import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  routToSwap(){
    // window.location.href = "http://swap.telefy.finance/";
    window.open("http://swap.telefy.finance/", "_blank");
  }
}
