<div class="container">
  <div class="hero-wrapper py-lg-5 pt-lg-5">
    <div class="hero-container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 col-sm-12">
          <div class="parent" data-aos="fade-right">
            <img class="image1" src="./assets/Images/telefy-dia04.svg" />
            <img class="image2" src="./assets/Images/telefy-dia03.svg" />
          </div>
          <!-- <div class="hero-image animated" data-aos="fade-right"></div> -->
        </div>
        <div class="col-lg-6 col-sm-12">
          <div class="row">
            <div class="col-lg-12"></div>
          </div>

          <div class="row">
            <div class="container">
              <div class="row">
                <div class="col-lg-10 offset-lg-2">
                  <div class="timer-box">
                    <div class="timer-title">
                      <p id="presaleContent"></p>
                    </div>
                    <div>
                      <div class="simply-countdown simply-countdown-one">
                        <div class="simply-section simply-days-section">
                          <div>
                            <span class="simply-amount" id="days"></span
                            ><span class="simply-word">days</span>
                          </div>
                        </div>
                        <div class="simply-section simply-hours-section">
                          <div>
                            <span class="simply-amount" id="hours"></span
                            ><span class="simply-word">hours</span>
                          </div>
                        </div>
                        <div class="simply-section simply-minutes-section">
                          <div>
                            <span class="simply-amount" id="mins"></span
                            ><span class="simply-word">minutes</span>
                          </div>
                        </div>
                        <div class="simply-section simply-seconds-section">
                          <div>
                            <span class="simply-amount" id="seconds"></span
                            ><span class="simply-word">seconds</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-10 offset-lg-2 flex-box">
              <div class="">
                <div class="hero-text animated">
                  <div>
                    <h1>
                      <img src="./assets/Images/telefy-dark1.svg" />
                    </h1>
                    <p>
                      Worlds first credit score oriented multi chain DEFI
                      solution
                    </p>
                    <button class="btn btn-gold fw-600" (click)="launchIDO()">
                      Take me to IDO
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="announcement-banner py-2">
    <div class="row">
      <div class="col-lg-12">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1200 203"
          enable-background="new 0 0 1200 203"
          xml:space="preserve"
        >
          <g id="Layer_4">
            <radialGradient
              id="SVGID_1_"
              cx="325.4029"
              cy="294.9559"
              r="417.8761"
              gradientTransform="matrix(2.3422 0 0 0.3964 -162.8471 -15.4287)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" style="stop-color: #792779" />
              <stop offset="0.1534" style="stop-color: #6e1e71" />
              <stop offset="0.6062" style="stop-color: #52085d" />
              <stop offset="0.8698" style="stop-color: #480055" />
            </radialGradient>
            <rect
              x="-0.4"
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="url(#SVGID_1_)"
              width="1199.4"
              height="203"
            />
          </g>
          <g id="Layer_2">
            <g opacity="0.15">
              <ellipse fill="#FFFFFF" cx="607.8" cy="55.3" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M516.2,73.7c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C517.1,72.4,516.2,73,516.2,73.7z"
              />
              <ellipse fill="#FFFFFF" cx="347.4" cy="178.8" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="459.6" cy="20.8" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M421.4,9.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C421.9,8.8,421.4,9.2,421.4,9.6z"
              />
              <path
                fill="#FFFFFF"
                d="M375.8,34.4c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C376.3,33.6,375.8,34,375.8,34.4z"
              />
              <path
                fill="#FFFFFF"
                d="M420.6,63.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C421.1,62.8,420.6,63.1,420.6,63.5z"
              />
              <path
                fill="#FFFFFF"
                d="M506.2,12.6c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S506.2,12.2,506.2,12.6z"
              />
              <path
                fill="#FFFFFF"
                d="M518.2,33.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C518.7,32.7,518.2,33,518.2,33.5z"
              />
              <path
                fill="#FFFFFF"
                d="M552,32.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C552.5,31.8,552,32.1,552,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M598.7,16.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C599.2,15.8,598.7,16.1,598.7,16.6z"
              />
              <path
                fill="#FFFFFF"
                d="M616.2,32.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C616.7,31.8,616.2,32.1,616.2,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M542.6,92c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S542.6,91.6,542.6,92z"
              />
              <path
                fill="#FFFFFF"
                d="M564.9,56.9c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S564.9,56.5,564.9,56.9z"
              />
              <path
                fill="#FFFFFF"
                d="M447.7,91.4c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C448.2,90.6,447.7,90.9,447.7,91.4z"
              />
              <path
                fill="#FFFFFF"
                d="M420.3,79c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C420.9,78.3,420.3,78.6,420.3,79z"
              />
              <path
                fill="#FFFFFF"
                d="M426.2,68.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C426.7,67.8,426.2,68.1,426.2,68.6z"
              />
              <path
                fill="#FFFFFF"
                d="M383.4,76.2c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C383.9,75.5,383.4,75.8,383.4,76.2z"
              />
              <path
                fill="#FFFFFF"
                d="M342.8,32.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C343.4,31.8,342.8,32.1,342.8,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M326.4,30c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C326.9,29.2,326.4,29.5,326.4,30z"
              />
              <path
                fill="#FFFFFF"
                d="M333.3,50.4c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C333.8,49.7,333.3,50,333.3,50.4z"
              />
              <path
                fill="#FFFFFF"
                d="M334.5,143.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C335,143.2,334.5,143.5,334.5,143.9z"
              />
              <path
                fill="#FFFFFF"
                d="M340.3,187.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C340.8,186.9,340.3,187.2,340.3,187.7z"
              />
              <path
                fill="#FFFFFF"
                d="M357.3,188.3c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C357.8,187.6,357.3,187.9,357.3,188.3z"
              />
              <path
                fill="#FFFFFF"
                d="M431.1,152.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C431.6,151.9,431.1,152.3,431.1,152.7z"
              />
              <path
                fill="#FFFFFF"
                d="M468.3,196c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C468.8,195.2,468.3,195.5,468.3,196z"
              />
              <path
                fill="#FFFFFF"
                d="M519.1,168.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C519.7,168,519.1,168.3,519.1,168.7z"
              />
              <path
                fill="#FFFFFF"
                d="M536.3,184.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C536.8,184.2,536.3,184.5,536.3,184.9z"
              />
              <path
                fill="#FFFFFF"
                d="M487.6,182.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C488.1,181.9,487.6,182.3,487.6,182.7z"
              />
              <path
                fill="#FFFFFF"
                d="M590.1,180.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C590.6,180.1,590.1,180.5,590.1,180.9z"
              />
              <path
                fill="#FFFFFF"
                d="M597.1,162.6c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S597.1,162.1,597.1,162.6z"
              />
              <path
                fill="#FFFFFF"
                d="M573.4,178.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C573.9,177.7,573.4,178.1,573.4,178.5z"
              />
              <path
                fill="#FFFFFF"
                d="M640.4,193.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C641,192.9,640.4,193.2,640.4,193.7z"
              />
              <path
                fill="#FFFFFF"
                d="M570.7,197.1c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C571.2,196.3,570.7,196.6,570.7,197.1z"
              />
              <path
                fill="#FFFFFF"
                d="M609.9,145.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C610.4,144.8,609.9,145.1,609.9,145.5z"
              />
              <path
                fill="#FFFFFF"
                d="M332.1,123.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C332.7,122.7,332.1,123.1,332.1,123.5z"
              />
              <path
                fill="#FFFFFF"
                d="M372.1,161.3c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C372.7,160.5,372.1,160.9,372.1,161.3z"
              />
              <path
                fill="#FFFFFF"
                d="M402.8,150.1c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C403.3,149.3,402.8,149.6,402.8,150.1z"
              />
              <path
                fill="#FFFFFF"
                d="M521.3,65.3c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8S521.3,64.9,521.3,65.3z"
              />
              <path
                fill="#FFFFFF"
                d="M600.4,58.2c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C600.9,57.4,600.4,57.7,600.4,58.2z"
              />
              <path
                fill="#FFFFFF"
                d="M599.7,51.4c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C600.2,50.7,599.7,51,599.7,51.4z"
              />
              <path
                fill="#FFFFFF"
                d="M333.3,134.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C333.8,134.1,333.3,134.5,333.3,134.9z"
              />
              <ellipse fill="#FFFFFF" cx="482.1" cy="201.7" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="361.1" cy="124.7" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="416.7" cy="102.6" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="505" cy="162.6" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M450.4,133.8c0,0.7,0.9,1.3,2,1.3s2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3S450.4,133.1,450.4,133.8z"
              />
              <path
                fill="#FFFFFF"
                d="M573.8,173c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C574.7,171.8,573.8,172.3,573.8,173
   z"
              />
              <path
                fill="#FFFFFF"
                d="M640.9,168.3c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3
   C641.8,167,640.9,167.6,640.9,168.3z"
              />
              <path
                fill="#FFFFFF"
                d="M548.9,9.9c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C549.7,8.6,548.9,9.2,548.9,9.9z"
              />
              <ellipse fill="#FFFFFF" cx="341.1" cy="25.8" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M392.5,6.4c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C393.4,5.2,392.5,5.7,392.5,6.4z"
              />
              <path
                fill="#FFFFFF"
                d="M640.5,4.6c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C641.4,3.3,640.5,3.9,640.5,4.6z"
              />
            </g>
            <g opacity="0.15">
              <ellipse fill="#FFFFFF" cx="563.5" cy="55.3" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M655,73.7c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3C654.1,72.4,655,73,655,73.7z"
              />
              <ellipse fill="#FFFFFF" cx="823.9" cy="178.8" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="711.7" cy="20.8" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M749.9,9.6c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C749.3,8.8,749.9,9.2,749.9,9.6z"
              />
              <path
                fill="#FFFFFF"
                d="M795.5,34.4c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C795,33.6,795.5,34,795.5,34.4z"
              />
              <path
                fill="#FFFFFF"
                d="M750.7,63.5c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S750.7,63.1,750.7,63.5z"
              />
              <path
                fill="#FFFFFF"
                d="M665.1,12.6c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S665.1,12.2,665.1,12.6z"
              />
              <path
                fill="#FFFFFF"
                d="M653.1,33.5c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C652.5,32.7,653.1,33,653.1,33.5z"
              />
              <path
                fill="#FFFFFF"
                d="M619.3,32.5c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C618.8,31.8,619.3,32.1,619.3,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M572.6,16.6c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C572.1,15.8,572.6,16.1,572.6,16.6z"
              />
              <path
                fill="#FFFFFF"
                d="M555.1,32.5c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C554.6,31.8,555.1,32.1,555.1,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M628.7,92c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S628.7,91.6,628.7,92z"
              />
              <path
                fill="#FFFFFF"
                d="M606.4,56.9c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S606.4,56.5,606.4,56.9z"
              />
              <path
                fill="#FFFFFF"
                d="M723.6,91.4c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C723.1,90.6,723.6,90.9,723.6,91.4z"
              />
              <path
                fill="#FFFFFF"
                d="M750.9,79c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C750.4,78.3,750.9,78.6,750.9,79z"
              />
              <path
                fill="#FFFFFF"
                d="M745.1,68.6c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8s0.5-0.8,1.2-0.8
   C744.5,67.8,745.1,68.1,745.1,68.6z"
              />
              <path
                fill="#FFFFFF"
                d="M787.9,76.2c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C787.4,75.5,787.9,75.8,787.9,76.2z"
              />
              <path
                fill="#FFFFFF"
                d="M828.4,32.5c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C827.9,31.8,828.4,32.1,828.4,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M844.9,30c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S844.9,29.5,844.9,30z"
              />
              <path
                fill="#FFFFFF"
                d="M838,50.4c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S838,50,838,50.4z"
              />
              <path
                fill="#FFFFFF"
                d="M836.8,143.9c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C836.3,143.2,836.8,143.5,836.8,143.9z"
              />
              <path
                fill="#FFFFFF"
                d="M831,187.7c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8s0.5-0.8,1.2-0.8
   C830.4,186.9,831,187.2,831,187.7z"
              />
              <path
                fill="#FFFFFF"
                d="M814,188.3c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8s0.5-0.8,1.2-0.8
   C813.5,187.6,814,187.9,814,188.3z"
              />
              <path
                fill="#FFFFFF"
                d="M740.2,152.7c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S740.2,152.3,740.2,152.7z"
              />
              <path
                fill="#FFFFFF"
                d="M703,196c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8s0.5-0.8,1.2-0.8C702.5,195.2,703,195.5,703,196z"
              />
              <path
                fill="#FFFFFF"
                d="M652.1,168.7c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C651.6,168,652.1,168.3,652.1,168.7z"
              />
              <path
                fill="#FFFFFF"
                d="M635,184.9c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C634.5,184.2,635,184.5,635,184.9z"
              />
              <path
                fill="#FFFFFF"
                d="M683.7,182.7c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C683.1,181.9,683.7,182.3,683.7,182.7z"
              />
              <path
                fill="#FFFFFF"
                d="M581.2,180.9c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C580.7,180.1,581.2,180.5,581.2,180.9z"
              />
              <path
                fill="#FFFFFF"
                d="M574.2,162.6c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C573.6,161.8,574.2,162.1,574.2,162.6z"
              />
              <path
                fill="#FFFFFF"
                d="M597.9,178.5c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C597.3,177.7,597.9,178.1,597.9,178.5z"
              />
              <path
                fill="#FFFFFF"
                d="M530.8,193.7c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C530.3,192.9,530.8,193.2,530.8,193.7z"
              />
              <path
                fill="#FFFFFF"
                d="M600.6,197.1c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C600.1,196.3,600.6,196.6,600.6,197.1z"
              />
              <path
                fill="#FFFFFF"
                d="M561.4,145.5c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S561.4,145.1,561.4,145.5z"
              />
              <path
                fill="#FFFFFF"
                d="M839.1,123.5c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C838.6,122.7,839.1,123.1,839.1,123.5z"
              />
              <path
                fill="#FFFFFF"
                d="M799.1,161.3c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C798.6,160.5,799.1,160.9,799.1,161.3z"
              />
              <path
                fill="#FFFFFF"
                d="M768.5,150.1c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C767.9,149.3,768.5,149.6,768.5,150.1z"
              />
              <path
                fill="#FFFFFF"
                d="M649.9,65.3c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8s0.5-0.8,1.2-0.8S649.9,64.9,649.9,65.3z"
              />
              <path
                fill="#FFFFFF"
                d="M570.9,58.2c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C570.3,57.4,570.9,57.7,570.9,58.2z"
              />
              <path
                fill="#FFFFFF"
                d="M571.6,51.4c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C571.1,50.7,571.6,51,571.6,51.4z"
              />
              <path
                fill="#FFFFFF"
                d="M838,134.9c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C837.4,134.1,838,134.5,838,134.9z"
              />
              <ellipse fill="#FFFFFF" cx="689.2" cy="201.7" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="810.2" cy="124.7" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="754.6" cy="102.6" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="666.3" cy="162.6" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M720.9,133.8c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3
   C720,132.6,720.9,133.1,720.9,133.8z"
              />
              <path
                fill="#FFFFFF"
                d="M597.4,173c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3
   C596.5,171.8,597.4,172.3,597.4,173z"
              />
              <path
                fill="#FFFFFF"
                d="M530.3,168.3c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3
   C529.4,167,530.3,167.6,530.3,168.3z"
              />
              <path
                fill="#FFFFFF"
                d="M622.4,9.9c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3C621.5,8.6,622.4,9.2,622.4,9.9z"
              />
              <ellipse fill="#FFFFFF" cx="830.2" cy="25.8" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M778.8,6.4c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3C777.9,5.2,778.8,5.7,778.8,6.4z"
              />
              <path
                fill="#FFFFFF"
                d="M530.7,4.6c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3C529.9,3.3,530.7,3.9,530.7,4.6z"
              />
            </g>
            <g opacity="0.15">
              <ellipse fill="#FFFFFF" cx="281.8" cy="55.3" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M190.2,73.7c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C191.1,72.4,190.2,73,190.2,73.7z"
              />
              <ellipse fill="#FFFFFF" cx="21.4" cy="178.8" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="133.6" cy="20.8" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M95.4,9.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8C95.9,8.8,95.4,9.2,95.4,9.6
   z"
              />
              <path
                fill="#FFFFFF"
                d="M49.8,34.4c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S49.8,34,49.8,34.4z"
              />
              <path
                fill="#FFFFFF"
                d="M94.6,63.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C95.1,62.8,94.6,63.1,94.6,63.5z"
              />
              <path
                fill="#FFFFFF"
                d="M180.2,12.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C180.7,11.9,180.2,12.2,180.2,12.6z"
              />
              <path
                fill="#FFFFFF"
                d="M192.2,33.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C192.7,32.7,192.2,33,192.2,33.5z"
              />
              <path
                fill="#FFFFFF"
                d="M226,32.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C226.5,31.8,226,32.1,226,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M272.7,16.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C273.2,15.8,272.7,16.1,272.7,16.6z"
              />
              <path
                fill="#FFFFFF"
                d="M290.2,32.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C290.7,31.8,290.2,32.1,290.2,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M216.6,92c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C217.1,91.3,216.6,91.6,216.6,92z"
              />
              <path
                fill="#FFFFFF"
                d="M238.9,56.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C239.4,56.2,238.9,56.5,238.9,56.9z"
              />
              <path
                fill="#FFFFFF"
                d="M121.7,91.4c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C122.2,90.6,121.7,90.9,121.7,91.4z"
              />
              <path
                fill="#FFFFFF"
                d="M94.3,79c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8C94.9,78.3,94.3,78.6,94.3,79
   z"
              />
              <path
                fill="#FFFFFF"
                d="M100.2,68.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C100.7,67.8,100.2,68.1,100.2,68.6z"
              />
              <path
                fill="#FFFFFF"
                d="M57.4,76.2c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C57.9,75.5,57.4,75.8,57.4,76.2z"
              />
              <path
                fill="#FFFFFF"
                d="M16.8,32.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C17.4,31.8,16.8,32.1,16.8,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M0.4,30c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8C0.9,29.2,0.4,29.5,0.4,30z"
              />
              <path
                fill="#FFFFFF"
                d="M7.3,50.4c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S7.3,50,7.3,50.4z"
              />
              <path
                fill="#FFFFFF"
                d="M8.5,143.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C9,143.2,8.5,143.5,8.5,143.9z"
              />
              <path
                fill="#FFFFFF"
                d="M14.3,187.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C14.8,186.9,14.3,187.2,14.3,187.7z"
              />
              <path
                fill="#FFFFFF"
                d="M31.3,188.3c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C31.8,187.6,31.3,187.9,31.3,188.3z"
              />
              <path
                fill="#FFFFFF"
                d="M105.1,152.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C105.6,151.9,105.1,152.3,105.1,152.7z"
              />
              <path
                fill="#FFFFFF"
                d="M142.3,196c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C142.8,195.2,142.3,195.5,142.3,196z"
              />
              <path
                fill="#FFFFFF"
                d="M193.1,168.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C193.7,168,193.1,168.3,193.1,168.7z"
              />
              <path
                fill="#FFFFFF"
                d="M210.3,184.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C210.8,184.2,210.3,184.5,210.3,184.9z"
              />
              <path
                fill="#FFFFFF"
                d="M161.6,182.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C162.1,181.9,161.6,182.3,161.6,182.7z"
              />
              <path
                fill="#FFFFFF"
                d="M264.1,180.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C264.6,180.1,264.1,180.5,264.1,180.9z"
              />
              <path
                fill="#FFFFFF"
                d="M271.1,162.6c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S271.1,162.1,271.1,162.6z"
              />
              <path
                fill="#FFFFFF"
                d="M247.4,178.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C247.9,177.7,247.4,178.1,247.4,178.5z"
              />
              <path
                fill="#FFFFFF"
                d="M314.4,193.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C315,192.9,314.4,193.2,314.4,193.7z"
              />
              <path
                fill="#FFFFFF"
                d="M244.7,197.1c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C245.2,196.3,244.7,196.6,244.7,197.1z"
              />
              <path
                fill="#FFFFFF"
                d="M283.9,145.5c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S283.9,145.1,283.9,145.5z"
              />
              <path
                fill="#FFFFFF"
                d="M6.1,123.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C6.7,122.7,6.1,123.1,6.1,123.5z"
              />
              <path
                fill="#FFFFFF"
                d="M46.1,161.3c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C46.7,160.5,46.1,160.9,46.1,161.3z"
              />
              <path
                fill="#FFFFFF"
                d="M76.8,150.1c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C77.3,149.3,76.8,149.6,76.8,150.1z"
              />
              <path
                fill="#FFFFFF"
                d="M195.3,65.3c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C195.9,64.6,195.3,64.9,195.3,65.3z"
              />
              <path
                fill="#FFFFFF"
                d="M274.4,58.2c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C274.9,57.4,274.4,57.7,274.4,58.2z"
              />
              <path
                fill="#FFFFFF"
                d="M273.7,51.4c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C274.2,50.7,273.7,51,273.7,51.4z"
              />
              <path
                fill="#FFFFFF"
                d="M7.3,134.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C7.8,134.1,7.3,134.5,7.3,134.9z"
              />
              <ellipse fill="#FFFFFF" cx="156.1" cy="201.7" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="35.1" cy="124.7" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="90.7" cy="102.6" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="179" cy="162.6" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M124.4,133.8c0,0.7,0.9,1.3,2,1.3s2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3S124.4,133.1,124.4,133.8z"
              />
              <path
                fill="#FFFFFF"
                d="M247.8,173c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C248.7,171.8,247.8,172.3,247.8,173
   z"
              />
              <path
                fill="#FFFFFF"
                d="M314.9,168.3c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3
   C315.8,167,314.9,167.6,314.9,168.3z"
              />
              <path
                fill="#FFFFFF"
                d="M222.9,9.9c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C223.7,8.6,222.9,9.2,222.9,9.9z"
              />
              <ellipse fill="#FFFFFF" cx="15.1" cy="25.8" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M66.5,6.4c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C67.4,5.2,66.5,5.7,66.5,6.4z"
              />
              <path
                fill="#FFFFFF"
                d="M314.5,4.6c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C315.4,3.3,314.5,3.9,314.5,4.6z"
              />
            </g>
            <g opacity="0.15">
              <ellipse fill="#FFFFFF" cx="961.8" cy="55.3" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M870.2,73.7c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C871.1,72.4,870.2,73,870.2,73.7z"
              />
              <ellipse fill="#FFFFFF" cx="701.4" cy="178.8" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="813.6" cy="20.8" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M775.4,9.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C775.9,8.8,775.4,9.2,775.4,9.6z"
              />
              <path
                fill="#FFFFFF"
                d="M729.8,34.4c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S729.8,34,729.8,34.4z"
              />
              <path
                fill="#FFFFFF"
                d="M774.6,63.5c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S774.6,63.1,774.6,63.5z"
              />
              <path
                fill="#FFFFFF"
                d="M860.2,12.6c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S860.2,12.2,860.2,12.6z"
              />
              <path
                fill="#FFFFFF"
                d="M872.2,33.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C872.7,32.7,872.2,33,872.2,33.5z"
              />
              <path
                fill="#FFFFFF"
                d="M906,32.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C906.5,31.8,906,32.1,906,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M952.7,16.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C953.2,15.8,952.7,16.1,952.7,16.6z"
              />
              <path
                fill="#FFFFFF"
                d="M970.2,32.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C970.7,31.8,970.2,32.1,970.2,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M896.6,92c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S896.6,91.6,896.6,92z"
              />
              <path
                fill="#FFFFFF"
                d="M918.9,56.9c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S918.9,56.5,918.9,56.9z"
              />
              <path
                fill="#FFFFFF"
                d="M801.7,91.4c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C802.2,90.6,801.7,90.9,801.7,91.4z"
              />
              <path
                fill="#FFFFFF"
                d="M774.3,79c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C774.9,78.3,774.3,78.6,774.3,79z"
              />
              <path
                fill="#FFFFFF"
                d="M780.2,68.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C780.7,67.8,780.2,68.1,780.2,68.6z"
              />
              <path
                fill="#FFFFFF"
                d="M737.4,76.2c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C737.9,75.5,737.4,75.8,737.4,76.2z"
              />
              <path
                fill="#FFFFFF"
                d="M696.8,32.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C697.4,31.8,696.8,32.1,696.8,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M680.4,30c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S680.4,29.5,680.4,30z"
              />
              <path
                fill="#FFFFFF"
                d="M687.3,50.4c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S687.3,50,687.3,50.4z"
              />
              <path
                fill="#FFFFFF"
                d="M688.5,143.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C689,143.2,688.5,143.5,688.5,143.9z"
              />
              <path
                fill="#FFFFFF"
                d="M694.3,187.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C694.8,186.9,694.3,187.2,694.3,187.7z"
              />
              <path
                fill="#FFFFFF"
                d="M711.3,188.3c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C711.8,187.6,711.3,187.9,711.3,188.3z"
              />
              <path
                fill="#FFFFFF"
                d="M785.1,152.7c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S785.1,152.3,785.1,152.7z"
              />
              <path
                fill="#FFFFFF"
                d="M822.3,196c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C822.8,195.2,822.3,195.5,822.3,196z"
              />
              <path
                fill="#FFFFFF"
                d="M873.1,168.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C873.7,168,873.1,168.3,873.1,168.7z"
              />
              <path
                fill="#FFFFFF"
                d="M890.3,184.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C890.8,184.2,890.3,184.5,890.3,184.9z"
              />
              <path
                fill="#FFFFFF"
                d="M841.6,182.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C842.1,181.9,841.6,182.3,841.6,182.7z"
              />
              <path
                fill="#FFFFFF"
                d="M944.1,180.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C944.6,180.1,944.1,180.5,944.1,180.9z"
              />
              <path
                fill="#FFFFFF"
                d="M951.1,162.6c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S951.1,162.1,951.1,162.6z"
              />
              <path
                fill="#FFFFFF"
                d="M927.4,178.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C927.9,177.7,927.4,178.1,927.4,178.5z"
              />
              <path
                fill="#FFFFFF"
                d="M994.4,193.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C995,192.9,994.4,193.2,994.4,193.7z"
              />
              <path
                fill="#FFFFFF"
                d="M924.7,197.1c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C925.2,196.3,924.7,196.6,924.7,197.1z"
              />
              <path
                fill="#FFFFFF"
                d="M963.9,145.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C964.4,144.8,963.9,145.1,963.9,145.5z"
              />
              <path
                fill="#FFFFFF"
                d="M686.1,123.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C686.7,122.7,686.1,123.1,686.1,123.5z"
              />
              <path
                fill="#FFFFFF"
                d="M726.1,161.3c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C726.7,160.5,726.1,160.9,726.1,161.3z"
              />
              <path
                fill="#FFFFFF"
                d="M756.8,150.1c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C757.3,149.3,756.8,149.6,756.8,150.1z"
              />
              <path
                fill="#FFFFFF"
                d="M875.3,65.3c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8S875.3,64.9,875.3,65.3z"
              />
              <path
                fill="#FFFFFF"
                d="M954.4,58.2c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C954.9,57.4,954.4,57.7,954.4,58.2z"
              />
              <path
                fill="#FFFFFF"
                d="M953.7,51.4c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C954.2,50.7,953.7,51,953.7,51.4z"
              />
              <path
                fill="#FFFFFF"
                d="M687.3,134.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C687.8,134.1,687.3,134.5,687.3,134.9z"
              />
              <ellipse fill="#FFFFFF" cx="836.1" cy="201.7" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="715.1" cy="124.7" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="770.7" cy="102.6" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="859" cy="162.6" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M804.4,133.8c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3
   C805.2,132.6,804.4,133.1,804.4,133.8z"
              />
              <path
                fill="#FFFFFF"
                d="M927.8,173c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C928.7,171.8,927.8,172.3,927.8,173
   z"
              />
              <path
                fill="#FFFFFF"
                d="M994.9,168.3c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3
   C995.8,167,994.9,167.6,994.9,168.3z"
              />
              <path
                fill="#FFFFFF"
                d="M902.9,9.9c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C903.7,8.6,902.9,9.2,902.9,9.9z"
              />
              <ellipse fill="#FFFFFF" cx="695.1" cy="25.8" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M746.5,6.4c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C747.4,5.2,746.5,5.7,746.5,6.4z"
              />
              <path
                fill="#FFFFFF"
                d="M994.5,4.6c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C995.4,3.3,994.5,3.9,994.5,4.6z"
              />
            </g>
            <g opacity="0.15">
              <ellipse fill="#FFFFFF" cx="917.5" cy="55.3" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M1009,73.7c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3C1008.1,72.4,1009,73,1009,73.7z"
              />
              <ellipse fill="#FFFFFF" cx="1177.9" cy="178.8" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="1065.7" cy="20.8" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M1103.9,9.6c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1103.3,8.8,1103.9,9.2,1103.9,9.6z"
              />
              <path
                fill="#FFFFFF"
                d="M1149.5,34.4c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S1149.5,34,1149.5,34.4z"
              />
              <path
                fill="#FFFFFF"
                d="M1104.7,63.5c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S1104.7,63.1,1104.7,63.5z"
              />
              <path
                fill="#FFFFFF"
                d="M1019.1,12.6c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S1019.1,12.2,1019.1,12.6z"
              />
              <path
                fill="#FFFFFF"
                d="M1007.1,33.5c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1006.5,32.7,1007.1,33,1007.1,33.5z"
              />
              <path
                fill="#FFFFFF"
                d="M973.3,32.5c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C972.8,31.8,973.3,32.1,973.3,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M926.6,16.6c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C926.1,15.8,926.6,16.1,926.6,16.6z"
              />
              <path
                fill="#FFFFFF"
                d="M909.1,32.5c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C908.6,31.8,909.1,32.1,909.1,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M982.7,92c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S982.7,91.6,982.7,92z"
              />
              <path
                fill="#FFFFFF"
                d="M960.4,56.9c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S960.4,56.5,960.4,56.9z"
              />
              <path
                fill="#FFFFFF"
                d="M1077.6,91.4c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1077.1,90.6,1077.6,90.9,1077.6,91.4z"
              />
              <path
                fill="#FFFFFF"
                d="M1104.9,79c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1104.4,78.3,1104.9,78.6,1104.9,79z"
              />
              <path
                fill="#FFFFFF"
                d="M1099.1,68.6c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8s0.5-0.8,1.2-0.8
   C1098.5,67.8,1099.1,68.1,1099.1,68.6z"
              />
              <path
                fill="#FFFFFF"
                d="M1141.9,76.2c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1141.4,75.5,1141.9,75.8,1141.9,76.2z"
              />
              <path
                fill="#FFFFFF"
                d="M1182.4,32.5c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1181.9,31.8,1182.4,32.1,1182.4,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M1198.9,30c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1198.3,29.2,1198.9,29.5,1198.9,30z"
              />
              <path
                fill="#FFFFFF"
                d="M1192,50.4c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S1192,50,1192,50.4z"
              />
              <path
                fill="#FFFFFF"
                d="M1190.8,143.9c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1190.3,143.2,1190.8,143.5,1190.8,143.9z"
              />
              <path
                fill="#FFFFFF"
                d="M1185,187.7c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8s0.5-0.8,1.2-0.8
   C1184.4,186.9,1185,187.2,1185,187.7z"
              />
              <path
                fill="#FFFFFF"
                d="M1168,188.3c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8s0.5-0.8,1.2-0.8
   C1167.5,187.6,1168,187.9,1168,188.3z"
              />
              <path
                fill="#FFFFFF"
                d="M1094.2,152.7c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S1094.2,152.3,1094.2,152.7z"
              />
              <path
                fill="#FFFFFF"
                d="M1057,196c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8s0.5-0.8,1.2-0.8
   C1056.5,195.2,1057,195.5,1057,196z"
              />
              <path
                fill="#FFFFFF"
                d="M1006.1,168.7c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1005.6,168,1006.1,168.3,1006.1,168.7z"
              />
              <path
                fill="#FFFFFF"
                d="M989,184.9c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C988.5,184.2,989,184.5,989,184.9z"
              />
              <path
                fill="#FFFFFF"
                d="M1037.7,182.7c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1037.1,181.9,1037.7,182.3,1037.7,182.7z"
              />
              <path
                fill="#FFFFFF"
                d="M935.2,180.9c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C934.7,180.1,935.2,180.5,935.2,180.9z"
              />
              <path
                fill="#FFFFFF"
                d="M928.2,162.6c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C927.6,161.8,928.2,162.1,928.2,162.6z"
              />
              <path
                fill="#FFFFFF"
                d="M951.9,178.5c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C951.3,177.7,951.9,178.1,951.9,178.5z"
              />
              <path
                fill="#FFFFFF"
                d="M884.8,193.7c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C884.3,192.9,884.8,193.2,884.8,193.7z"
              />
              <path
                fill="#FFFFFF"
                d="M954.6,197.1c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C954.1,196.3,954.6,196.6,954.6,197.1z"
              />
              <path
                fill="#FFFFFF"
                d="M915.4,145.5c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8S915.4,145.1,915.4,145.5z"
              />
              <path
                fill="#FFFFFF"
                d="M1193.1,123.5c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1192.6,122.7,1193.1,123.1,1193.1,123.5z"
              />
              <path
                fill="#FFFFFF"
                d="M1153.1,161.3c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1152.6,160.5,1153.1,160.9,1153.1,161.3z"
              />
              <path
                fill="#FFFFFF"
                d="M1122.5,150.1c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1121.9,149.3,1122.5,149.6,1122.5,150.1z"
              />
              <path
                fill="#FFFFFF"
                d="M1003.9,65.3c0,0.4-0.5,0.8-1.2,0.8s-1.2-0.3-1.2-0.8s0.5-0.8,1.2-0.8S1003.9,64.9,1003.9,65.3z"
              />
              <path
                fill="#FFFFFF"
                d="M924.9,58.2c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C924.3,57.4,924.9,57.7,924.9,58.2z"
              />
              <path
                fill="#FFFFFF"
                d="M925.6,51.4c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C925.1,50.7,925.6,51,925.6,51.4z"
              />
              <path
                fill="#FFFFFF"
                d="M1192,134.9c0,0.4-0.5,0.8-1.2,0.8c-0.6,0-1.2-0.3-1.2-0.8c0-0.4,0.5-0.8,1.2-0.8
   C1191.4,134.1,1192,134.5,1192,134.9z"
              />
              <ellipse fill="#FFFFFF" cx="1043.2" cy="201.7" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="1164.2" cy="124.7" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="1108.6" cy="102.6" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="1020.3" cy="162.6" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M1074.9,133.8c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3
   C1074,132.6,1074.9,133.1,1074.9,133.8z"
              />
              <path
                fill="#FFFFFF"
                d="M951.4,173c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3
   C950.5,171.8,951.4,172.3,951.4,173z"
              />
              <path
                fill="#FFFFFF"
                d="M884.3,168.3c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3
   C883.4,167,884.3,167.6,884.3,168.3z"
              />
              <path
                fill="#FFFFFF"
                d="M976.4,9.9c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3C975.5,8.6,976.4,9.2,976.4,9.9z"
              />
              <ellipse fill="#FFFFFF" cx="1184.2" cy="25.8" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M1132.8,6.4c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3
   C1131.9,5.2,1132.8,5.7,1132.8,6.4z"
              />
              <path
                fill="#FFFFFF"
                d="M884.7,4.6c0,0.7-0.9,1.3-2,1.3c-1.1,0-2-0.6-2-1.3c0-0.7,0.9-1.3,2-1.3C883.9,3.3,884.7,3.9,884.7,4.6z"
              />
            </g>
            <g opacity="0.15">
              <ellipse fill="#FFFFFF" cx="635.8" cy="55.3" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M544.2,73.7c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C545.1,72.4,544.2,73,544.2,73.7z"
              />
              <ellipse fill="#FFFFFF" cx="375.4" cy="178.8" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="487.6" cy="20.8" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M449.4,9.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C449.9,8.8,449.4,9.2,449.4,9.6z"
              />
              <path
                fill="#FFFFFF"
                d="M403.8,34.4c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C404.3,33.6,403.8,34,403.8,34.4z"
              />
              <path
                fill="#FFFFFF"
                d="M448.6,63.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C449.1,62.8,448.6,63.1,448.6,63.5z"
              />
              <path
                fill="#FFFFFF"
                d="M534.2,12.6c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S534.2,12.2,534.2,12.6z"
              />
              <path
                fill="#FFFFFF"
                d="M546.2,33.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C546.7,32.7,546.2,33,546.2,33.5z"
              />
              <path
                fill="#FFFFFF"
                d="M580,32.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C580.5,31.8,580,32.1,580,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M626.7,16.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C627.2,15.8,626.7,16.1,626.7,16.6z"
              />
              <path
                fill="#FFFFFF"
                d="M644.2,32.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C644.7,31.8,644.2,32.1,644.2,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M570.6,92c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S570.6,91.6,570.6,92z"
              />
              <path
                fill="#FFFFFF"
                d="M592.9,56.9c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S592.9,56.5,592.9,56.9z"
              />
              <path
                fill="#FFFFFF"
                d="M475.7,91.4c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C476.2,90.6,475.7,90.9,475.7,91.4z"
              />
              <path
                fill="#FFFFFF"
                d="M448.3,79c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C448.9,78.3,448.3,78.6,448.3,79z"
              />
              <path
                fill="#FFFFFF"
                d="M454.2,68.6c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C454.7,67.8,454.2,68.1,454.2,68.6z"
              />
              <path
                fill="#FFFFFF"
                d="M411.4,76.2c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C411.9,75.5,411.4,75.8,411.4,76.2z"
              />
              <path
                fill="#FFFFFF"
                d="M370.8,32.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C371.4,31.8,370.8,32.1,370.8,32.5z"
              />
              <path
                fill="#FFFFFF"
                d="M354.4,30c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C354.9,29.2,354.4,29.5,354.4,30z"
              />
              <path
                fill="#FFFFFF"
                d="M361.3,50.4c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C361.8,49.7,361.3,50,361.3,50.4z"
              />
              <path
                fill="#FFFFFF"
                d="M362.5,143.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C363,143.2,362.5,143.5,362.5,143.9z"
              />
              <path
                fill="#FFFFFF"
                d="M368.3,187.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C368.8,186.9,368.3,187.2,368.3,187.7z"
              />
              <path
                fill="#FFFFFF"
                d="M385.3,188.3c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C385.8,187.6,385.3,187.9,385.3,188.3z"
              />
              <path
                fill="#FFFFFF"
                d="M459.1,152.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C459.6,151.9,459.1,152.3,459.1,152.7z"
              />
              <path
                fill="#FFFFFF"
                d="M496.3,196c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8
   C496.8,195.2,496.3,195.5,496.3,196z"
              />
              <path
                fill="#FFFFFF"
                d="M547.1,168.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C547.7,168,547.1,168.3,547.1,168.7z"
              />
              <path
                fill="#FFFFFF"
                d="M564.3,184.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C564.8,184.2,564.3,184.5,564.3,184.9z"
              />
              <path
                fill="#FFFFFF"
                d="M515.6,182.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C516.1,181.9,515.6,182.3,515.6,182.7z"
              />
              <path
                fill="#FFFFFF"
                d="M618.1,180.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C618.6,180.1,618.1,180.5,618.1,180.9z"
              />
              <path
                fill="#FFFFFF"
                d="M625.1,162.6c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8S625.1,162.1,625.1,162.6z"
              />
              <path
                fill="#FFFFFF"
                d="M601.4,178.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C601.9,177.7,601.4,178.1,601.4,178.5z"
              />
              <path
                fill="#FFFFFF"
                d="M668.4,193.7c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C669,192.9,668.4,193.2,668.4,193.7z"
              />
              <path
                fill="#FFFFFF"
                d="M598.7,197.1c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C599.2,196.3,598.7,196.6,598.7,197.1z"
              />
              <path
                fill="#FFFFFF"
                d="M637.9,145.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C638.4,144.8,637.9,145.1,637.9,145.5z"
              />
              <path
                fill="#FFFFFF"
                d="M360.1,123.5c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C360.7,122.7,360.1,123.1,360.1,123.5z"
              />
              <path
                fill="#FFFFFF"
                d="M400.1,161.3c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C400.7,160.5,400.1,160.9,400.1,161.3z"
              />
              <path
                fill="#FFFFFF"
                d="M430.8,150.1c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C431.3,149.3,430.8,149.6,430.8,150.1z"
              />
              <path
                fill="#FFFFFF"
                d="M549.3,65.3c0,0.4,0.5,0.8,1.2,0.8s1.2-0.3,1.2-0.8s-0.5-0.8-1.2-0.8S549.3,64.9,549.3,65.3z"
              />
              <path
                fill="#FFFFFF"
                d="M628.4,58.2c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C628.9,57.4,628.4,57.7,628.4,58.2z"
              />
              <path
                fill="#FFFFFF"
                d="M627.7,51.4c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C628.2,50.7,627.7,51,627.7,51.4z"
              />
              <path
                fill="#FFFFFF"
                d="M361.3,134.9c0,0.4,0.5,0.8,1.2,0.8c0.6,0,1.2-0.3,1.2-0.8c0-0.4-0.5-0.8-1.2-0.8
   C361.8,134.1,361.3,134.5,361.3,134.9z"
              />
              <ellipse fill="#FFFFFF" cx="510.1" cy="201.7" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="389.1" cy="124.7" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="444.7" cy="102.6" rx="2" ry="1.3" />
              <ellipse fill="#FFFFFF" cx="533" cy="162.6" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M478.4,133.8c0,0.7,0.9,1.3,2,1.3s2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3S478.4,133.1,478.4,133.8z"
              />
              <path
                fill="#FFFFFF"
                d="M601.8,173c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C602.7,171.8,601.8,172.3,601.8,173
   z"
              />
              <path
                fill="#FFFFFF"
                d="M668.9,168.3c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3
   C669.8,167,668.9,167.6,668.9,168.3z"
              />
              <path
                fill="#FFFFFF"
                d="M576.9,9.9c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C577.7,8.6,576.9,9.2,576.9,9.9z"
              />
              <ellipse fill="#FFFFFF" cx="369.1" cy="25.8" rx="2" ry="1.3" />
              <path
                fill="#FFFFFF"
                d="M420.5,6.4c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C421.4,5.2,420.5,5.7,420.5,6.4z"
              />
              <path
                fill="#FFFFFF"
                d="M668.5,4.6c0,0.7,0.9,1.3,2,1.3c1.1,0,2-0.6,2-1.3c0-0.7-0.9-1.3-2-1.3C669.4,3.3,668.5,3.9,668.5,4.6z"
              />
            </g>
          </g>
          <g id="Layer_1">
            <g>
              <a target="_blank" xlink:href="https://azbit.com/ieo/TELE">
                <path
                  fill="#FFFFFF"
                  d="M337.1,156H167.8c-5.9,0-10.8-4.8-10.8-10.8V99.6c0-5.9,4.8-10.8,10.8-10.8h169.4
   c5.9,0,10.8,4.8,10.8,10.8v45.7C347.9,151.2,343,156,337.1,156z"
                />
              </a>
              <a target="_blank" xlink:href="https://azbit.com/ieo/TELE">
                <g>
                  <path
                    fill="#C83356"
                    d="M197.7,114.9c-0.9-1.6-1.9-3.2-2.9-4.9c-2,3.4-3.9,6.8-5.9,10.3c1.7,0,3.3,0,4.9,0c0,0.6-0.5,0.4-0.8,0.4
     c-0.5,0-0.9-0.2-1.3,0.3c-0.1,0.1-0.2,0.2-0.3,0c-0.1-0.1-0.1-0.3,0-0.4c-0.2,0.2,0,0.4-0.1,0.6c-0.3,0.1-0.3-0.1-0.3-0.2
     c0,0.1,0,0.3-0.2,0.3c-0.2,0-0.1-0.2-0.2-0.4c0,0.1,0,0.2,0,0.3c-0.1,0.3-0.3,0.6-0.5,0.4c-0.2-0.2-0.3,0.2-0.5,0
     c0,0,0-0.1-0.1-0.1c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.4,0.3c-0.4,0.5,0,1.1-0.2,1.6
     c0,0.5-0.4,0.8-0.8,0.7c-0.2,0-0.3,0.2-0.4,0.1c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2,0.3-0.2,0.6-0.2,0.8c0,0.2-0.2,0.4-0.4,0.3
     c-0.3-0.1-0.3-0.4-0.3-0.7c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2,0,0.2c0,0.1,0,0,0,0.1c-0.1,0.3,0.1,0.7-0.3,0.7
     c-0.3,0-0.2-0.4-0.3-0.7c-0.2,0.2,0,0.6-0.3,0.8c-0.2-0.2-0.3-0.2-0.4,0c-0.3-0.2-0.5-0.5-0.8,0c-0.1,0.1-0.2,0.2-0.3,0.3
     c-0.1,0.1-0.2,0.1-0.3,0.2c0,0.2,0.1,0.4-0.1,0.6c-2.4,0-4.9,0-7.5,0c6.2-10.8,12.3-21.6,18.5-32.5c3,5.2,5.8,10.3,8.7,15.4
     c-0.1,0.4-0.3,0.5-0.6,0.2c-0.1-0.2-0.2-0.4-0.3-0.5c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0.2-0.1,0.4-0.3,0.5
     c-0.2-0.2-0.4-0.1-0.4,0.2c-0.1,0.2-0.1,0.5-0.3,0.7c-0.4,0-0.3-0.4-0.4-0.6c0,0.6,0,0.6-1.2,2c-0.5,0.1-0.9,0.3-1.4,0.4
     c-0.8,0.2-0.5,0.8-0.4,1.3C197.9,114.7,197.9,114.9,197.7,114.9z"
                  />
                  <path
                    fill="#974F88"
                    d="M212.6,126.3c1.1,2,2.3,3.9,3.5,6c0.4-0.7,0.8-1.3,1.2-2c1.8-3.2,3.6-6.4,5.4-9.6
     c0.1-0.2,0.2-0.5,0.6-0.5c2.7,0,5.3,0,8.2,0c-5.2,9-10.2,17.9-15.4,26.9c-1.4-2.4-2.7-4.8-4.1-7.1c-1.6-2.8-3.2-5.7-4.9-8.5
     c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-1-0.1-1,1-1.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0,0.5,0.3,0.1
     c0.4-0.4,0.5-0.9,0.3-1.4c0-0.1,0-0.1,0-0.2c0.2-0.4,0.6-0.6,0.9-0.9c0.2-0.1,0.3,0.5,0.6,0.1c0.1-0.6,0.4-1.1,1-1.3
     c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.1C212.4,126.3,212.5,126.3,212.6,126.3z"
                  />
                  <path
                    d="M257.6,146.7c3.1-4.9,6.1-9.6,9.2-14.3c-0.2-0.3-0.4-0.2-0.6-0.2c-2.2,0-4.5,0-6.7,0c-0.5,0-0.7-0.1-0.6-0.7
     c0-1.2,0-2.5,0-3.7c0-0.4,0.1-0.5,0.5-0.5c5.4,0,10.8,0,16.4,0c-0.8,1.2-1.5,2.4-2.2,3.5c-2.2,3.5-4.5,6.9-6.7,10.4
     c-0.1,0.2-0.3,0.4-0.2,0.5c0.1,0.3,0.4,0.1,0.6,0.1c1.8,0,3.5,0,5.3,0c1.8,0,2.7,0.9,2.6,2.8c0,0.1,0,0.2,0,0.3
     c0,1.7,0,1.7-1.7,1.7c-4.9,0-9.9,0-14.8,0C258.2,146.7,258,146.7,257.6,146.7z"
                  />
                  <path
                    d="M311.1,129.6c0-3,0-5.9,0-8.9c0-0.7,0.2-0.9,0.8-0.8c0.4,0,0.9,0,1.3,0c1.7,0,2.6,1,2.7,2.6c0,1.3,0,2.6,0,3.8
     c0,0.7,0.2,0.9,0.9,0.8c1.1-0.1,2.3,0,3.4,0c0.4,0,0.5,0.1,0.5,0.4c0,0.8,0,1.6-0.1,2.3c-0.1,1.1-0.9,1.9-2,2
     c-0.7,0.1-1.4,0.1-2.1,0.1c-0.4,0-0.6,0.1-0.6,0.5c0,2,0,4,0,5.9c0.1,1.8,1.6,3.2,3.5,3.3c0.2,0,0.5,0,0.7,0
     c0.4,0,0.5,0.1,0.5,0.5c0,1.3,0,2.6,0,3.8c0,0.3-0.1,0.4-0.5,0.5c-5.1,0.3-9.1-3.4-9.1-8.5C311.1,135.3,311.1,132.4,311.1,129.6z
     "
                  />
                  <path
                    fill="#B53E6B"
                    d="M202.7,109.9c0.1,0.2,0.3,0.4,0.4,0.7c0.2,0.4,0.3,0,0.5,0c2.6,4.4,5.1,8.8,7.5,13.2
     c0.2,0.4,0.3,0.8,0.2,1.3c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2-0.1-0.3-0.3-0.3-0.6c0,0.6-0.4,0.7-0.9,0.8c0,0.2,0,0.4-0.1,0.5
     c-0.1,0.5-0.6,0.2-0.9,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.5,0.1,0,0.4-0.1,0.6c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.1-0.2,0.1
     c-0.2-0.1-0.3-0.2-0.5,0c-0.2-0.2-0.4-0.2-0.5,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.1,0.5-0.1,0.7c-0.2,0.8-0.2,0.8-0.8,0.4
     c-1.9-3.3-3.8-6.6-5.7-10c-0.6-1.1-1.2-2.2-1.8-3.3c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.2-0.2-0.3-0.3-0.5c0.1-0.1,0.2-0.2,0.2-0.3
     c0.3,0,0.6,0.2,0.7-0.3c0-0.1,0.2-0.2,0.2-0.2c0.4,0.3,0.4,0,0.5-0.3c0.1-0.3,0.5-0.2,0.7-0.3c0.1,0,0.2,0,0.3-0.1
     c0.1-0.4,0.2-0.8,0.8-0.8c0.1,0,0.1-0.3,0.2-0.5c0.1-0.2,0.4-0.2,0.4-0.5c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.1-0.5,0.2-0.7
     c0.1,0,0.1-0.1,0.2,0c0.1,0.1,0.1,0.3,0.1,0.4C202.5,110.7,202.5,110.3,202.7,109.9z"
                  />
                  <path
                    d="M306.7,137.2c0,2.9,0,5.9,0,8.8c0,0.7-0.2,0.8-0.8,0.8c-1-0.1-2.1,0-3.1,0c-0.6,0-0.8-0.1-0.8-0.8c0-5.1,0-10.1,0-15.2
     c0-0.8,0-1.7,0-2.5c0-0.7,0.1-0.9,0.8-0.9c1.1,0.1,2.1,0.1,3.2,0c0.6,0,0.7,0.1,0.7,0.7C306.7,131.1,306.7,134.1,306.7,137.2z"
                  />
                  <path
                    fill="#B53E6B"
                    d="M187.8,124.6c0.2,0,0.1-0.6,0.4-0.1c0.2-0.3,0.7-0.2,0.6-0.7c0.2-0.2,0-0.5,0.2-0.7
     c0.3,0,0.3,0.3,0.4,0.6c-0.1-0.8,0.6-0.9,0.8-1.4c0.4-0.1,0.7-0.3,0.9-0.7c0.1-0.1,0.3,0,0.2-0.2c0,0,0-0.1,0-0.1
     c0-0.2,0-0.4,0-0.7c0.3,0,0.3,0.4,0.3,0.3c0.2-0.9,0.9-0.4,1.4-0.4c0.3,0,0.7,0.2,0.9-0.2c0.6,0,1.2,0,1.8,0c1.6,0,2.7,0.7,3.5,2
     c1,1.7,2,3.5,3.1,5.3c-5.5,0-10.8,0-16.2,0c-0.5-0.2-0.4-0.6-0.3-1c0-0.1,0.1-0.1,0.1-0.2c0.1-0.3,0-0.6,0.1-0.9
     c0,0,0.1-0.1,0.1-0.1c0.3,0.2,0.1,0.6,0.2,0.8c0.2-0.3-0.1-0.7,0.3-0.9c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.2,0,0.3c0-0.1,0-0.1,0-0.2
     c0-0.1,0-0.2-0.1-0.2c-0.2-0.4,0.2-0.7,0.2-1.1c0.2,0.3,0.2,0.6,0.2,0.9c0.1,0.4,0.3,0.1,0.4,0
     C187.7,125.2,187.5,124.7,187.8,124.6z"
                  />
                  <path
                    d="M304.4,120.2c0.5,0,1.1,0,1.6,0c0.5-0.1,0.7,0.1,0.7,0.6c0,1.1,0,2.3,0,3.4c0,0.5-0.1,0.6-0.6,0.6c-1.2,0-2.3,0-3.5,0
     c-0.5,0-0.6-0.1-0.6-0.6c0-1.2,0-2.3,0-3.5c0-0.4,0.1-0.6,0.6-0.6C303.2,120.2,303.8,120.2,304.4,120.2
     C304.4,120.2,304.4,120.2,304.4,120.2z"
                  />
                  <path
                    fill="#AD4474"
                    d="M205.9,129.3c0.4,0.1,0.8,0.1,0.7-0.5c0.2-0.2,0-0.6,0.2-0.8l0,0c0.3-0.1,0.3,0.2,0.4,0.3
     c0,0.1,0,0.1,0,0.2c-0.1,0.6,0.2,0.4,0.6,0.3c0.3,0.1,0.3,0.3,0.3,0.6c0,0.2,0,0.3,0,0.5c-0.1,0.1-0.2,0.2-0.2,0.2
     c-0.6-0.2-0.9,0-0.8,0.7c0,0.1-0.1,0.2-0.1,0.3C206.5,130.5,206.2,129.9,205.9,129.3z"
                  />
                  <path
                    fill="#AF4473"
                    d="M210.9,125.3c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0.1,0,0.1,0c0.2,0,0.1-0.5,0.4-0.3c0.2,0.3,0.3,0.7,0.5,1
     c-0.2,0.1-0.3,0.2-0.5,0.3c-0.6,0.3-0.8,0.8-1,1.3c0,0-0.1,0-0.1-0.1c-0.2-0.4-0.2-0.9-0.3-1.3
     C210.4,125.8,210.6,125.5,210.9,125.3z"
                  />
                  <path
                    fill="#BC3A64"
                    d="M185.8,126.6c0,0.3-0.2,0.7,0.2,1c-0.3,0-0.7,0-1,0c-0.1-0.1-0.2-0.3-0.1-0.5c0.1-0.2,0.1-0.5,0.3-0.7
     c0.2-0.1,0.1-0.3,0.2-0.5c0.2,0.1,0,0.4,0.3,0.5C185.7,126.4,185.7,126.6,185.8,126.6z"
                  />
                  <path
                    fill="#C3365C"
                    d="M184.9,127.1c0,0.2,0,0.3,0,0.5c-0.3,0-0.6,0-0.8,0c-0.2-0.3-0.1-0.5,0-0.8c0-0.1,0-0.3,0.2-0.3
     c0.1,0,0.2,0,0.2,0.1c0,0,0,0.1,0.1,0.1c0.1-0.1,0.2-0.2,0.3,0C185,126.7,184.9,126.9,184.9,127.1z"
                  />
                  <path
                    fill="#B04E79"
                    d="M211.2,125.1c-0.1-0.5-0.2-0.9-0.2-1.4c0.3,0.2,0.4,0.6,0.5,0.9C211.4,124.7,211.7,125.1,211.2,125.1
     L211.2,125.1z"
                  />
                  <path
                    fill="#9A4D86"
                    d="M211.7,126.1c0-0.3,0.1-0.5,0.5-0.3c0.2,0.1,0.2,0.3,0.3,0.5c-0.1,0.1-0.1,0.1-0.2,0.2
     c-0.4,0-0.2-0.3-0.3-0.4c0,0.2,0.1,0.4-0.2,0.4C211.9,126.3,211.8,126.2,211.7,126.1z"
                  />
                  <path
                    fill="#9D4C83"
                    d="M211.2,125.1c0.1-0.2,0.2-0.4,0.4-0.5c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
     c0.1,0.2,0,0.4-0.1,0.4C211.3,125.5,211.3,125.3,211.2,125.1z"
                  />
                  <path
                    fill="#B93C66"
                    d="M184.1,126.8c0.2,0.3,0,0.5,0,0.8c-0.1,0-0.1,0-0.2,0c-0.1-0.2-0.1-0.5-0.3-0.6
     C183.7,126.7,183.9,126.6,184.1,126.8z"
                  />
                  <path
                    fill="#C14568"
                    d="M198,115.4c0.5,0,0.6,0.3,0.3,0.7C198.2,115.9,198.1,115.6,198,115.4z"
                  />
                  <path
                    fill="#C83356"
                    d="M183.6,126.9c0.3,0.1,0.4,0.4,0.3,0.6c-0.1,0-0.1,0-0.2,0C183.7,127.4,183.7,127.2,183.6,126.9z"
                  />
                  <path
                    d="M297.2,131.5c-1.3-1.8-3-3.1-5.2-3.8c-2.9-0.9-5.7-0.5-8.4,1.1c0-0.3,0-0.4,0-0.6c0-2.5,0-4.9,0-7.4
     c0-0.6-0.2-0.7-0.7-0.7c-0.9,0-1.8,0-2.6,0c-1.5,0-1.5,0-1.5,1.5c0,8.1,0,16.2,0,24.3c0,0.7,0.2,0.9,0.8,0.8c1,0,2,0,3,0
     c0.7,0,1.4,0,1.1-1c5.6,2.3,10.1,1.6,13-2C299.6,140,299.8,135.3,297.2,131.5z M289.1,142.4c-2.9,0-5.1-2.2-5.2-5.2
     c-0.1-2.7,2.3-5,5-5.1c2.8,0,5.1,2.2,5.2,5C294.2,140,291.9,142.4,289.1,142.4z"
                  />
                  <path
                    fill="#C1375D"
                    d="M200,113.5c-0.6,0-0.6,0.7-0.9,1.1c-0.1-0.2-0.2-0.3-0.3-0.4c-0.2,0.4-0.3,1.2-0.9,0.4
     c-0.5-1-0.3-1.4,0.8-1.5c0.1,0,0.3,0.2,0.4-0.1c0.1-0.6,0.4-0.1,0.7-0.1C200.1,112.9,200.5,113,200,113.5z"
                  />
                  <path
                    fill="#BD3962"
                    d="M200,113.5c0.2-0.4-0.1-0.5-0.3-0.7c0.2-0.3,0.4-0.5,0.7-0.7c0.7-0.4,0.1-1.2,0.5-1.6
     c0.3,0.3,0.1,0.7,0.4,1c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.1,0.3-0.1,0.4c-0.1,0.5-0.1,0.4-0.6,0.7c-0.2,0.1-0.4,0.3-0.3,0.7
     C200.5,113.7,200.4,114.1,200,113.5z"
                  />
                  <path
                    fill="#C3365C"
                    d="M190.3,122.3c0,0.3,0.1,0.6-0.5,0.7c-0.2,0-0.3,0.7-0.5,1.1c-0.1-0.3-0.2-0.6-0.3-0.9
     c-0.2,0.1-0.1,0.4-0.3,0.5c0.1-0.6-0.4-1.3,0.2-1.9c0,0,0.1,0,0.1,0c0,0.1,0,0.1,0,0.2c0.7,0.5,0-0.5,0.3-0.3l0,0
     C189.7,121.9,190.2,121.9,190.3,122.3z"
                  />
                  <path
                    fill="#BB3B64"
                    d="M190.3,122.3c-0.3-0.2-0.6-0.3-0.8-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0,0.3,0.1,0.3-0.1
     c0-0.1,0.1-0.3,0.1-0.3c0.6,0,0.6,0,0.6-0.5c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.2c0.2,0,0.1-0.2,0.1-0.2
     c0-0.1,0-0.2,0.1-0.3c0.2,0.3,0,0.6,0.3,0.7c0,0,0,0,0,0c0.1,0.2,0,0.3-0.2,0.3C191.1,122.1,191,122.6,190.3,122.3z"
                  />
                  <path
                    fill="#BA3B65"
                    d="M184.9,127.1c0-0.1-0.3-0.1-0.4-0.2c-0.2-0.1,0-0.4-0.3-0.5c0-0.1,0.1-0.2,0.1-0.2
     c0.2-0.2,0.2-0.6,0.5-0.5c0.3,0.1,0.2,0.5,0.2,0.7C185.1,126.7,185.2,126.9,184.9,127.1z"
                  />
                  <path
                    fill="#BD3962"
                    d="M187.8,124.6c-0.1,0.2,0.2,0.9-0.5,0.5c-0.4-0.5,0.1-0.8,0.2-1.2C187.8,124.1,187.8,124.3,187.8,124.6z"
                  />
                  <path
                    fill="#C63458"
                    d="M202.7,109.9c0.3,0.6-0.4,1.1-0.4,1.7c-0.2-0.3,0-0.7-0.3-0.9c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
     c0.2-0.2,0.1-0.4,0.2-0.7c0.4,0,0.1,0.4,0.3,0.6C202.5,110.2,202.3,109.9,202.7,109.9L202.7,109.9z"
                  />
                  <path
                    fill="#B73D69"
                    d="M185.8,126.6c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.3,0-0.6-0.1-1c0-0.1,0-0.3,0.2-0.3c0.1,0,0.1,0.1,0.2,0.2
     c0,0,0,0,0,0c-0.2,0.3,0,0.7-0.2,1C185.8,126.5,185.8,126.5,185.8,126.6z"
                  />
                  <path
                    fill="#B73D69"
                    d="M202,110.5c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c-0.2,0.2,0,0.6-0.2,0.8l0,0c-0.5-0.1-0.3-0.5-0.3-0.7
     c0,0,0-0.1,0-0.1c0.1-0.2,0.1-0.4,0.2-0.5C201.9,110.3,201.9,110.4,202,110.5z"
                  />
                  <path
                    fill="#B83C68"
                    d="M202.7,109.9c-0.2,0.1-0.2,0.3-0.2,0.5c-0.2-0.1-0.1-0.4-0.3-0.5C202.4,109.5,202.5,109.5,202.7,109.9z"
                  />
                  <path
                    fill="#B83C68"
                    d="M189.5,121.7c0,0.3,0.1,0.6-0.1,0.9c-0.1-0.2-0.2-0.4-0.2-0.5C189.2,122,189.4,121.9,189.5,121.7z"
                  />
                  <path
                    fill="#B53E6B"
                    d="M188.1,123c0.1,0.3,0.1,0.5,0,0.6C187.9,123.4,187.9,123.3,188.1,123z"
                  />
                  <path
                    fill="#B93C66"
                    d="M189.4,121.2c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.2,0.2c0,0.1-0.1,0.2-0.1,0.2
     C189.5,121.4,189.5,121.3,189.4,121.2z"
                  />
                  <path
                    fill="#B53E6B"
                    d="M201.4,111.5c0-0.3-0.1-0.6,0.2-0.8c0,0,0,0,0,0C201.6,111,201.8,111.3,201.4,111.5L201.4,111.5z"
                  />
                  <path
                    d="M252.2,136.9c-1.9-4.9-3.8-9.8-5.6-14.7c-0.5-1.3-1.4-2.1-2.4-2.1c-1.1,0-1.9,0.6-2.4,1.9c-2.5,6.2-5.1,12.5-7.6,18.7
     c-0.8,2-1.6,3.9-2.4,6c1.7,0,3.2,0,4.8,0c0.4,0,0.5-0.1,0.7-0.5c0.5-1.2,1-2.5,1.5-3.7c0.2-0.5,0.4-0.7,1-0.7c2.9,0,5.7,0,8.6,0
     c0.6,0,0.8,0.2,1,0.7c0.3,0.8,0.5,1.5,0.9,2.2c0.6,1.2,1.6,1.9,3,1.9c0.9,0,1.8,0,2.9,0C254.7,143.4,253.4,140.1,252.2,136.9z
      M241.4,137.3c-0.3,0-0.6,0.1-0.4-0.5c1-2.4,2-4.9,3.1-7.5c1.1,2.7,2.1,5.3,3.1,8C245.1,137.3,243.3,137.3,241.4,137.3z"
                  />
                  <path
                    fill="#9E4B81"
                    d="M208.7,126.3c0.1,0,0.2-0.4,0.4-0.1c0.2,0.3,0.3,0.1,0.4-0.1c0.1-0.1,0.1-0.1,0.2-0.1
     c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.2,0.4,0.2,0.6c-0.1,0.2-0.1,0.3-0.3,0.3c-0.1,0.4-0.5,0.6-0.8,0.8c-0.5,0-0.5-0.5-0.6-0.8
     C208.6,127,208.7,126.6,208.7,126.3z"
                  />
                  <path
                    fill="#9A4D86"
                    d="M209.2,128.3c0.7,1-0.3,1.4-0.6,2.1c-0.3-0.3,0-0.6-0.2-0.8c-0.2-0.2-0.2-0.5-0.1-0.7
     c0.2,0,0.3-0.1,0.4-0.3C208.8,128.4,208.9,128.2,209.2,128.3z"
                  />
                  <path
                    fill="#AD4273"
                    d="M209.2,128.3c-0.3,0.1-0.3,0.3-0.4,0.6c-0.2,0.4-0.3-0.1-0.4-0.1c0,0,0-0.1-0.1-0.1c0-0.2,0-0.4,0-0.7
     c0.1-0.3,0.2-0.6,0.4-0.8c0.3,0.2,0.2,0.7,0.5,0.8C209.2,128.2,209.2,128.2,209.2,128.3z"
                  />
                  <path
                    fill="#AF4171"
                    d="M210.1,127.3c0.1-0.1,0.2-0.2,0.2-0.3c0.4,0,0.3,0.4,0.5,0.5c0,0,0,0,0,0
     C210.3,128.4,210.3,127.3,210.1,127.3z"
                  />
                  <path
                    fill="#974F88"
                    d="M208.1,129.8c-0.1-0.1-0.2-0.2-0.2-0.3c0.3-0.1-0.2-0.7,0.4-0.6c0,0.3,0.1,0.5,0,0.8
     C208.2,129.7,208.1,129.7,208.1,129.8z"
                  />
                  <path
                    fill="#B1406F"
                    d="M211.9,126.4c0.1-0.1,0.2-0.3,0.2-0.4c0.1,0.1,0.2,0.3,0.2,0.4C212.2,126.8,212.1,126.8,211.9,126.4z"
                  />
                  <path
                    fill="#B53E6B"
                    d="M208.2,129.6c0-0.3,0-0.6,0-0.8c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0.3,0,0.6,0,0.8
     C208.4,129.6,208.3,129.6,208.2,129.6L208.2,129.6z"
                  />
                  <path
                    fill="#9E4B81"
                    d="M210.9,125.3c0,0.4-0.3,0.6-0.5,0.8c0,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.3-0.2-0.4-0.3
     c-0.1-0.1-0.1-0.3-0.3-0.4c0.1-0.1,0.1-0.3,0.1-0.3c0.8,0.3,0.7-0.4,1-0.8C210.8,124.7,210.9,124.9,210.9,125.3z"
                  />
                  <path
                    fill="#9F4A81"
                    d="M207.7,128.8c-0.1,0.2-0.2,0.2-0.4,0.2c0,0-0.1,0.2-0.2,0.4c-0.1-0.4-0.3-0.7-0.1-1
     c0-0.2,0.1-0.4,0.3-0.5c0.2-0.2,0.3-0.2,0.5,0C208.1,128.2,208,128.5,207.7,128.8z"
                  />
                  <path
                    fill="#AF4171"
                    d="M208.7,127.3c0,0.3-0.1,0.6-0.3,0.8c-0.1,0.1-0.1,0.1-0.2,0c-0.1-0.1-0.2-0.2-0.1-0.4c0-0.2,0-0.3,0-0.5
     c0.2-0.2,0-0.6,0.3-0.7c0.1-0.1,0.2-0.2,0.3-0.3C209,126.6,208.8,126.9,208.7,127.3z"
                  />
                  <path
                    fill="#AF4170"
                    d="M208.1,127.8c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.1,0.4,0,0.7c0,0,0,0,0,0c-0.3,0.1-0.1,0.5-0.3,0.7
     c0-0.2-0.1-0.5-0.2-0.7c0.1-0.3,0.1-0.7,0.2-1C207.9,127.8,208,127.8,208.1,127.8z"
                  />
                  <path
                    fill="#C4355A"
                    d="M201.4,111.5c0.3-0.2,0.1-0.6,0.2-0.8c0.3,0.2,0,0.6,0.3,0.8c0,0.3,0.1,0.7-0.5,0.5
     C201.4,111.9,201.4,111.7,201.4,111.5z"
                  />
                  <path
                    fill="#9D4C83"
                    d="M207.4,127.8c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.2,0.4-0.4,0.4-0.7
     C207.2,127.4,207.3,127.6,207.4,127.8z"
                  />
                  <path
                    fill="#9D4B82"
                    d="M208.4,126.6c-0.1,0.2-0.2,0.4-0.3,0.7c0-0.1,0-0.3-0.1-0.4c0-0.2-0.4-0.3-0.1-0.5
     C208.1,126.3,208.2,126.6,208.4,126.6z"
                  />
                  <path
                    fill="#B53E6B"
                    d="M209.6,125.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.1,0.3-0.2,0.3l-0.1,0c0,0-0.1,0-0.1,0
     C209.6,125.9,209.6,125.7,209.6,125.4z"
                  />
                  <path
                    fill="#9E4B82"
                    d="M210.3,123.6c-0.1,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2
     C210.2,123.5,210.3,123.6,210.3,123.6z"
                  />
                  <path
                    fill="#974F88"
                    d="M206.7,127.9c0,0.3,0.1,0.6-0.2,0.8C206.6,128.5,206.4,128.2,206.7,127.9z"
                  />
                  <path
                    fill="#C83356"
                    d="M201.9,111.5c0-0.3-0.1-0.6,0.2-0.8C202,111,202.1,111.3,201.9,111.5z"
                  />
                  <path
                    fill="#C83356"
                    d="M185.8,126.4c0.1-0.3-0.1-0.7,0.2-1C185.9,125.8,186.1,126.1,185.8,126.4z"
                  />
                  <path
                    fill="#C53559"
                    d="M191.1,121.6c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.3,0.2,0.2,0.4C191.3,122,191.3,121.6,191.1,121.6z"
                  />
                  <path
                    fill="#C83356"
                    d="M186.5,125.4c0.3,0.2,0.1,0.4,0.2,0.8C186.4,125.9,186.5,125.7,186.5,125.4
     C186.5,125.4,186.5,125.4,186.5,125.4z"
                  />
                  <path
                    fill="#9F4B81"
                    d="M210.2,126.1c0.1,0,0.1,0,0.2,0c0.4,0.4,0.2,0.9,0.3,1.3c-0.2-0.2-0.3-0.3-0.5-0.5
     c-0.1-0.2-0.1-0.3-0.2-0.5C210,126.3,210.1,126.2,210.2,126.1z"
                  />
                  <path
                    fill="#B04170"
                    d="M210.2,126.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2-0.1-0.3-0.2-0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.3
     C210,125.9,210.1,126,210.2,126.1z"
                  />
                  <path
                    fill="#9D4C83"
                    d="M208.2,128.8c0-0.2-0.2-0.4,0-0.7c0.1,0,0.1,0,0.2,0c0.2,0.2,0,0.4,0,0.7
     C208.3,128.8,208.3,128.8,208.2,128.8z"
                  />
                </g>
              </a>
            </g>
            <g>
              <a
                target="_blank"
                xlink:href="https://coinsbit.io/ieo-list/TELE_104"
              >
                <path
                  fill="#FFFFFF"
                  d="M543.2,156H373.9c-5.9,0-10.8-4.8-10.8-10.8V99.6c0-5.9,4.8-10.8,10.8-10.8h169.4
   c5.9,0,10.8,4.8,10.8,10.8v45.7C554,151.2,549.2,156,543.2,156z"
                />
              </a>
            </g>
            <path
              opacity="0.75"
              fill="#AB47BC"
              d="M1044.5,181h-342c-8.5,0-15.5-7-15.5-15.5v-127c0-8.5,7-15.5,15.5-15.5h342
 c8.5,0,15.5,7,15.5,15.5v127C1060,174,1053,181,1044.5,181z"
            />
            <g display="none">
              <path
                display="inline"
                fill="#F5F5F5"
                d="M734.1,58.2l5.3-16.2h3.4l5.3,16.2h-2.8l-1.3-3.9h-5.9l-1.2,3.9H734.1z M738.8,51.9h4.6
   l-2.3-7.6L738.8,51.9z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M749.7,58.2l1.9-16.2h2.6l3.9,13l3.9-13h2.6l1.9,16.2H764l-1.5-12.8l0.4,0l-3.5,12.7
   h-2.6l-3.6-12.7l0.5,0l-1.4,12.7H749.7z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M768.2,58.2l5.3-16.2h3.4l5.3,16.2h-2.8l-1.3-3.9h-5.9l-1.2,3.9H768.2z M772.8,51.9h4.6
   l-2.3-7.6L772.8,51.9z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M794.1,58.4c-0.3,0-0.7,0-1.1,0c-0.4,0-0.9,0-1.3-0.1c-0.4,0-0.9-0.1-1.2-0.1
   s-0.7-0.1-0.9-0.2v-1.3h4.9c0.4,0,0.8,0,1.1-0.1c0.3-0.1,0.5-0.2,0.7-0.4c0.2-0.2,0.3-0.5,0.3-1v-0.5c0-0.4-0.1-0.8-0.4-1
   c-0.3-0.2-0.8-0.4-1.5-0.4H793c-0.7,0-1.3-0.1-1.8-0.3c-0.5-0.2-1-0.5-1.3-0.9s-0.5-1-0.5-1.8v-0.6c0-0.7,0.1-1.4,0.4-1.8
   c0.3-0.5,0.7-0.8,1.4-1.1c0.6-0.2,1.5-0.4,2.5-0.4c0.4,0,0.9,0,1.5,0.1c0.5,0,1.1,0.1,1.6,0.1c0.5,0.1,0.9,0.1,1.2,0.2v1.3h-4.7
   c-0.6,0-1.1,0.1-1.5,0.3c-0.3,0.2-0.5,0.6-0.5,1.3v0.4c0,0.4,0.1,0.7,0.2,0.9c0.2,0.2,0.4,0.3,0.7,0.4s0.7,0.1,1.1,0.1h1.8
   c1.1,0,2,0.3,2.5,0.8c0.6,0.5,0.9,1.3,0.9,2.2v0.9c0,0.8-0.2,1.4-0.6,1.8c-0.4,0.4-0.9,0.7-1.5,0.9
   C795.8,58.3,795,58.4,794.1,58.4z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M805.7,58.4c-1.2,0-2.2-0.2-3-0.5c-0.7-0.4-1.3-1-1.6-1.9s-0.5-2.1-0.5-3.6
   c0-1.6,0.2-2.8,0.5-3.7c0.3-0.9,0.8-1.5,1.6-1.8c0.7-0.4,1.7-0.5,3-0.5c1.1,0,2,0.1,2.6,0.3c0.7,0.2,1.2,0.6,1.5,1.2
   c0.3,0.6,0.5,1.4,0.5,2.4c0,0.7-0.1,1.3-0.4,1.7c-0.3,0.4-0.7,0.7-1.2,0.9c-0.5,0.2-1.1,0.3-1.9,0.3h-3.9c0,0.9,0.1,1.5,0.3,2
   c0.2,0.5,0.5,0.9,1,1.1s1.2,0.3,2.1,0.3h3.7V58c-0.6,0.1-1.3,0.2-1.9,0.3C807.3,58.3,806.6,58.4,805.7,58.4z M802.8,51.8h3.7
   c0.6,0,1-0.1,1.3-0.3c0.3-0.2,0.4-0.6,0.4-1.2s-0.1-1-0.3-1.4c-0.2-0.3-0.4-0.6-0.8-0.7c-0.4-0.1-0.9-0.2-1.5-0.2
   c-0.7,0-1.3,0.1-1.7,0.3c-0.4,0.2-0.7,0.6-0.9,1.2C802.9,50,802.8,50.8,802.8,51.8z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M817,58.4c-0.3,0-0.7,0-1.1,0c-0.4,0-0.9,0-1.3-0.1c-0.4,0-0.9-0.1-1.2-0.1
   c-0.4,0-0.7-0.1-0.9-0.2v-1.3h5c0.4,0,0.8,0,1.1-0.1s0.5-0.2,0.7-0.4c0.2-0.2,0.3-0.5,0.3-1v-0.5c0-0.4-0.1-0.8-0.4-1
   c-0.3-0.2-0.8-0.4-1.5-0.4h-1.7c-0.7,0-1.3-0.1-1.8-0.3c-0.5-0.2-1-0.5-1.3-0.9c-0.3-0.4-0.5-1-0.5-1.8v-0.6
   c0-0.7,0.1-1.4,0.4-1.8c0.3-0.5,0.7-0.8,1.4-1.1c0.6-0.2,1.5-0.4,2.5-0.4c0.4,0,0.9,0,1.5,0.1c0.5,0,1.1,0.1,1.6,0.1
   c0.5,0.1,0.9,0.1,1.2,0.2v1.3h-4.7c-0.6,0-1.1,0.1-1.5,0.3s-0.5,0.6-0.5,1.3v0.4c0,0.4,0.1,0.7,0.2,0.9c0.2,0.2,0.4,0.3,0.7,0.4
   s0.7,0.1,1.1,0.1h1.8c1.1,0,2,0.3,2.5,0.8c0.6,0.5,0.9,1.3,0.9,2.2v0.9c0,0.8-0.2,1.4-0.6,1.8s-0.9,0.7-1.5,0.9
   C818.7,58.3,817.9,58.4,817,58.4z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M828.1,58.4c-0.3,0-0.7,0-1.1,0c-0.4,0-0.9,0-1.3-0.1c-0.4,0-0.9-0.1-1.2-0.1
   c-0.4,0-0.7-0.1-0.9-0.2v-1.3h5c0.4,0,0.8,0,1.1-0.1s0.5-0.2,0.7-0.4c0.2-0.2,0.3-0.5,0.3-1v-0.5c0-0.4-0.1-0.8-0.4-1
   c-0.3-0.2-0.8-0.4-1.5-0.4h-1.7c-0.7,0-1.3-0.1-1.8-0.3c-0.5-0.2-1-0.5-1.3-0.9c-0.3-0.4-0.5-1-0.5-1.8v-0.6
   c0-0.7,0.1-1.4,0.4-1.8c0.3-0.5,0.7-0.8,1.4-1.1c0.6-0.2,1.5-0.4,2.5-0.4c0.4,0,0.9,0,1.5,0.1c0.5,0,1.1,0.1,1.6,0.1
   c0.5,0.1,0.9,0.1,1.2,0.2v1.3h-4.7c-0.6,0-1.1,0.1-1.5,0.3s-0.5,0.6-0.5,1.3v0.4c0,0.4,0.1,0.7,0.2,0.9c0.2,0.2,0.4,0.3,0.7,0.4
   s0.7,0.1,1.1,0.1h1.8c1.1,0,2,0.3,2.5,0.8c0.6,0.5,0.9,1.3,0.9,2.2v0.9c0,0.8-0.2,1.4-0.6,1.8s-0.9,0.7-1.5,0.9
   C829.7,58.3,829,58.4,828.1,58.4z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M835.3,44.5c-0.3,0-0.4-0.1-0.4-0.4v-1.8c0-0.3,0.1-0.4,0.4-0.4h1.6c0.1,0,0.2,0,0.3,0.1
   s0.1,0.2,0.1,0.3v1.8c0,0.2-0.1,0.4-0.4,0.4H835.3z M835,58.2V46.4h2.2v11.8H835z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M845.1,58.4c-0.9,0-1.7-0.1-2.3-0.3s-1.2-0.5-1.6-1c-0.4-0.4-0.7-1.1-0.9-1.8
   c-0.2-0.8-0.3-1.8-0.3-2.9c0-1.2,0.1-2.2,0.3-3s0.5-1.4,0.9-1.8c0.4-0.4,1-0.7,1.6-0.9s1.4-0.3,2.3-0.3s1.6,0.1,2.3,0.3
   c0.7,0.2,1.2,0.5,1.6,0.9c0.4,0.4,0.7,1.1,0.9,1.8s0.3,1.8,0.3,3c0,1.2-0.1,2.2-0.3,3c-0.2,0.8-0.5,1.4-0.9,1.8s-1,0.8-1.6,0.9
   S845.9,58.4,845.1,58.4z M845.1,56.5c0.5,0,0.9,0,1.3-0.1c0.4-0.1,0.7-0.3,0.9-0.6c0.2-0.3,0.4-0.7,0.6-1.3
   c0.1-0.6,0.2-1.3,0.2-2.3c0-0.9-0.1-1.7-0.2-2.3c-0.1-0.6-0.3-1-0.6-1.3c-0.2-0.3-0.6-0.5-0.9-0.6c-0.4-0.1-0.8-0.1-1.3-0.1
   c-0.5,0-0.9,0-1.3,0.1c-0.4,0.1-0.7,0.3-0.9,0.6c-0.2,0.3-0.4,0.7-0.5,1.3c-0.1,0.6-0.2,1.3-0.2,2.3c0,0.9,0.1,1.7,0.2,2.3
   c0.1,0.6,0.3,1,0.5,1.3c0.2,0.3,0.6,0.5,0.9,0.6C844.1,56.5,844.6,56.5,845.1,56.5z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M852.9,58.2V46.4h1.8l0.4,1.3c0.5-0.4,1.2-0.7,1.9-1c0.7-0.3,1.5-0.5,2.3-0.5
   c0.9,0,1.6,0.2,2.1,0.6c0.5,0.4,0.9,0.9,1.2,1.5c0.2,0.6,0.4,1.3,0.4,2v7.9h-2.2v-7.7c0-0.5-0.1-0.9-0.3-1.2
   c-0.2-0.3-0.4-0.6-0.8-0.8c-0.3-0.2-0.7-0.3-1.2-0.3c-0.4,0-0.8,0-1.2,0.1c-0.4,0.1-0.7,0.2-1.1,0.4c-0.3,0.2-0.7,0.3-1.1,0.6v8.9
   H852.9z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M761.9,84.8c-0.3,0-0.7,0-1.1,0c-0.4,0-0.9,0-1.3-0.1c-0.4,0-0.9-0.1-1.2-0.1
   c-0.4,0-0.7-0.1-0.9-0.2v-1.3h5c0.4,0,0.8,0,1.1-0.1s0.5-0.2,0.7-0.4c0.2-0.2,0.3-0.5,0.3-1v-0.5c0-0.4-0.1-0.8-0.4-1
   c-0.3-0.2-0.8-0.4-1.5-0.4h-1.7c-0.7,0-1.3-0.1-1.8-0.3c-0.5-0.2-1-0.5-1.3-0.9c-0.3-0.4-0.5-1-0.5-1.8V76c0-0.7,0.1-1.4,0.4-1.8
   c0.3-0.5,0.7-0.8,1.4-1.1c0.6-0.2,1.5-0.4,2.5-0.4c0.4,0,0.9,0,1.5,0.1c0.5,0,1.1,0.1,1.6,0.1c0.5,0.1,0.9,0.1,1.2,0.2v1.3h-4.7
   c-0.6,0-1.1,0.1-1.5,0.3s-0.5,0.6-0.5,1.3v0.4c0,0.4,0.1,0.7,0.2,0.9c0.2,0.2,0.4,0.3,0.7,0.4s0.7,0.1,1.1,0.1h1.8
   c1.1,0,2,0.3,2.5,0.8c0.6,0.5,0.9,1.3,0.9,2.2v0.9c0,0.8-0.2,1.4-0.6,1.8s-0.9,0.7-1.5,0.9C763.6,84.7,762.8,84.8,761.9,84.8z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M772.9,84.6c-0.8,0-1.4-0.1-2-0.4c-0.5-0.3-0.9-0.7-1.1-1.2c-0.2-0.6-0.4-1.3-0.3-2.3
   l0.1-6h-1.9v-1.4l1.9-0.4l0.3-3.3h1.7v3.3h3.2v1.8h-3.2v6c0,0.5,0,0.8,0.1,1.1c0.1,0.3,0.2,0.5,0.4,0.6c0.2,0.1,0.3,0.3,0.5,0.3
   c0.2,0.1,0.4,0.1,0.5,0.1l1.5,0.1v1.6H772.9z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M779.4,84.8c-0.9,0-1.6-0.3-2.1-0.8s-0.8-1.2-0.8-2.2v-0.9c0-0.9,0.3-1.7,0.9-2.2
   c0.6-0.6,1.6-0.9,2.8-0.9h3.7v-1.2c0-0.5-0.1-0.8-0.2-1.2c-0.2-0.3-0.4-0.6-0.8-0.7c-0.4-0.2-1-0.2-1.8-0.2h-3.6v-1.3
   c0.5-0.1,1.1-0.2,1.9-0.3c0.7-0.1,1.5-0.1,2.5-0.2c1,0,1.8,0.1,2.4,0.4c0.7,0.2,1.1,0.6,1.5,1.2c0.3,0.5,0.5,1.3,0.5,2.2v8.2h-1.7
   l-0.4-1.3c-0.1,0.1-0.3,0.2-0.5,0.3s-0.6,0.3-1.1,0.5c-0.4,0.2-0.9,0.3-1.4,0.4C780.4,84.7,779.9,84.8,779.4,84.8z M780.4,83.1
   c0.2,0,0.5,0,0.8-0.1c0.3-0.1,0.6-0.1,0.9-0.2s0.6-0.2,0.8-0.3c0.3-0.1,0.5-0.2,0.6-0.2c0.2-0.1,0.3-0.1,0.3-0.1v-3.4l-3.1,0.1
   c-0.8,0-1.3,0.2-1.6,0.6c-0.3,0.3-0.5,0.8-0.5,1.3v0.5c0,0.4,0.1,0.8,0.3,1c0.2,0.3,0.4,0.4,0.7,0.5S780.1,83.1,780.4,83.1z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M789,84.6V72.8h1.7l0.5,2c0.5-0.6,1.1-1.1,1.7-1.5c0.6-0.4,1.3-0.6,2.2-0.6
   c0.2,0,0.4,0,0.5,0s0.3,0.1,0.5,0.1v2.3c-0.2,0-0.4-0.1-0.6-0.1s-0.4,0-0.7,0c-0.5,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.3-1.1,0.5
   c-0.4,0.2-0.7,0.5-1.1,0.9v8H789z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M802.5,84.6c-0.8,0-1.4-0.1-2-0.4c-0.5-0.3-0.9-0.7-1.1-1.2c-0.2-0.6-0.4-1.3-0.3-2.3
   l0.1-6h-1.9v-1.4l1.9-0.4l0.3-3.3h1.7v3.3h3.2v1.8h-3.2v6c0,0.5,0,0.8,0.1,1.1c0.1,0.3,0.2,0.5,0.4,0.6c0.2,0.1,0.3,0.3,0.5,0.3
   c0.2,0.1,0.4,0.1,0.5,0.1l1.5,0.1v1.6H802.5z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M810.7,84.8c-0.3,0-0.7,0-1.1,0c-0.4,0-0.9,0-1.3-0.1c-0.4,0-0.9-0.1-1.2-0.1
   s-0.7-0.1-0.9-0.2v-1.3h5c0.4,0,0.8,0,1.1-0.1c0.3-0.1,0.5-0.2,0.7-0.4c0.2-0.2,0.3-0.5,0.3-1v-0.5c0-0.4-0.1-0.8-0.4-1
   c-0.3-0.2-0.8-0.4-1.5-0.4h-1.7c-0.7,0-1.3-0.1-1.8-0.3c-0.5-0.2-1-0.5-1.3-0.9c-0.3-0.4-0.5-1-0.5-1.8V76c0-0.7,0.1-1.4,0.4-1.8
   c0.3-0.5,0.7-0.8,1.4-1.1c0.6-0.2,1.5-0.4,2.5-0.4c0.4,0,0.9,0,1.5,0.1c0.5,0,1.1,0.1,1.6,0.1c0.5,0.1,0.9,0.1,1.2,0.2v1.3H810
   c-0.6,0-1.1,0.1-1.5,0.3c-0.3,0.2-0.5,0.6-0.5,1.3v0.4c0,0.4,0.1,0.7,0.2,0.9s0.4,0.3,0.7,0.4s0.7,0.1,1.1,0.1h1.8
   c1.1,0,2,0.3,2.5,0.8c0.6,0.5,0.9,1.3,0.9,2.2v0.9c0,0.8-0.2,1.4-0.5,1.8c-0.4,0.4-0.9,0.7-1.5,0.9
   C812.4,84.7,811.6,84.8,810.7,84.8z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M828.3,84.8c-0.9,0-1.7-0.1-2.3-0.3c-0.7-0.2-1.2-0.5-1.6-1c-0.4-0.4-0.7-1.1-0.9-1.8
   c-0.2-0.8-0.3-1.8-0.3-2.9c0-1.2,0.1-2.2,0.3-3c0.2-0.8,0.5-1.4,0.9-1.8c0.4-0.4,1-0.7,1.6-0.9c0.7-0.2,1.4-0.3,2.3-0.3
   s1.7,0.1,2.3,0.3c0.7,0.2,1.2,0.5,1.6,0.9c0.4,0.4,0.7,1.1,0.9,1.8c0.2,0.8,0.3,1.8,0.3,3c0,1.2-0.1,2.2-0.3,3
   c-0.2,0.8-0.5,1.4-0.9,1.8s-1,0.8-1.6,0.9S829.2,84.8,828.3,84.8z M828.3,82.9c0.5,0,0.9,0,1.3-0.1c0.4-0.1,0.7-0.3,0.9-0.6
   c0.2-0.3,0.4-0.7,0.6-1.3c0.1-0.6,0.2-1.3,0.2-2.3c0-0.9-0.1-1.7-0.2-2.3s-0.3-1-0.6-1.3c-0.2-0.3-0.6-0.5-0.9-0.6
   c-0.4-0.1-0.8-0.1-1.3-0.1c-0.5,0-0.9,0-1.3,0.1c-0.4,0.1-0.7,0.3-0.9,0.6c-0.2,0.3-0.4,0.7-0.6,1.3s-0.2,1.3-0.2,2.3
   c0,0.9,0.1,1.7,0.2,2.3c0.1,0.6,0.3,1,0.6,1.3c0.2,0.3,0.5,0.5,0.9,0.6C827.4,82.9,827.8,82.9,828.3,82.9z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M836.2,84.6V72.8h1.8l0.4,1.3c0.5-0.4,1.2-0.7,1.9-1c0.7-0.3,1.5-0.5,2.3-0.5
   c0.9,0,1.6,0.2,2.1,0.6c0.5,0.4,0.9,0.9,1.2,1.5c0.2,0.6,0.4,1.3,0.4,2v7.9h-2.2V77c0-0.5-0.1-0.9-0.3-1.2s-0.4-0.6-0.8-0.8
   c-0.3-0.2-0.7-0.3-1.2-0.3c-0.4,0-0.8,0-1.2,0.1c-0.4,0.1-0.7,0.2-1.1,0.4c-0.3,0.2-0.7,0.3-1.1,0.6v8.9H836.2z"
              />
            </g>
            <g display="none">
              <path
                display="inline"
                fill="#F5F5F5"
                d="M726.4,132.4v-15.6h2.6l5.2,9.6l5.2-9.6h2.5v15.6h-2.7v-10.8l-4.1,7.5h-2l-4.1-7.5v10.8
   H726.4z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M748.3,132.7c-0.6,0-1.1-0.1-1.6-0.3c-0.5-0.2-0.9-0.4-1.3-0.7c-0.4-0.3-0.7-0.7-0.9-1.1
   c-0.2-0.4-0.3-0.9-0.3-1.5c0-0.7,0.2-1.4,0.6-1.9c0.4-0.5,0.9-1,1.6-1.3c0.7-0.3,1.5-0.5,2.5-0.5c0.5,0,1,0,1.5,0.1
   c0.5,0.1,0.9,0.2,1.3,0.4v-0.7c0-0.5-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.7-0.8-0.9c-0.4-0.2-0.8-0.3-1.3-0.3c-0.5,0-1,0.1-1.4,0.4
   s-0.7,0.7-0.9,1.2l-2.3-0.5c0.2-0.6,0.5-1.2,1-1.7c0.5-0.5,1-0.8,1.7-1.1c0.7-0.3,1.4-0.4,2.1-0.4c0.7,0,1.4,0.1,2,0.4
   c0.6,0.2,1.1,0.6,1.5,1c0.4,0.4,0.8,0.9,1,1.5c0.2,0.6,0.4,1.2,0.4,2v7H752l-0.1-1.5c-0.3,0.6-0.8,1-1.4,1.3
   C749.9,132.5,749.2,132.7,748.3,132.7z M746.8,129c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.4,0.5,0.7,0.6s0.7,0.2,1.1,0.2
   c0.5,0,1-0.1,1.5-0.4c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4v-0.3c-0.4-0.2-0.8-0.3-1.2-0.4c-0.4-0.1-0.8-0.1-1.2-0.1
   c-0.8,0-1.4,0.2-1.8,0.5C747,128,746.8,128.4,746.8,129z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M757,132.4v-11.5h2.3l0.1,1.9c0.3-0.6,0.7-1.1,1.2-1.5s1.1-0.5,1.8-0.5
   c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0.1,0.5,0.2l-0.3,2.5c-0.2-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4,0-0.5,0c-0.9,0-1.6,0.3-2.1,0.9
   c-0.5,0.6-0.7,1.4-0.7,2.4v5.8H757z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M770.4,132.7c-0.8,0-1.6-0.2-2.3-0.5s-1.3-0.7-1.8-1.3c-0.5-0.5-0.9-1.2-1.2-1.9
   c-0.3-0.7-0.4-1.5-0.4-2.4s0.1-1.7,0.4-2.4s0.7-1.4,1.2-2s1.1-1,1.8-1.3c0.7-0.3,1.5-0.5,2.3-0.5c0.8,0,1.5,0.1,2.2,0.4
   s1.2,0.7,1.7,1.2c0.5,0.5,0.8,1.1,1,1.8l-2.4,0.6c-0.2-0.6-0.6-1-1-1.3c-0.4-0.3-1-0.5-1.5-0.5c-0.6,0-1.1,0.2-1.6,0.5
   c-0.5,0.3-0.8,0.8-1.1,1.4c-0.3,0.6-0.4,1.2-0.4,2c0,0.7,0.1,1.4,0.4,1.9c0.3,0.6,0.6,1,1.1,1.3c0.5,0.3,1,0.5,1.6,0.5
   c0.6,0,1.1-0.2,1.6-0.5c0.5-0.3,0.8-0.7,1.1-1.3l2.4,0.5c-0.2,0.7-0.6,1.3-1.1,1.8c-0.5,0.5-1.1,0.9-1.8,1.2
   C771.9,132.5,771.2,132.7,770.4,132.7z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M777.5,132.4V116h2.5v6.2c0.4-0.5,0.8-0.9,1.4-1.3s1.2-0.5,2-0.5c0.9,0,1.6,0.2,2.3,0.6
   c0.6,0.4,1.1,1,1.5,1.7c0.4,0.7,0.5,1.6,0.5,2.6v6.8h-2.5v-6.6c0-0.9-0.2-1.7-0.6-2.2s-1-0.8-1.7-0.8c-0.5,0-1,0.1-1.4,0.4
   s-0.7,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.5v6.7H777.5z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M798.4,132.4V120l-3.3,1.8l-0.9-2.1l4.6-2.5h2.2v15.2H798.4z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M808.3,132.7c-1.4,0-2.6-0.3-3.7-1l0.8-2.1c0.4,0.2,0.8,0.4,1.3,0.6
   c0.5,0.1,1,0.2,1.6,0.2c0.8,0,1.5-0.2,2.2-0.5s1.1-0.9,1.5-1.7c0.4-0.8,0.6-1.8,0.7-3.1c-0.4,0.6-0.9,1.1-1.5,1.4
   c-0.6,0.3-1.4,0.5-2.2,0.5c-0.7,0-1.4-0.1-2-0.4c-0.6-0.2-1.1-0.6-1.6-1c-0.5-0.4-0.8-1-1.1-1.6c-0.2-0.6-0.4-1.2-0.4-1.9
   c0-0.7,0.1-1.4,0.4-2c0.3-0.6,0.7-1.2,1.1-1.6c0.5-0.5,1.1-0.8,1.7-1.1c0.7-0.3,1.4-0.4,2.1-0.4c0.9,0,1.7,0.2,2.4,0.5
   c0.7,0.3,1.3,0.8,1.9,1.5c0.5,0.7,0.9,1.4,1.2,2.4c0.3,0.9,0.4,2,0.4,3.2c0,1.3-0.2,2.5-0.5,3.5s-0.8,1.9-1.4,2.6
   c-0.6,0.7-1.3,1.2-2.2,1.6C810.3,132.5,809.3,132.7,808.3,132.7z M809.3,124.8c0.5,0,1-0.1,1.5-0.4c0.4-0.2,0.8-0.6,1-1
   c0.2-0.4,0.4-0.9,0.4-1.4c0-0.5-0.1-1-0.4-1.5c-0.2-0.4-0.6-0.8-1-1s-0.9-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.4c-0.4,0.3-0.8,0.6-1,1
   c-0.2,0.4-0.4,0.9-0.4,1.5s0.1,1,0.4,1.4c0.2,0.4,0.6,0.7,1,1C808.3,124.6,808.8,124.8,809.3,124.8z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M817.5,136.2l-0.5-1.5c0.6-0.2,1.1-0.5,1.5-1s0.6-0.9,0.6-1.4c0-0.2,0-0.4-0.1-0.5
   c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.3,0.1-0.5,0.2-0.7
   c0.1-0.2,0.3-0.4,0.6-0.5c0.2-0.1,0.5-0.2,0.8-0.2c0.4,0,0.8,0.1,1.2,0.4c0.3,0.3,0.6,0.6,0.8,1c0.2,0.4,0.3,0.9,0.3,1.5
   c0,0.6-0.1,1.2-0.4,1.8c-0.3,0.5-0.7,1-1.1,1.4S818.1,135.9,817.5,136.2z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M827.1,132.4v-1.9c0.7-0.6,1.4-1.2,2-1.7s1.1-0.9,1.5-1.4c0.4-0.4,0.9-0.8,1.2-1.2
   c0.7-0.7,1.2-1.2,1.6-1.7c0.4-0.5,0.7-1,0.9-1.4c0.2-0.4,0.3-0.9,0.3-1.4c0-0.5-0.1-1-0.3-1.3c-0.2-0.4-0.5-0.7-0.8-0.9
   c-0.4-0.2-0.8-0.3-1.3-0.3c-0.7,0-1.2,0.2-1.7,0.6s-0.8,1-1,1.7L827,121c0.2-0.8,0.5-1.6,1-2.2c0.5-0.6,1.1-1.1,1.8-1.5
   c0.7-0.3,1.5-0.5,2.4-0.5c0.7,0,1.4,0.1,2,0.4c0.6,0.2,1.1,0.6,1.6,1s0.8,0.9,1,1.5c0.2,0.6,0.4,1.2,0.4,1.9
   c0,0.6-0.1,1.2-0.4,1.8c-0.2,0.6-0.6,1.2-1.1,1.9c-0.5,0.6-1.2,1.4-1.9,2.1c-0.8,0.8-1.8,1.7-2.8,2.6h6.5v2.3H827.1z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M845.2,132.7c-0.9,0-1.7-0.2-2.4-0.5c-0.7-0.4-1.3-0.9-1.8-1.6s-0.9-1.5-1.1-2.5
   c-0.3-1-0.4-2.1-0.4-3.3c0-1.2,0.1-2.4,0.4-3.3c0.3-1,0.7-1.8,1.2-2.5c0.5-0.7,1.1-1.2,1.8-1.6c0.7-0.4,1.5-0.5,2.4-0.5
   c0.9,0,1.7,0.2,2.4,0.5c0.7,0.4,1.3,0.9,1.8,1.6c0.5,0.7,0.9,1.5,1.1,2.5c0.3,1,0.4,2,0.4,3.2c0,1.2-0.1,2.3-0.4,3.3
   s-0.6,1.8-1.2,2.5c-0.5,0.7-1.1,1.2-1.8,1.6C846.9,132.5,846.1,132.7,845.2,132.7z M845.3,130.3c1,0,1.7-0.5,2.3-1.4
   c0.5-1,0.8-2.3,0.8-4.1s-0.3-3.1-0.8-4.1c-0.5-1-1.3-1.4-2.3-1.4c-1,0-1.7,0.5-2.3,1.4c-0.5,1-0.8,2.3-0.8,4.1s0.3,3.1,0.8,4.1
   C843.5,129.8,844.3,130.3,845.3,130.3z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M852.8,132.4v-1.9c0.7-0.6,1.4-1.2,2-1.7s1.1-0.9,1.5-1.4c0.4-0.4,0.9-0.8,1.2-1.2
   c0.7-0.7,1.2-1.2,1.6-1.7c0.4-0.5,0.7-1,0.9-1.4c0.2-0.4,0.3-0.9,0.3-1.4c0-0.5-0.1-1-0.3-1.3c-0.2-0.4-0.5-0.7-0.8-0.9
   c-0.4-0.2-0.8-0.3-1.3-0.3c-0.7,0-1.2,0.2-1.7,0.6s-0.8,1-1,1.7l-2.4-0.5c0.2-0.8,0.5-1.6,1-2.2c0.5-0.6,1.1-1.1,1.8-1.5
   c0.7-0.3,1.5-0.5,2.4-0.5c0.7,0,1.4,0.1,2,0.4c0.6,0.2,1.1,0.6,1.6,1s0.8,0.9,1,1.5c0.2,0.6,0.4,1.2,0.4,1.9
   c0,0.6-0.1,1.2-0.4,1.8c-0.2,0.6-0.6,1.2-1.1,1.9c-0.5,0.6-1.2,1.4-1.9,2.1c-0.8,0.8-1.8,1.7-2.8,2.6h6.5v2.3H852.8z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M865.4,132.4v-1.9c0.7-0.6,1.4-1.2,2-1.7s1.1-0.9,1.5-1.4c0.4-0.4,0.9-0.8,1.2-1.2
   c0.7-0.7,1.2-1.2,1.6-1.7c0.4-0.5,0.7-1,0.9-1.4c0.2-0.4,0.3-0.9,0.3-1.4c0-0.5-0.1-1-0.3-1.3c-0.2-0.4-0.5-0.7-0.8-0.9
   c-0.4-0.2-0.8-0.3-1.3-0.3c-0.7,0-1.2,0.2-1.7,0.6s-0.8,1-1,1.7l-2.4-0.5c0.2-0.8,0.5-1.6,1-2.2c0.5-0.6,1.1-1.1,1.8-1.5
   c0.7-0.3,1.5-0.5,2.4-0.5c0.7,0,1.4,0.1,2,0.4c0.6,0.2,1.1,0.6,1.6,1s0.8,0.9,1,1.5c0.2,0.6,0.4,1.2,0.4,1.9
   c0,0.6-0.1,1.2-0.4,1.8c-0.2,0.6-0.6,1.2-1.1,1.9c-0.5,0.6-1.2,1.4-1.9,2.1c-0.8,0.8-1.8,1.7-2.8,2.6h6.5v2.3H865.4z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M878.5,136.2l-0.5-1.5c0.6-0.2,1.1-0.5,1.5-1s0.6-0.9,0.6-1.4c0-0.2,0-0.4-0.1-0.5
   c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.3,0.1-0.5,0.2-0.7
   c0.1-0.2,0.3-0.4,0.6-0.5c0.2-0.1,0.5-0.2,0.8-0.2c0.4,0,0.8,0.1,1.2,0.4c0.3,0.3,0.6,0.6,0.8,1c0.2,0.4,0.3,0.9,0.3,1.5
   c0,0.6-0.1,1.2-0.4,1.8c-0.3,0.5-0.7,1-1.1,1.4S879.2,135.9,878.5,136.2z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M746.3,159.1c-1.4,0-2.6-0.3-3.7-1l0.8-2.1c0.4,0.2,0.8,0.4,1.3,0.6
   c0.5,0.1,1,0.2,1.6,0.2c0.8,0,1.5-0.2,2.2-0.5s1.1-0.9,1.5-1.7c0.4-0.8,0.6-1.8,0.7-3.1c-0.4,0.6-0.9,1.1-1.5,1.4
   c-0.6,0.3-1.4,0.5-2.2,0.5c-0.7,0-1.4-0.1-2-0.4c-0.6-0.2-1.1-0.6-1.6-1c-0.5-0.4-0.8-1-1.1-1.6c-0.2-0.6-0.4-1.2-0.4-1.9
   c0-0.7,0.1-1.4,0.4-2c0.3-0.6,0.7-1.2,1.1-1.6c0.5-0.5,1.1-0.8,1.7-1.1c0.7-0.3,1.4-0.4,2.1-0.4c0.9,0,1.7,0.2,2.4,0.5
   c0.7,0.3,1.3,0.8,1.9,1.5c0.5,0.7,0.9,1.4,1.2,2.4c0.3,0.9,0.4,2,0.4,3.2c0,1.3-0.2,2.5-0.5,3.5s-0.8,1.9-1.4,2.6
   c-0.6,0.7-1.3,1.2-2.2,1.6C748.2,158.9,747.3,159.1,746.3,159.1z M747.3,151.2c0.5,0,1-0.1,1.5-0.4c0.4-0.2,0.8-0.6,1-1
   c0.2-0.4,0.4-0.9,0.4-1.4c0-0.5-0.1-1-0.4-1.5c-0.2-0.4-0.6-0.8-1-1s-0.9-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.4c-0.4,0.3-0.8,0.6-1,1
   c-0.2,0.4-0.4,0.9-0.4,1.5s0.1,1,0.4,1.4c0.2,0.4,0.6,0.7,1,1C746.2,151,746.7,151.2,747.3,151.2z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M756.5,158.8c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.5-0.3-0.6-0.6c-0.2-0.3-0.2-0.5-0.2-0.8
   s0.1-0.6,0.2-0.8c0.2-0.3,0.4-0.5,0.6-0.6c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2s0.5,0.3,0.6,0.6c0.2,0.3,0.2,0.5,0.2,0.8
   s-0.1,0.6-0.2,0.8c-0.2,0.3-0.4,0.5-0.6,0.6S756.8,158.8,756.5,158.8z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M765.6,159.1c-0.9,0-1.7-0.2-2.4-0.5c-0.7-0.4-1.3-0.9-1.8-1.6s-0.9-1.5-1.1-2.5
   c-0.3-1-0.4-2.1-0.4-3.3c0-1.2,0.1-2.4,0.4-3.3c0.3-1,0.7-1.8,1.2-2.5c0.5-0.7,1.1-1.2,1.8-1.6c0.7-0.4,1.5-0.5,2.4-0.5
   c0.9,0,1.7,0.2,2.4,0.5c0.7,0.4,1.3,0.9,1.8,1.6c0.5,0.7,0.9,1.5,1.1,2.5c0.3,1,0.4,2,0.4,3.2c0,1.2-0.1,2.3-0.4,3.3
   s-0.6,1.8-1.2,2.5c-0.5,0.7-1.1,1.2-1.8,1.6C767.3,158.9,766.5,159.1,765.6,159.1z M765.6,156.7c1,0,1.7-0.5,2.3-1.4
   c0.5-1,0.8-2.3,0.8-4.1s-0.3-3.1-0.8-4.1c-0.5-1-1.3-1.4-2.3-1.4c-1,0-1.7,0.5-2.3,1.4c-0.5,1-0.8,2.3-0.8,4.1s0.3,3.1,0.8,4.1
   C763.9,156.2,764.6,156.7,765.6,156.7z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M779,159.1c-0.9,0-1.7-0.2-2.4-0.5c-0.7-0.4-1.3-0.9-1.8-1.6s-0.9-1.5-1.1-2.5
   c-0.3-1-0.4-2.1-0.4-3.3c0-1.2,0.1-2.4,0.4-3.3c0.3-1,0.7-1.8,1.2-2.5c0.5-0.7,1.1-1.2,1.8-1.6c0.7-0.4,1.5-0.5,2.4-0.5
   c0.9,0,1.7,0.2,2.4,0.5c0.7,0.4,1.3,0.9,1.8,1.6c0.5,0.7,0.9,1.5,1.1,2.5c0.3,1,0.4,2,0.4,3.2c0,1.2-0.1,2.3-0.4,3.3
   s-0.6,1.8-1.2,2.5c-0.5,0.7-1.1,1.2-1.8,1.6C780.7,158.9,779.9,159.1,779,159.1z M779.1,156.7c1,0,1.7-0.5,2.3-1.4
   c0.5-1,0.8-2.3,0.8-4.1s-0.3-3.1-0.8-4.1c-0.5-1-1.3-1.4-2.3-1.4c-1,0-1.7,0.5-2.3,1.4c-0.5,1-0.8,2.3-0.8,4.1s0.3,3.1,0.8,4.1
   C777.3,156.2,778.1,156.7,779.1,156.7z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M790.9,158.8l6.2-15.6h2.9l6.2,15.6h-2.8l-1.5-3.9h-7l-1.5,3.9H790.9z M795.9,152.6h5.2
   l-2.6-6.8L795.9,152.6z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M808.2,158.8v-15.6h2.6l5.2,9.6l5.2-9.6h2.5v15.6H821V148l-4.1,7.5h-2l-4.1-7.5v10.8
   H808.2z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M837.1,161.9h-2.5c-1.2-1.5-2.1-3.1-2.8-4.8c-0.6-1.7-0.9-3.5-0.9-5.4
   c0-1.9,0.3-3.7,0.9-5.4c0.6-1.7,1.5-3.3,2.8-4.8h2.5c-1.3,1.5-2.2,3.2-2.9,4.9c-0.6,1.7-1,3.5-1,5.3s0.3,3.6,1,5.3
   C834.9,158.7,835.8,160.3,837.1,161.9z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M839,158.8v-15.6h9.5v2.3h-6.8v4.1h6.2v2.3h-6.2v4.6h7v2.3H839z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M855.1,158.8v-13.2h-4.6v-2.4h11.8v2.4h-4.6v13.2H855.1z"
              />
              <path
                display="inline"
                fill="#F5F5F5"
                d="M863.6,141.4h2.5c1.2,1.5,2.1,3.1,2.8,4.8s0.9,3.5,0.9,5.4c0,1.9-0.3,3.7-0.9,5.4
   s-1.5,3.3-2.8,4.8h-2.5c1.3-1.5,2.2-3.2,2.9-4.9c0.6-1.7,1-3.5,1-5.3s-0.3-3.6-1-5.3C865.8,144.6,864.9,142.9,863.6,141.4z"
              />
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        fill="#FFD143"
                        d="M1042.5,111.7c0.2-0.1,0.1-0.3-0.4-0.2l-0.3,0.3L1042.5,111.7z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1039.2,111.1c-0.6,0.2-1.2,0.4-0.9,0.7c0.6-0.3,0.7-0.2,1.3-0.6c0.5,0,0.9,0.1,0.8,0.2
           c0.2-0.1,0.8,0.1,1-0.1c-0.6-0.3-1.4-0.3-2.3-0.1C1039,111.2,1039.2,111.1,1039.2,111.1z"
                      />
                      <path
                        fill="#FFD143"
                        d="M991.3,110.5l0.3,0C991.6,110.6,991.5,110.5,991.3,110.5z"
                      />
                      <path
                        fill="#FFD143"
                        d="M961.7,110.4c0.2,0,0.3,0,0.3-0.1C961.8,110.3,961.6,110.3,961.7,110.4z"
                      />
                      <path
                        fill="#FFD143"
                        d="M956.6,110c0.1,0,0.2,0,0.3,0C956.8,110,956.7,110,956.6,110z"
                      />
                      <path
                        fill="#FFD143"
                        d="M991.3,110.5l-0.8-0.1C990.5,110.5,991,110.5,991.3,110.5z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1032.6,111.9c0.2,0,0.3,0,0.4-0.1C1032.9,111.8,1032.8,111.8,1032.6,111.9
           C1032.6,111.8,1032.6,111.8,1032.6,111.9z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1004.4,110.3l0.2,0c0.1,0,0.1-0.1,0.2-0.1L1004.4,110.3z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1014.4,110.2c-0.2,0-0.3,0.3-0.4,0.4c0.1-0.1,0.3-0.2,0.5-0.2
           C1014.6,110.4,1014.5,110.3,1014.4,110.2z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1030.4,110.7l0.3,0C1030.6,110.7,1030.4,110.7,1030.4,110.7z"
                      />
                      <path
                        fill="#FFD143"
                        d="M997.2,110.2c0,0-0.1-0.1-0.1-0.1C997.1,110.1,997.2,110.2,997.2,110.2z"
                      />
                      <path
                        fill="#FFD143"
                        d="M716.6,112.3C716.6,112.3,716.6,112.3,716.6,112.3C716.6,112.3,716.6,112.3,716.6,112.3z"
                      />
                      <path
                        fill="#FFD143"
                        d="M705,110.9l0.2,0c0-0.1,0-0.1,0-0.2L705,110.9z"
                      />
                      <path
                        fill="#FFD143"
                        d="M947.4,109.6C947.4,109.6,947.4,109.6,947.4,109.6C947.6,109.6,947.5,109.6,947.4,109.6z"
                      />
                      <path
                        fill="#FFD143"
                        d="M862.3,109.8c1,0.3-1.6,0.3-0.1,0.6C862,110.3,863.5,110,862.3,109.8z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1032.3,111.8c-0.1,0-0.3,0-0.4,0C1032.1,111.8,1032.2,111.8,1032.3,111.8z"
                      />
                      <path
                        fill="#FFD143"
                        d="M862.2,110.4C862.2,110.4,862.2,110.4,862.2,110.4l0.1,0C862.3,110.5,862.2,110.4,862.2,110.4z"
                      />
                      <path
                        fill="#FFD143"
                        d="M926,109.8c0,0,0.1,0.1,0.2,0.1C926.2,109.9,926.2,109.9,926,109.8z"
                      />
                      <path
                        fill="#FFD143"
                        d="M941.9,110.2l-0.4,0C941.7,110.2,941.8,110.2,941.9,110.2z"
                      />
                      <path
                        fill="#FFD143"
                        d="M917.8,110.2c-0.2,0-0.3-0.1-0.6,0c0,0,0.1,0,0.1,0C917.4,110.2,917.5,110.2,917.8,110.2z"
                      />
                      <path
                        fill="#FFD143"
                        d="M909.2,110.2c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0,0.3,0L909.2,110.2z"
                      />
                      <path
                        fill="#FFD143"
                        d="M742.8,112.2c-0.2-0.1-0.3-0.2-0.4-0.3C742.5,112.1,741.2,112.2,742.8,112.2z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1032.6,111.7c-0.1,0.1-0.1,0.1-0.3,0.1c0.1,0,0.2,0,0.3,0C1032.7,111.8,1032.7,111.7,1032.6,111.7z"
                      />
                      <path
                        fill="#FFD143"
                        d="M917.4,112.4l-1.4,0C916.4,112.4,916.9,112.4,917.4,112.4z"
                      />
                      <path
                        fill="#FFD143"
                        d="M804.4,112.1l-0.3,0C804.2,112.2,804.3,112.2,804.4,112.1z"
                      />
                      <path
                        fill="#FFD143"
                        d="M912,112.4c-0.2-0.1-0.5-0.2-0.8-0.2c-0.1,0-0.1,0-0.2,0.1L912,112.4z"
                      />
                      <path
                        fill="#FFD143"
                        d="M742.4,111.9C742.4,111.9,742.4,111.9,742.4,111.9C742.4,111.9,742.4,111.9,742.4,111.9z"
                      />
                      <path
                        fill="#FFD143"
                        d="M733.4,112.6c1.3,0,0.6-0.4,0.9-0.6c1.6-0.1,0.7,0.3,1.2,0.5c1-0.1,1.7-0.4,2.4-0.4
           c0.1,0.1,0.2,0.1,0,0.2c1-0.3,3,0,4.2-0.4c0.1,0,0.1,0.1,0.2,0.1c-0.2-0.2-0.2-0.4-0.2-0.5c0.6,0,1.1-0.2,1.3,0
           c-0.4,0-0.4,0.1-0.6,0.2l0.6-0.1c0.6,0.3-0.8,0.1-0.8,0.4c1.2-0.1,2.6-0.4,3.5-0.3c0.2,0.3-2.1,0.1-0.9,0.4l-1.4-0.1
           c1.1,0.5-0.9,0,0.1,0.5c1.9,0.1,2.1-0.2,3.9-0.1c0.4-0.2-0.1-0.3,0.3-0.5c2.6-0.3,2.1,0.9,4,0.4c0.5-0.2,0.7-0.7,2.1-0.4
           l-0.5,0.4c1.8,0,1-0.6,2.9-0.3c-0.3,0.2-1.4,0.1-1.1,0.2c1.2,0.3,0.9-0.2,1.8-0.2l0.2,0.3c1.9-0.1,4-0.3,6.2-0.4
           c-0.1,0.1-0.5,0.2,0,0.3c2.7-0.2,5.7,0,8.3,0.2l1-0.3c0.2,0,0.4,0.1,0.2,0.2c1,0.1,0.1-0.2,0.5-0.3c0.6,0,0.8,0.2,0.4,0.3
           c1.8-0.2,5.1-0.5,6.9-0.2l-0.4,0.1c1.5,0.1,1.6-0.6,3-0.3l-0.3,0.1c3.2,0.1,6.7-0.1,9.1,0.3c0.5-0.4,3.1,0.2,3.3-0.4
           c1.8,0.3,1.3,0.4,3.8,0.6c0.7,0,1-0.4,1.9-0.4l0,0.3l1.1-0.3l0.6,0.4l0.8-0.1c-0.3,0-0.7-0.1-0.6-0.2c1.2-0.1,3,0.2,3,0.4
           l2.9-0.3c-0.1,0.1-0.6,0.2-0.9,0.2c1.6-0.3,0.3,0.3,1.7,0c-0.2-0.1,0.1-0.2,0.1-0.2c0.7,0.2,2.3,0.2,3.5,0.1l-0.2,0.2
           c2.2,0.1,4.6-0.1,7-0.1c-0.9-0.1-0.7-0.5,0-0.5l0.3,0.3c0.5-0.2-0.7-0.1,0.2-0.4c0.9,0,0.6,0.4,0.1,0.5l1.1-0.1
           c0.1,0.1-0.2,0.1-0.3,0.3c0.5-0.2,1.9,0.3,2.6,0l0.1,0.1c2.4,0,3.5-0.1,5.5-0.1c0-0.3,0.8-0.2,1.4-0.4
           c1.5,0.1,3.1,0.3,4.8,0.3c1.2-0.1,0.4-0.2,0.9-0.4c0.1,0.3,1.8,0.2,0.9,0.4c2.1,0,2.7-0.1,5.3-0.3c-0.3-0.1-0.6-0.2-0.4-0.2
           c0.5,0,1.1,0.1,1,0.2l-0.1,0c3-0.4,6.9,0.7,9.2,0.2l-0.1,0l2.5-0.3c1.1,0-0.7,0.2,0.2,0.4c2.7-0.4,4.2,0.1,6.4-0.2
           c1.7,0.8,6-0.1,8.3,0.4l-0.1-0.2c0.6-0.2,0.7,0.1,1.1,0.1c-0.1-0.1-0.6-0.2-0.3-0.3c5-0.2,10.3,0.3,15.4,0.4
           c1.9,0,1.1-0.9,3.4-0.5l-0.7,0.3c3.8-0.4,7.7,0,11.4-0.3c-0.2,0.1-0.2,0.2-0.6,0.2c0.8,0.2,2.9,0,2.9-0.2
           c0.6,0,0.1,0.2,0.5,0.3c0.8,0.1,2-0.3,2.4-0.1c0.1,0-0.2,0.1-0.4,0.1c0.5-0.1,2.4,0,1.9-0.2c1.4,0.1,2.7-0.1,3.8,0
           c0.7-0.3,2.4,0.1,2.6-0.3c-0.1,0.5,2.4,0.4,3.3,0.2c-0.3,0.1,0.8,0.1,0.4,0.3c1,0,1.9-0.1,1.7-0.3c1.8,0.5,3.7-0.4,4.8,0.3
           c3-0.2,5.7-0.4,8.4-0.5c-0.1,0.5-3,0-2.8,0.5c2.5-0.2,4-0.6,6.5-0.5c0.7,0.1-0.4,0.2-0.6,0.3c2.5-0.2,4.9,0,7.2-0.2
           c0,0-0.2,0.1-0.5,0.1c0.4,0,1,0.1,1-0.1l-0.4,0.1c-0.7-0.3,1.8-0.6,1.8-0.8c-0.8,0.6,2.2,0.1,1,0.9c0.3,0,0.7-0.1,0.6-0.3
           c0.4,0,0.6,0.1,0.1,0.3c1.6-0.1,1-0.3,2.5-0.3c0,0.1-0.3,0.1-0.4,0.3c0.7-0.2,1.5-0.4,2.7-0.3c-0.1,0.1,0.1,0.1-0.3,0.1
           c2.1,0,3.6-0.5,5.9-0.3c-0.7,0.4,0.8,0.1,0.9,0.5l1.2-0.1l-0.5-0.2c1.1,0,2-0.3,3-0.1l-0.8,0.2l2-0.1l-1.1-0.1
           c1.1-0.2-0.1-0.5,1.3-0.4c-0.3,0,1.1,0,1.6,0.2c0,0,0,0,0,0c0.5-0.1,1.1-0.2,1.9-0.2c0.2,0.1-0.2,0.4,0,0.5
           c0.5-0.3,2.5-0.1,3.2-0.4c-0.2,0.1-0.2,0.3-0.2,0.3l0.4-0.3c0.5,0.1,0.6,0.1,0.5,0.3c1.5,0-0.5-0.5,1.3-0.3
           c-0.1,0-0.1,0.1-0.4,0.1c1.8,0.1,3.5-0.2,5.1,0c0.5,0.4-1.4,0.1-0.9,0.4c2.4-0.1,4.9-0.5,7.2-0.4l-0.4,0
           c0.3-0.5,1.7,0.3,2.9,0c-0.3,0-0.4,0.3-0.3,0.2c1.6,0.1,3.2-0.1,5.6-0.3l-0.3,0.1c0.9,0.1,2.5,0.1,3.5,0.1
           c0.3-0.1,0.9-0.1,0.5-0.3c3.1,0.8,5.9-0.4,8.7,0.2c-0.3,0-0.8,0.1-0.5,0.1l2.3-0.1c-0.2-0.2-0.3-0.1-0.8-0.3
           c0.3-0.1,1-0.2,1.5-0.2c-1.1,0.2,0,0.4,0.5,0.5c-0.1-0.1,0.1-0.1,0-0.2c1.2,0.4,0.2-0.5,1.9-0.2l-0.1,0.1
           c0.6-0.1,1.2-0.2,1.9-0.2c0.3,0.3-0.7,0.1-0.7,0.3c0.9,0,1.9-0.5,2.7-0.2c-0.4,0.1-1,0.1-1.4,0.1c0.9,0.3,1,0.2,1.7,0.3
           l-0.1,0l1.6,0.1l-0.4-0.1c1.2-0.7,2.4-0.1,4-0.6c-1.6,0.4-0.3,0.2-0.4,0.6c0.4,0.1,1,0.2,0.9,0.3c0.8-0.2,1.3,0.2,2.3-0.2
           c0,0.1,0.8,0,0.6,0.3c1-0.1-0.2-0.2-0.1-0.3c1.5-0.3,3.6,0.4,5.7-0.1c1.9-0.2,2.2-0.5,4-0.7c0.6,0.2-0.5,0.4-0.5,0.4
           c0.9,0.1,1.7,0.1,2.6,0c-0.2,0.1,0,0.2,0.3,0.3c0.7,0,0.5-0.4,1.4-0.2c-0.2,0.1-0.4,0.3-0.7,0.4c0.8-0.1,1.7-0.3,2.6-0.4
           c0.2,0.2-0.5,0.3-0.8,0.4c1-0.1,2.5-0.2,2.9-0.4l-0.6-0.1c0.4,0,0.2,0.2-0.1,0.3c-0.8,0.2-1.2-0.1-1.3-0.2l0.6-0.1
           c-0.6-0.6-2.5,0.1-3.8,0.1l0.5-0.4l-1.3,0.3c-0.3-0.1-0.5-0.3,0.2-0.4c-0.9-0.2-0.8,0.1-1.6-0.1c-0.1,0,0-0.1,0-0.1l-0.9,0.1
           c0.2-0.1-0.1-0.3,0.4-0.2c-1.6-0.2-2.5,0.3-3.1,0l0.1,0c-2.2,0.1-0.2,0.3-1.9,0.7l-1.1-0.8l-0.2,0.3c-0.2,0-0.7,0-0.6-0.2
           c-0.8,0.2,0.1,0.1-0.3,0.3c-1.1-0.3-3,0.1-3.2-0.4c-0.4,0.2,1,0.2-0.1,0.3c-0.3-0.5-1.3,0.1-2.3-0.2c0.3-0.3,1.2-0.1,0.3-0.4
           c-0.6,0.5-1.9-0.1-2.8,0c0,0.4-1.8,0.2-3.2,0.3c0.1-0.1-2.6-0.2-3.9-0.3l0-0.1c-0.3,0.4-1.6,0.3-2.6,0.4
           c0-0.2,0.2-0.3,0.7-0.4l-0.9,0c-0.2,0.1-0.5,0.2-1.1,0.2l0.2-0.3c-1.2,0-2.5,0.3-4.1,0.1c-0.1,0.3,1.3,0.7-0.4,0.9
           c-0.1-0.1-0.1-0.4,0.3-0.4c-0.1,0-0.5,0.1-0.8,0.1l0.7-0.2c-0.7-0.1-0.4,0.2-1,0.2c0-0.2-0.4-0.1-0.3-0.3c0.1,0,0.5,0.1,0.7,0
           c-0.6,0-1.4-0.2-1.7-0.3c0.1,0.1,0,0.3-0.5,0.3c-1.2,0-0.4-0.2-1.1-0.3c-0.3,0.1-1.1,0-1.1,0.3c-0.4,0-0.4-0.1-0.6-0.2
           c-0.6,0.1-3,0-2.3,0.4l0.2,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0h0c-2.1,0.3-4.3-0.2-6.4,0l0-0.1c-1.2,0.1-2.7,0.1-3.6,0.3
           c-0.4,0-0.9-0.2-0.7-0.3c-0.9,0.1-1,0.2-2.4,0c0.9-0.2-0.3-0.2,1-0.1c-1.2-0.1-1.5-0.2-2.8,0c0.4-0.3-0.9-0.3-1.5-0.2l0.9,0.2
           c-0.7,0-1.2,0-1.8,0l0.3-0.3c-2.5-0.4-4.5,0.7-6.4,0c-0.7,0.2,1.4,0.2,0.8,0.4c-1.2,0-2.4-0.5-2.7-0.5
           c-1.6-0.2-1.5,0.5-3.1,0.4c0.2,0.1,0.4,0.4-0.8,0.5c-1.2,0-1.4-0.5-0.7-0.6c0.3,0,0.4,0,0.4,0.1c0.4,0,0.9-0.1,0.4-0.2
           l-0.1,0.1c-0.6-0.1-2.2-0.1-1.8-0.3c-0.9,0.1-0.4,0.2,0.3,0.3c-1.5-0.2-2.3,0-3.8-0.1c0.2,0.1,0.5,0.2,0.2,0.2
           c-2.2-0.1-1,0.2-2.5,0.3c-0.9-0.2,0.3-0.4-1.2-0.3c-0.9-0.1-0.2-0.3,0.2-0.4c-1.4,0.2-2.6-0.2-3.4-0.2l0.7-0.1
           c-0.4,0.1-0.8,0.1-1.2,0.1l0.4,0.2c-0.4,0-0.8,0-0.8-0.1c-0.4,0.2,0.3,0.4-0.5,0.6c-0.6-0.1-1.7,0.1-1.9-0.2
           c1.8,0.1-0.2-0.3,1.3-0.4c-0.3,0-0.7,0-1.1,0c-0.1,0,0.1-0.1,0.2-0.1c-1.7-0.3-0.8,0.4-2.6,0.3c0.2-0.2-0.4-0.3-0.7-0.3
           c0.7,0,0.3,0.3-0.3,0.4c-1.1-0.1-1.2,0.1-1.8,0.1l0.7,0.1c-0.2,0.2-0.8,0.2-1.6,0.2c-0.1-0.1,0.7-0.1,0.4-0.2
           c-0.9,0.3-2-0.3-3.2-0.1c-0.6-0.1-0.1-0.3-0.6-0.4c-1.4,0.2-1.5-0.2-3-0.1c0.4,0.2,0.5,0.2-0.5,0.4l2.4-0.2l-1.5,0.3
           c0.7,0,1.5-0.1,2-0.1c-0.7,0.2-0.7,0.1-0.3,0.3c-1-0.4-2,0.2-3.1,0l0-0.6c-1.7-0.3-3.1,0.3-5.3,0.2l0.7,0.1
           c-0.3,0.2-1.4,0-2.1,0.1c0.1-0.2-0.4-0.3-0.7-0.4c0,0.1-1.6,0-1.8,0.4l-1-0.3c-1.8-0.1-2.1,0.6-3.8,0.5
           c0.5-0.2-0.3-0.4,0.9-0.5c-0.6,0-1.1,0-1.1,0.1c-0.3-0.1-1.6,0.3-2.1,0.1c-0.2,0.1-0.3,0.3-1,0.3c-0.1,0,0-0.1,0-0.1
           c0,0.1-1,0.1-0.4,0.4c-1.8-0.6-5,0-6.5-0.5c-1.3,0.1-2.8,0.1-3.9,0.1c0.1-0.1,0-0.1,0.2-0.1c-1.6-0.2-1,0.6-2.8,0.4
           c-0.9-0.3,1-0.2,0.4-0.3c-0.2-0.6-2.2,0.1-3.4-0.3l0.3,0c-0.9-0.2-2.3,0.2-3.8,0.2c0,0,0.1-0.1,0-0.1c-1,0.3-3,0.4-4.4,0.5
           c0.5-0.3,0.7-0.1,0.4-0.4c-0.7,0,0.2,0.4-1.1,0.5c-0.4-0.1-1.4-0.3-1.5-0.5l1.2,0c-0.6-0.4-1.6,0-2.2,0l0.1-0.2
           c-1.9,0.1-1.9,0.4-3.9,0.4l0.2,0.1c-1.1,0.4-0.9-0.2-2,0.1l-0.3-0.4c-0.7,0.1-2.6,0.4-3.9,0.5c0.7-0.3,2.3-0.4,3.2-0.6
           c-0.7,0-2.5,0-2.8,0.2c0.3,0,0.7-0.1,0.9-0.1c-0.9,0.3-2.1,0.5-3.5,0.5c0.3-0.8-3.8-0.2-4.8-0.8c-2.5,0.3-5.1-0.1-8-0.1
           c1.1,0.6-0.9,0-0.7,0.6c-0.7,0-1,0-1,0l-2.1-0.4c-0.5-0.2,0.7-0.1,0.6-0.3c-1.5,0-0.6-0.3-1.6-0.4c0.2,0.2-0.5,0.3-1.4,0.2
           l1.2,0.3c-2.1,0.3-1.8-0.5-4-0.2l0.7-0.2c-1.1,0.1-4,0-5.1,0.5c-0.2-0.1-0.4-0.2,0-0.3c-2.6-0.1-5.8,0.5-7.8,0.2l0.2-0.1
           c-0.5,0-0.8,0.3-1.5,0.1c0-0.1,0.2-0.1,0-0.2c-0.3,0.1-1.3,0.3-1.9,0.2l0.6-0.2c-2.5-0.2-3.9,0.4-6,0.5c0-0.4-2-0.6-3-0.7
           l0-0.1c-3-0.1-5,0.4-7.8,0.5c-1.7-0.5-5.8-0.2-8.5-0.5c0.4,0.1-0.1,0.3-0.6,0.4c-0.6-0.1-1.8,0.3-1.7-0.1l0.2,0
           c-0.5-0.4-1.8-0.3-2.8-0.3l-0.2,0.5c-2.7-0.8-7.1,0.3-8.6-0.4c-0.7,0.1-1.5,0.1-2.1,0.3l0-0.3c-1.8-0.1-2.7-0.1-4.9,0l0.5-0.2
           c-1.3,0-1.6,0.8-2.8,0.8l-0.3-0.4c-2.2,0-4.4-0.4-6.6,0c0-0.1,0.1-0.2,0.5-0.2c-1,0-4-0.3-3.6,0.3c0-0.4-2.3,0-3.5,0.1
           l0.2-0.3c-1,0.4-1,0.5-2.4,0.6c-0.3,0-0.3-0.2,0.1-0.2c-1.1-0.1-0.9,0.3-2.2,0.1l0.5-0.2c-1.1,0-1,0.1-1.7,0.3
           c-0.7,0.1-2.2-0.1-2.3-0.3c-0.2,0.2-0.8,0.4-1.4,0.3c-0.1-0.1,0.2-0.2,0.3-0.2c-1.9-0.2-2.8,0.3-4.1,0.1
           c0-0.1-0.1-0.1-0.2-0.2c1.5,0,2.7,0,2.6,0c0-0.3-1.2-0.3-0.2-0.7c-1,0.2-2.7,0.6-3.8,0.5c-0.1,0-0.1,0-0.2-0.1l0.1,0
           c-0.3,0-0.5,0-0.8,0c-0.4,0-0.6-0.1-1-0.1c-0.1-0.2-1.4-0.1-2-0.1c0.1,0.3-0.7,0.2,0,0.5c-0.1,0,0.1,0,0.5,0
           c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0.4,0,0.9,0,1.5,0c-0.5,0.1-1,0.3-1.7,0.1c-0.3,0.2-0.7,0.4-0.8,0.5c-0.8-0.4-2.4-0.6-2.7-0.8
           c-1.9,0.2-4.4,0.1-5.5,0.6c-0.5,0.1-0.6-0.2-0.8-0.2c0.3-0.1,0.7,0,0.8-0.1c-1.1,0.1-3.1,0.1-3.4,0.4
           c-1.3-0.2,0.7-0.3-1.1-0.3l0.2-0.1c-3.6-0.6-5.8,0.9-9.8,0.2c0.3,0,0.4,0.1,0.5,0.1c-4.5-0.3-9.4,0.3-13.8-0.2
           c-1.3-0.1-1.4,0.1-2.2,0.3l-0.3-0.3l-1.3,0.3c-1.9,0.3-3.3-0.7-5.7-0.3l0.3,0.2c-0.9,0-2.7,0.1-2.6-0.1
           c-0.1,0-0.3,0.2-0.6,0.1l0-0.2l-2.1,0.3c-1.6-0.1,0.3-0.6-2.1-0.7c-0.9,0.1-2.5,0.5-2.2,0.6c0.4,0,0.9,0.1,0.9,0.3l-0.8,0.1
           c-0.1,0.5-0.8,0.9,1.2,1.3l1.4-0.3l0.4,0.2l-1.2,0c0.7,0.2,1.1,0,1.6-0.1c0.1,0.1,0.3,0.2,0.6,0.2l1.4-0.4
           c0.2,0.2-0.6,0.4,0.3,0.5c0.6-0.2-0.6-0.4,0.4-0.5c0.7,0.2,0.5,0.4,1.2,0.2c0.1,0.1-0.1,0.2-0.4,0.2c0.7-0.1,1.8-0.1,2.2-0.3
           c0.7,0.3,2.3-0.1,2.3,0.4c0.2-0.1,1.2-0.3,1.9-0.4c-1.2,0.5,2.4,0,2.1,0.6c0.9-0.5,3.1-0.2,4.6-0.5c-0.1,0-0.3,0.1-0.4,0.1
           c0.9,0,1.1,0.2,1.7,0.3c0.1-0.4,1.4-0.5,2.2-0.7c1.1,0.4-0.6,0.5-0.3,0.8c-0.1-0.3,1.8-0.3,1.5-0.6c0.8,0.2,0.7,0.1,1.1,0.4
           c0.1-0.5,0.7-0.1,1.4-0.4c1.2,0.2,0.3,0.5,1.6,0.3C734.5,112.4,733.4,112.6,733.4,112.6z M763.8,111.7
           C763.8,111.8,763.8,111.8,763.8,111.7c-0.3,0-0.5,0-0.6,0C763.3,111.8,763.4,111.8,763.8,111.7z M766.3,111.7
           c-0.3,0-0.7,0-1.1,0c-0.1-0.1,0-0.2,0.3-0.2C765.3,111.6,765.9,111.7,766.3,111.7z M760.9,109.8c0.4,0,0.5,0,0.6,0.1
           c-0.2,0-0.4,0-0.7,0c0,0,0,0,0,0C760.8,109.9,760.8,109.8,760.9,109.8z"
                      />
                      <path
                        fill="#FFD143"
                        d="M760.2,110.2c-0.1,0-0.1,0-0.2,0C760.1,110.2,760.2,110.2,760.2,110.2c0.1,0.1,0.2,0,0.2,0
           C760.3,110.2,760.2,110.2,760.2,110.2z"
                      />
                      <path
                        fill="#FFD143"
                        d="M991.7,110.6L991.7,110.6C991.7,110.6,991.7,110.6,991.7,110.6C991.7,110.6,991.7,110.6,991.7,110.6z"
                      />
                      <polygon
                        fill="#FFD143"
                        points="801.6,112.3 801.6,112.2 801.3,112.3 						"
                      />
                      <path
                        fill="#FFD143"
                        d="M1005.8,111.5c0,0,0.1,0.1,0.2,0.1C1006,111.6,1005.9,111.6,1005.8,111.5z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1011.9,111.6l0.9,0.1C1012.3,111.7,1012.1,111.6,1011.9,111.6z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1016.5,111.7c0.1,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0L1016.5,111.7z"
                      />
                      <path
                        fill="#FFD143"
                        d="M942.2,112.1c-0.1,0-0.1,0-0.2,0C941.6,112.1,941.9,112.1,942.2,112.1z"
                      />
                      <path
                        fill="#FFD143"
                        d="M964.3,111.4c-0.2,0.1-0.5,0.1-0.7,0.1C964,111.6,964.3,111.6,964.3,111.4z"
                      />
                      <path
                        fill="#FFD143"
                        d="M917.6,112.4C917.6,112.4,917.6,112.4,917.6,112.4c-0.1,0-0.1,0-0.1,0L917.6,112.4z"
                      />
                      <path
                        fill="#FFD143"
                        d="M962.6,111.6c0.4,0.1,0.7,0,1,0C963.3,111.5,962.9,111.5,962.6,111.6z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1036.8,111.9l0.2,0c0,0,0,0,0,0L1036.8,111.9z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1037,111.8l0.2,0C1037.2,111.8,1037.1,111.8,1037,111.8z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1037.8,111.7l-0.5,0.1C1037.5,111.8,1037.7,111.8,1037.8,111.7z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1030.6,111.7l-0.1,0.1C1030.6,111.8,1030.6,111.8,1030.6,111.7z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1028.7,111.8c0.4,0,1.1,0.2,1.8,0.2l0.1-0.1C1030.2,112,1029.1,111.6,1028.7,111.8z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1020.9,110.5l1.6,0.1c-0.3-0.1-0.6-0.3,0.1-0.3C1020.5,110,1022.9,110.6,1020.9,110.5z"
                      />
                      <path
                        fill="#FFD143"
                        d="M1016,110.4c0.8-0.1,0-0.2,0-0.2C1015.5,110.2,1015.3,110.3,1016,110.4z"
                      />
                      <path
                        fill="#FFD143"
                        d="M980.7,110.4l0.1-0.3C980.1,110.1,980.8,110.2,980.7,110.4z"
                      />
                      <polygon
                        fill="#FFD143"
                        points="973.9,111.7 974.6,111.8 974.9,111.6 						"
                      />
                      <polygon
                        fill="#FFD143"
                        points="970,109.9 970.2,109.8 969.2,109.9 						"
                      />
                      <path
                        fill="#FFD143"
                        d="M910.7,109.9l0.2,0.3C911.3,110.2,911.4,109.9,910.7,109.9z"
                      />
                      <path
                        fill="#FFD143"
                        d="M885.1,110.3c-0.3-0.2-1-0.3-1.4-0.1C884.2,110.2,884.8,110.3,885.1,110.3z"
                      />
                      <polygon
                        fill="#FFD143"
                        points="808.4,109.8 808.9,109.7 807.9,109.7 						"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <a
              target="_blank"
              xlink:href="https://coinsbit.io/ieo-list/TELE_104"
            >
              <g>
                <path
                  fill="#263238"
                  d="M402.4,136.7c-1.2,0.6-3.7,1.2-7,1.2c-8.6,0-13.8-5.4-13.8-13.6c0-8.9,6.2-14.2,14.4-14.2
   c3.2,0,5.6,0.7,6.6,1.2l-1.1,3.9c-1.3-0.6-3-1-5.3-1c-5.5,0-9.4,3.4-9.4,9.9c0,5.9,3.5,9.7,9.4,9.7c2,0,4.1-0.4,5.4-1L402.4,136.7
   z"
                />
                <path
                  fill="#263238"
                  d="M437.7,137.5v-27h5.6l7,11.6c1.8,3,3.4,6.1,4.6,9h0.1c-0.3-3.6-0.4-7.1-0.4-11.2v-9.4h4.6v27H454l-7-11.8
   c-1.7-3-3.5-6.3-4.8-9.3l-0.1,0c0.2,3.5,0.2,7,0.2,11.5v9.6H437.7z"
                />
                <path
                  fill="#263238"
                  d="M464.6,132.2c1.6,0.9,4,1.7,6.5,1.7c3.2,0,4.9-1.5,4.9-3.7c0-2-1.4-3.2-4.8-4.5c-4.4-1.6-7.3-4-7.3-7.8
   c0-4.4,3.7-7.8,9.5-7.8c2.9,0,5.1,0.6,6.5,1.4l-1.2,4c-1-0.5-2.8-1.3-5.4-1.3c-3.1,0-4.5,1.7-4.5,3.2c0,2.1,1.6,3,5.2,4.4
   c4.7,1.8,7,4.1,7,8c0,4.3-3.3,8.1-10.2,8.1c-2.8,0-5.8-0.8-7.3-1.7L464.6,132.2z"
                />
                <path
                  fill="#263238"
                  d="M485.5,110.9c1.6-0.3,4.4-0.6,7.2-0.6c3.6,0,5.9,0.4,7.7,1.7c1.7,1,2.8,2.8,2.8,5c0,2.5-1.6,4.8-4.5,5.8
   v0.1c2.8,0.7,5.4,3,5.4,6.7c0,2.4-1,4.3-2.6,5.6c-1.9,1.7-5.1,2.5-10,2.5c-2.7,0-4.8-0.2-6-0.4V110.9z M490.4,121.6h2.5
   c3.4,0,5.3-1.6,5.3-3.9c0-2.5-1.9-3.7-5-3.7c-1.4,0-2.3,0.1-2.8,0.2V121.6z M490.4,134c0.6,0.1,1.5,0.1,2.6,0.1c3.2,0,6-1.2,6-4.5
   c0-3.1-2.7-4.4-6.1-4.4h-2.4V134z"
                />
                <path fill="#263238" d="M513.5,110.6v27h-4.9v-27H513.5z" />
                <path
                  fill="#263238"
                  d="M524.3,114.7h-7.7v-4.1h20.4v4.1h-7.8v22.8h-4.9V114.7z"
                />
              </g>
            </a>
            <linearGradient
              id="SVGID_2_"
              gradientUnits="userSpaceOnUse"
              x1="404"
              y1="124"
              x2="434"
              y2="124"
            >
              <stop offset="0" style="stop-color: #a87030" />
              <stop offset="0.3371" style="stop-color: #fab217" />
              <stop offset="0.3526" style="stop-color: #fab019" />
              <stop offset="0.552" style="stop-color: #f59935" />
              <stop offset="0.6685" style="stop-color: #f3903f" />
              <stop offset="1" style="stop-color: #a84927" />
            </linearGradient>
            <circle fill="url(#SVGID_2_)" cx="419" cy="124" r="15" />
            <g>
              <path
                fill="#FFECB3"
                d="M419,137.4c-7.4,0-13.4-6-13.4-13.4s6-13.4,13.4-13.4s13.4,6,13.4,13.4S426.4,137.4,419,137.4z M419,111.6
   c-6.9,0-12.4,5.6-12.4,12.4s5.6,12.4,12.4,12.4s12.4-5.6,12.4-12.4S425.9,111.6,419,111.6z"
              />
            </g>
            <g>
              <path fill="#FFECB3" d="M420.1,117.3V130h-2.3v-12.7H420.1z" />
            </g>
            <g id="Layer_8">
              <g>
                <path
                  fill="#FFFFFF"
                  d="M733.8,62.8V49h-4.7v-2.4h12V49h-4.6v13.8H733.8z"
                />
                <path
                  fill="#FFFFFF"
                  d="M746.7,62.9c-0.9,0-1.7-0.1-2.4-0.3s-1.2-0.5-1.7-1c-0.4-0.5-0.7-1.1-0.9-1.8c-0.2-0.8-0.3-1.8-0.3-2.9
     c0-1.2,0.1-2.2,0.3-3c0.2-0.8,0.5-1.4,1-1.8c0.4-0.4,1-0.8,1.7-0.9c0.7-0.2,1.5-0.3,2.4-0.3c0.9,0,1.7,0.1,2.4,0.3
     c0.7,0.2,1.2,0.5,1.6,0.9s0.7,1.1,0.9,1.8c0.2,0.8,0.3,1.8,0.3,3c0,1.2-0.1,2.2-0.3,3s-0.5,1.4-0.9,1.8c-0.4,0.4-1,0.8-1.7,0.9
     S747.7,62.9,746.7,62.9z M746.7,60.7c0.5,0,0.8,0,1.2-0.1c0.3-0.1,0.6-0.2,0.8-0.5c0.2-0.3,0.4-0.6,0.5-1.2s0.2-1.2,0.2-2.1
     c0-0.9-0.1-1.5-0.2-2s-0.3-0.9-0.5-1.2c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3-0.1-0.7-0.1-1.2-0.1c-0.4,0-0.8,0-1.1,0.1
     s-0.6,0.2-0.8,0.5c-0.2,0.3-0.4,0.6-0.5,1.2c-0.1,0.5-0.2,1.2-0.2,2c0,0.9,0.1,1.6,0.2,2.1c0.1,0.5,0.3,0.9,0.5,1.2
     s0.5,0.4,0.8,0.5S746.3,60.7,746.7,60.7z"
                />
                <path
                  fill="#FFFFFF"
                  d="M760.2,62.8V46h2.7v9.3h1.5l2.9-4.3h2.8l-3.7,5.5l4,6.2h-2.8l-3.3-5h-1.5v5H760.2z"
                />
                <path
                  fill="#FFFFFF"
                  d="M772,62.8V51h2.2l0.5,1.2c0.5-0.4,1.1-0.7,1.8-1s1.4-0.4,2.2-0.4c0.9,0,1.7,0.2,2.2,0.6
     c0.5,0.4,0.9,0.9,1.1,1.5c0.2,0.6,0.3,1.3,0.3,2.1v7.9h-2.7v-7.5c0-0.4-0.1-0.8-0.2-1.1c-0.2-0.3-0.4-0.5-0.7-0.7
     c-0.3-0.2-0.6-0.2-1-0.2c-0.4,0-0.7,0-1.1,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.3-0.9,0.5v8.6H772z"
                />
                <path
                  fill="#FFFFFF"
                  d="M790,62.9c-0.9,0-1.7-0.1-2.4-0.3s-1.2-0.5-1.7-1c-0.4-0.5-0.7-1.1-0.9-1.8c-0.2-0.8-0.3-1.8-0.3-2.9
     c0-1.2,0.1-2.2,0.3-3c0.2-0.8,0.5-1.4,1-1.8c0.4-0.4,1-0.8,1.7-0.9c0.7-0.2,1.5-0.3,2.4-0.3c0.9,0,1.7,0.1,2.4,0.3
     c0.7,0.2,1.2,0.5,1.6,0.9s0.7,1.1,0.9,1.8c0.2,0.8,0.3,1.8,0.3,3c0,1.2-0.1,2.2-0.3,3s-0.5,1.4-0.9,1.8c-0.4,0.4-1,0.8-1.7,0.9
     S791,62.9,790,62.9z M790,60.7c0.5,0,0.8,0,1.2-0.1c0.3-0.1,0.6-0.2,0.8-0.5c0.2-0.3,0.4-0.6,0.5-1.2s0.2-1.2,0.2-2.1
     c0-0.9-0.1-1.5-0.2-2s-0.3-0.9-0.5-1.2c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3-0.1-0.7-0.1-1.2-0.1c-0.4,0-0.8,0-1.1,0.1
     s-0.6,0.2-0.8,0.5c-0.2,0.3-0.4,0.6-0.5,1.2c-0.1,0.5-0.2,1.2-0.2,2c0,0.9,0.1,1.6,0.2,2.1c0.1,0.5,0.3,0.9,0.5,1.2
     s0.5,0.4,0.8,0.5S789.6,60.7,790,60.7z"
                />
                <path
                  fill="#FFFFFF"
                  d="M799.5,62.8L796.3,51h2.8l2.2,9.3l2.6-9.3h2.3l2.6,9.3l2.1-9.3h2.8l-3.2,11.8h-3l-2.4-8l-2.4,8H799.5z"
                />
                <path
                  fill="#FFFFFF"
                  d="M821,62.8V51h2.2l0.5,1.2c0.5-0.4,1.1-0.7,1.8-1c0.7-0.3,1.4-0.4,2.3-0.4c0.7,0,1.4,0.2,1.9,0.5
     c0.5,0.3,0.9,0.7,1.1,1.2c0.3-0.3,0.7-0.5,1.2-0.8c0.5-0.3,1-0.5,1.5-0.6c0.5-0.2,1.1-0.3,1.6-0.3c0.9,0,1.6,0.2,2.2,0.5
     c0.6,0.4,0.9,0.9,1.2,1.5c0.2,0.6,0.4,1.4,0.4,2.2v7.7h-2.7v-7.5c0-0.4-0.1-0.8-0.2-1.1c-0.2-0.3-0.4-0.5-0.7-0.7
     c-0.3-0.2-0.6-0.2-1-0.2c-0.5,0-1.1,0.1-1.6,0.3s-1,0.5-1.5,0.7c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4,0,0.5v7.5h-2.7v-7.5
     c0-0.4-0.1-0.8-0.2-1.1c-0.2-0.3-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.2-1-0.2c-0.4,0-0.7,0-1.1,0.1c-0.3,0.1-0.6,0.2-0.9,0.3
     c-0.3,0.1-0.6,0.3-0.9,0.5v8.6H821z"
                />
                <path
                  fill="#FFFFFF"
                  d="M846.6,62.9c-0.9,0-1.7-0.1-2.4-0.3s-1.2-0.5-1.7-1c-0.4-0.5-0.7-1.1-0.9-1.8c-0.2-0.8-0.3-1.8-0.3-2.9
     c0-1.2,0.1-2.2,0.3-3c0.2-0.8,0.5-1.4,1-1.8c0.4-0.4,1-0.8,1.7-0.9c0.7-0.2,1.5-0.3,2.4-0.3c0.9,0,1.7,0.1,2.4,0.3
     c0.7,0.2,1.2,0.5,1.6,0.9s0.7,1.1,0.9,1.8c0.2,0.8,0.3,1.8,0.3,3c0,1.2-0.1,2.2-0.3,3s-0.5,1.4-0.9,1.8c-0.4,0.4-1,0.8-1.7,0.9
     S847.5,62.9,846.6,62.9z M846.6,60.7c0.5,0,0.8,0,1.2-0.1c0.3-0.1,0.6-0.2,0.8-0.5c0.2-0.3,0.4-0.6,0.5-1.2s0.2-1.2,0.2-2.1
     c0-0.9-0.1-1.5-0.2-2s-0.3-0.9-0.5-1.2c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3-0.1-0.7-0.1-1.2-0.1c-0.4,0-0.8,0-1.1,0.1
     s-0.6,0.2-0.8,0.5c-0.2,0.3-0.4,0.6-0.5,1.2c-0.1,0.5-0.2,1.2-0.2,2c0,0.9,0.1,1.6,0.2,2.1c0.1,0.5,0.3,0.9,0.5,1.2
     s0.5,0.4,0.8,0.5S846.1,60.7,846.6,60.7z"
                />
                <path
                  fill="#FFFFFF"
                  d="M854.3,62.8V51h2.1l0.6,1.9c0.5-0.6,1-1.1,1.6-1.5s1.3-0.6,2-0.6c0.2,0,0.3,0,0.5,0
     c0.2,0,0.3,0.1,0.5,0.1v2.8c-0.2,0-0.4-0.1-0.7-0.1s-0.5,0-0.7,0c-0.5,0-0.9,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-1,0.5
     c-0.3,0.2-0.6,0.5-1,0.8v7.7H854.3z"
                />
                <path
                  fill="#FFFFFF"
                  d="M868.3,62.9c-1.3,0-2.3-0.2-3-0.5c-0.8-0.4-1.3-1-1.6-1.8c-0.3-0.9-0.5-2.1-0.5-3.6
     c0-1.6,0.2-2.8,0.5-3.7c0.3-0.9,0.9-1.5,1.6-1.8s1.8-0.5,3-0.5c1.1,0,2,0.1,2.7,0.3c0.7,0.2,1.2,0.6,1.5,1.2
     c0.3,0.6,0.5,1.4,0.5,2.5c0,0.7-0.1,1.3-0.4,1.8s-0.7,0.8-1.3,1c-0.5,0.2-1.2,0.3-1.9,0.3h-3.5c0,0.7,0.1,1.3,0.3,1.7
     c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,1.1,0.3,2,0.3h3.6v1.6c-0.6,0.1-1.2,0.2-1.9,0.3C870,62.8,869.3,62.9,868.3,62.9z M865.8,56.2
     h3.2c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.4-0.6,0.4-1.1c0-0.5-0.1-0.9-0.2-1.2s-0.4-0.5-0.7-0.6c-0.3-0.1-0.8-0.2-1.3-0.2
     c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.6,0.5-0.8,1C865.9,54.6,865.8,55.3,865.8,56.2z"
                />
                <path
                  fill="#FFFFFF"
                  d="M883.6,62.9c-0.9,0-1.6-0.3-2.2-0.8c-0.6-0.5-0.8-1.3-0.8-2.2v-1c0-0.9,0.3-1.6,0.9-2.2
     c0.6-0.6,1.6-0.9,2.9-0.9h3.4v-1c0-0.4-0.1-0.8-0.2-1c-0.1-0.3-0.4-0.5-0.8-0.6c-0.4-0.1-1-0.2-1.7-0.2h-3.6v-1.6
     c0.5-0.1,1.2-0.3,1.9-0.4c0.7-0.1,1.6-0.2,2.6-0.2c1,0,1.8,0.1,2.5,0.3c0.7,0.2,1.2,0.6,1.5,1.2c0.3,0.6,0.5,1.3,0.5,2.3v8.1
     h-2.1l-0.5-1.3c-0.1,0.1-0.3,0.2-0.5,0.4s-0.6,0.3-1,0.5c-0.4,0.2-0.8,0.3-1.3,0.4C884.6,62.9,884.1,62.9,883.6,62.9z M884.9,61
     c0.2,0,0.4,0,0.6-0.1s0.5-0.1,0.7-0.2c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.1,0.5-0.2s0.2-0.1,0.3-0.1v-3.1l-2.6,0.1
     c-0.7,0.1-1.2,0.2-1.5,0.5c-0.3,0.3-0.5,0.7-0.5,1.2v0.5c0,0.4,0.1,0.7,0.2,0.9c0.2,0.2,0.4,0.4,0.6,0.5S884.6,61,884.9,61z"
                />
                <path
                  fill="#FFFFFF"
                  d="M899.5,62.9c-0.5,0-1-0.1-1.4-0.2c-0.4-0.1-0.8-0.3-1.2-0.5c-0.4-0.2-0.7-0.4-1-0.6l-0.5,1.1h-2.3V46h2.7
     v5.9c0.5-0.3,1.1-0.5,1.7-0.7c0.6-0.2,1.2-0.3,1.8-0.3c1,0,1.8,0.2,2.4,0.6c0.6,0.4,1.1,1.1,1.4,2c0.3,0.9,0.4,2.1,0.4,3.6
     c0,1.3-0.1,2.4-0.4,3.3c-0.3,0.9-0.7,1.5-1.3,2C901.3,62.7,900.5,62.9,899.5,62.9z M898.6,60.7c0.5,0,0.9-0.1,1.2-0.4
     s0.6-0.7,0.8-1.2c0.2-0.6,0.3-1.3,0.3-2.2c0-1.1-0.1-1.9-0.3-2.5s-0.5-0.9-0.8-1.1c-0.4-0.2-0.7-0.3-1.2-0.3
     c-0.5,0-1,0.1-1.4,0.2c-0.4,0.2-0.8,0.3-1.2,0.5V60c0.3,0.2,0.7,0.3,1.2,0.5C897.5,60.6,898,60.7,898.6,60.7z"
                />
                <path
                  fill="#FFFFFF"
                  d="M911,62.9c-0.9,0-1.7-0.1-2.4-0.3s-1.2-0.5-1.7-1c-0.4-0.5-0.7-1.1-0.9-1.8c-0.2-0.8-0.3-1.8-0.3-2.9
     c0-1.2,0.1-2.2,0.3-3c0.2-0.8,0.5-1.4,1-1.8c0.4-0.4,1-0.8,1.7-0.9c0.7-0.2,1.5-0.3,2.4-0.3c0.9,0,1.7,0.1,2.4,0.3
     c0.7,0.2,1.2,0.5,1.6,0.9s0.7,1.1,0.9,1.8c0.2,0.8,0.3,1.8,0.3,3c0,1.2-0.1,2.2-0.3,3s-0.5,1.4-0.9,1.8c-0.4,0.4-1,0.8-1.7,0.9
     S911.9,62.9,911,62.9z M911,60.7c0.5,0,0.8,0,1.2-0.1c0.3-0.1,0.6-0.2,0.8-0.5c0.2-0.3,0.4-0.6,0.5-1.2s0.2-1.2,0.2-2.1
     c0-0.9-0.1-1.5-0.2-2s-0.3-0.9-0.5-1.2c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3-0.1-0.7-0.1-1.2-0.1c-0.4,0-0.8,0-1.1,0.1
     s-0.6,0.2-0.8,0.5c-0.2,0.3-0.4,0.6-0.5,1.2c-0.1,0.5-0.2,1.2-0.2,2c0,0.9,0.1,1.6,0.2,2.1c0.1,0.5,0.3,0.9,0.5,1.2
     s0.5,0.4,0.8,0.5S910.5,60.7,911,60.7z"
                />
                <path
                  fill="#FFFFFF"
                  d="M922.1,62.9c-1.1,0-1.9-0.3-2.5-0.9c-0.6-0.6-0.9-1.5-0.9-2.7V51h2.7v7.5c0,0.8,0.2,1.3,0.5,1.6
     s0.8,0.4,1.5,0.4c0.5,0,1-0.1,1.5-0.2c0.5-0.2,0.9-0.4,1.4-0.7V51h2.7v11.8h-2.2l-0.5-1.2c-0.6,0.4-1.2,0.7-1.9,0.9
     C923.7,62.8,922.9,62.9,922.1,62.9z"
                />
                <path
                  fill="#FFFFFF"
                  d="M936,62.8c-0.9,0-1.5-0.1-2.1-0.4c-0.5-0.3-0.9-0.7-1.2-1.3s-0.4-1.4-0.3-2.4l0.1-5.5h-1.8v-1.8l1.9-0.4
     l0.4-3.3h2.1V51h3v2.2h-3v5.5c0,0.4,0,0.7,0.1,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.1
     l1.5,0.2v2H936z"
                />
                <path
                  fill="#FFFFFF"
                  d="M949.5,62.8V49h-4.7v-2.4h12V49h-4.6v13.8H949.5z"
                />
                <path
                  fill="#FFFFFF"
                  d="M962.4,62.9c-1.3,0-2.3-0.2-3-0.5c-0.8-0.4-1.3-1-1.6-1.8c-0.3-0.9-0.5-2.1-0.5-3.6
     c0-1.6,0.2-2.8,0.5-3.7c0.3-0.9,0.9-1.5,1.6-1.8s1.8-0.5,3-0.5c1.1,0,2,0.1,2.7,0.3c0.7,0.2,1.2,0.6,1.5,1.2
     c0.3,0.6,0.5,1.4,0.5,2.5c0,0.7-0.1,1.3-0.4,1.8s-0.7,0.8-1.3,1c-0.5,0.2-1.2,0.3-1.9,0.3h-3.5c0,0.7,0.1,1.3,0.3,1.7
     c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,1.1,0.3,2,0.3h3.6v1.6c-0.6,0.1-1.2,0.2-1.9,0.3C964.1,62.8,963.3,62.9,962.4,62.9z
      M959.8,56.2h3.2c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.4-0.6,0.4-1.1c0-0.5-0.1-0.9-0.2-1.2s-0.4-0.5-0.7-0.6
     c-0.3-0.1-0.8-0.2-1.3-0.2c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.6,0.5-0.8,1C959.9,54.6,959.8,55.3,959.8,56.2z"
                />
                <path
                  fill="#FFFFFF"
                  d="M972.9,62.8c-0.8,0-1.4-0.1-1.9-0.4s-0.9-0.7-1.2-1.2c-0.3-0.6-0.4-1.3-0.4-2.2V46h2.7v12.6
     c0,0.5,0.1,0.9,0.2,1.2c0.1,0.3,0.3,0.5,0.5,0.6s0.5,0.2,0.7,0.3l1,0.1v2H972.9z"
                />
                <path
                  fill="#FFFFFF"
                  d="M980.9,62.9c-1.3,0-2.3-0.2-3-0.5c-0.8-0.4-1.3-1-1.6-1.8c-0.3-0.9-0.5-2.1-0.5-3.6
     c0-1.6,0.2-2.8,0.5-3.7c0.3-0.9,0.9-1.5,1.6-1.8s1.8-0.5,3-0.5c1.1,0,2,0.1,2.7,0.3c0.7,0.2,1.2,0.6,1.5,1.2
     c0.3,0.6,0.5,1.4,0.5,2.5c0,0.7-0.1,1.3-0.4,1.8s-0.7,0.8-1.3,1c-0.5,0.2-1.2,0.3-1.9,0.3h-3.5c0,0.7,0.1,1.3,0.3,1.7
     c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,1.1,0.3,2,0.3h3.6v1.6c-0.6,0.1-1.2,0.2-1.9,0.3C982.6,62.8,981.9,62.9,980.9,62.9z
      M978.4,56.2h3.2c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.4-0.6,0.4-1.1c0-0.5-0.1-0.9-0.2-1.2s-0.4-0.5-0.7-0.6
     c-0.3-0.1-0.8-0.2-1.3-0.2c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.6,0.5-0.8,1C978.5,54.6,978.4,55.3,978.4,56.2z"
                />
                <path
                  fill="#FFFFFF"
                  d="M988.8,62.8v-9.6H987v-1.8l1.7-0.4v-1c0-0.9,0.1-1.7,0.4-2.3c0.2-0.6,0.6-1.1,1.2-1.4s1.3-0.5,2.2-0.5
     c0.5,0,1,0,1.4,0.1c0.4,0.1,0.7,0.1,1,0.2v1.9H993c-0.4,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.1,0.6-0.1,1V51
     h3.2v2.2h-3.2v9.6H988.8z"
                />
                <path
                  fill="#FFFFFF"
                  d="M1000,68l1.4-5.2c-0.6,0-1.1-0.1-1.6-0.3c-0.5-0.2-0.9-0.5-1.2-1c-0.3-0.4-0.6-1-0.8-1.7l-2.4-8.8h2.8
     l2.1,8.2c0.1,0.3,0.2,0.6,0.4,0.8c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0l2.4-9.5h2.8l-3.8,14.8
     l-1.1,2.2H1000z"
                />
                <path
                  fill="#FFFFFF"
                  d="M1008.2,64.6c-0.2,0-0.5,0-0.9-0.1v-0.9c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-0.7h-0.2
     c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-1.8c0-0.3,0.1-0.5,0.4-0.5h1.5c0.2,0,0.3,0,0.3,0.1s0.1,0.2,0.1,0.3v2.9
     c0,0.4-0.1,0.7-0.2,0.9c-0.2,0.2-0.3,0.3-0.6,0.4S1008.4,64.6,1008.2,64.6z"
                />
                <path
                  fill="#FFFFFF"
                  d="M773.5,75.5c-0.3,0-0.4-0.1-0.4-0.4v-2c0-0.3,0.1-0.4,0.4-0.4h2c0.1,0,0.2,0,0.3,0.1
     c0.1,0.1,0.1,0.2,0.1,0.3v2c0,0.3-0.1,0.4-0.4,0.4H773.5z M773.2,94.4v-17h2.7V89c0,1-0.1,1.9-0.2,2.9c-0.1,1-0.3,1.8-0.5,2.5
     H773.2z"
                />
                <path
                  fill="#FFFFFF"
                  d="M783.6,89.3c-0.9,0-1.7-0.1-2.4-0.3s-1.2-0.5-1.7-1c-0.4-0.5-0.7-1.1-0.9-1.8c-0.2-0.8-0.3-1.8-0.3-2.9
     c0-1.2,0.1-2.2,0.3-3c0.2-0.8,0.5-1.4,1-1.8c0.4-0.4,1-0.8,1.7-0.9c0.7-0.2,1.5-0.3,2.4-0.3c0.9,0,1.7,0.1,2.4,0.3
     c0.7,0.2,1.2,0.5,1.6,0.9s0.7,1.1,0.9,1.8c0.2,0.8,0.3,1.8,0.3,3c0,1.2-0.1,2.2-0.3,3s-0.5,1.4-0.9,1.8c-0.4,0.4-1,0.8-1.7,0.9
     S784.6,89.3,783.6,89.3z M783.6,87.1c0.5,0,0.8,0,1.2-0.1c0.3-0.1,0.6-0.2,0.8-0.5c0.2-0.3,0.4-0.6,0.5-1.2s0.2-1.2,0.2-2.1
     c0-0.9-0.1-1.5-0.2-2s-0.3-0.9-0.5-1.2c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3-0.1-0.7-0.1-1.2-0.1c-0.4,0-0.8,0-1.1,0.1
     s-0.6,0.2-0.8,0.5c-0.2,0.3-0.4,0.6-0.5,1.2c-0.1,0.5-0.2,1.2-0.2,2c0,0.9,0.1,1.6,0.2,2.1c0.1,0.5,0.3,0.9,0.5,1.2
     s0.5,0.4,0.8,0.5S783.2,87.1,783.6,87.1z"
                />
                <path
                  fill="#FFFFFF"
                  d="M791.7,75.5c-0.3,0-0.4-0.1-0.4-0.4v-2c0-0.3,0.1-0.4,0.4-0.4h2.1c0.1,0,0.2,0,0.3,0.1
     c0.1,0.1,0.1,0.2,0.1,0.3v2c0,0.3-0.1,0.4-0.4,0.4H791.7z M791.4,89.2V77.4h2.7v11.8H791.4z"
                />
                <path
                  fill="#FFFFFF"
                  d="M796.9,89.2V77.4h2.2l0.5,1.2c0.5-0.4,1.1-0.7,1.8-1s1.4-0.4,2.2-0.4c0.9,0,1.7,0.2,2.2,0.6
     c0.5,0.4,0.9,0.9,1.1,1.5c0.2,0.6,0.3,1.3,0.3,2.1v7.9h-2.7v-7.5c0-0.4-0.1-0.8-0.2-1.1c-0.2-0.3-0.4-0.5-0.7-0.7
     c-0.3-0.2-0.6-0.2-1-0.2c-0.4,0-0.7,0-1.1,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.3-0.9,0.5v8.6H796.9z"
                />
                <path
                  fill="#FFFFFF"
                  d="M820,89.2c-0.9,0-1.5-0.1-2.1-0.4c-0.5-0.3-0.9-0.7-1.2-1.3s-0.4-1.4-0.3-2.4l0.1-5.5h-1.8v-1.8l1.9-0.4
     l0.4-3.3h2.1v3.3h3v2.2h-3v5.5c0,0.4,0,0.7,0.1,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.1
     l1.5,0.2v2H820z"
                />
                <path
                  fill="#FFFFFF"
                  d="M828.9,89.3c-1.3,0-2.3-0.2-3-0.5c-0.8-0.4-1.3-1-1.6-1.8c-0.3-0.9-0.5-2.1-0.5-3.6
     c0-1.6,0.2-2.8,0.5-3.7c0.3-0.9,0.9-1.5,1.6-1.8s1.8-0.5,3-0.5c1.1,0,2,0.1,2.7,0.3c0.7,0.2,1.2,0.6,1.5,1.2
     c0.3,0.6,0.5,1.4,0.5,2.5c0,0.7-0.1,1.3-0.4,1.8s-0.7,0.8-1.3,1c-0.5,0.2-1.2,0.3-1.9,0.3h-3.5c0,0.7,0.1,1.3,0.3,1.7
     c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,1.1,0.3,2,0.3h3.6v1.6c-0.6,0.1-1.2,0.2-1.9,0.3C830.6,89.2,829.8,89.3,828.9,89.3z
      M826.4,82.6h3.2c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.4-0.6,0.4-1.1c0-0.5-0.1-0.9-0.2-1.2s-0.4-0.5-0.7-0.6
     c-0.3-0.1-0.8-0.2-1.3-0.2c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.6,0.5-0.8,1C826.5,81,826.4,81.7,826.4,82.6z"
                />
                <path
                  fill="#FFFFFF"
                  d="M839.4,89.2c-0.8,0-1.4-0.1-1.9-0.4s-0.9-0.7-1.2-1.2c-0.3-0.6-0.4-1.3-0.4-2.2v-13h2.7V85
     c0,0.5,0.1,0.9,0.2,1.2c0.1,0.3,0.3,0.5,0.5,0.6s0.5,0.2,0.7,0.3l1,0.1v2H839.4z"
                />
                <path
                  fill="#FFFFFF"
                  d="M847.5,89.3c-1.3,0-2.3-0.2-3-0.5c-0.8-0.4-1.3-1-1.6-1.8c-0.3-0.9-0.5-2.1-0.5-3.6
     c0-1.6,0.2-2.8,0.5-3.7c0.3-0.9,0.9-1.5,1.6-1.8s1.8-0.5,3-0.5c1.1,0,2,0.1,2.7,0.3c0.7,0.2,1.2,0.6,1.5,1.2
     c0.3,0.6,0.5,1.4,0.5,2.5c0,0.7-0.1,1.3-0.4,1.8s-0.7,0.8-1.3,1c-0.5,0.2-1.2,0.3-1.9,0.3H845c0,0.7,0.1,1.3,0.3,1.7
     c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,1.1,0.3,2,0.3h3.6v1.6c-0.6,0.1-1.2,0.2-1.9,0.3C849.2,89.2,848.4,89.3,847.5,89.3z M845,82.6
     h3.2c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.4-0.6,0.4-1.1c0-0.5-0.1-0.9-0.2-1.2s-0.4-0.5-0.7-0.6c-0.3-0.1-0.8-0.2-1.3-0.2
     c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.6,0.5-0.8,1C845.1,81,845,81.7,845,82.6z"
                />
                <path
                  fill="#FFFFFF"
                  d="M857.5,94.6c-0.7,0-1.4-0.1-2-0.3c-0.6-0.2-1-0.5-1.4-1c-0.3-0.4-0.5-1-0.5-1.6v-0.4
     c0-0.6,0.2-1.2,0.5-1.6c0.4-0.4,0.8-0.8,1.4-1c0.6-0.2,1.2-0.4,1.8-0.4l0.8,0.9c-0.4,0-0.7,0.1-1,0.2c-0.3,0.1-0.5,0.3-0.7,0.5
     c-0.2,0.2-0.3,0.5-0.3,0.9v0.4c0,0.5,0.2,0.9,0.5,1.2c0.4,0.2,0.9,0.4,1.5,0.4h2.4c0.7,0,1.2-0.1,1.6-0.4
     c0.4-0.3,0.6-0.6,0.6-1.2v-0.4c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.3-0.4-0.6-0.5c-0.3-0.1-0.7-0.2-1.2-0.2h-2.9
     c-1.2,0-2.1-0.2-2.7-0.7c-0.6-0.5-0.9-1-0.9-1.7c0-0.6,0.2-1.1,0.5-1.5c0.3-0.4,0.8-0.6,1.4-0.8c-0.5-0.2-0.9-0.5-1.2-0.9
     c-0.3-0.4-0.5-0.8-0.6-1.2c-0.1-0.4-0.2-0.9-0.2-1.3c0-0.9,0.2-1.6,0.5-2.2c0.4-0.6,0.9-1,1.6-1.3c0.7-0.3,1.6-0.4,2.6-0.4h6.4
     v1.5l-2.1,0.3c0.1,0.1,0.3,0.3,0.5,0.5c0.2,0.2,0.3,0.5,0.4,0.8s0.2,0.8,0.2,1.3c0,0.7-0.1,1.3-0.4,1.8c-0.3,0.5-0.7,1-1.3,1.3
     c-0.6,0.3-1.4,0.5-2.5,0.5h-2c-0.5,0-0.9,0.1-1.1,0.2c-0.3,0.1-0.4,0.4-0.4,0.7c0,0.3,0.1,0.5,0.4,0.6c0.2,0.1,0.5,0.2,0.7,0.2
     h3.5c0.9,0,1.7,0.1,2.3,0.4c0.6,0.2,1.1,0.6,1.4,1.1c0.3,0.5,0.5,1.2,0.5,2V91c0,1.1-0.4,2-1.1,2.7c-0.7,0.6-1.8,1-3.3,1H857.5z
      M858.7,83.4h1.3c0.5,0,1-0.1,1.3-0.3c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1c0-0.5-0.1-0.9-0.2-1.2
     c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.2-1-0.2h-1.2c-0.7,0-1.2,0.1-1.5,0.4c-0.3,0.3-0.5,0.8-0.5,1.5s0.2,1.2,0.5,1.5
     S858.1,83.4,858.7,83.4z"
                />
                <path
                  fill="#FFFFFF"
                  d="M867.3,89.2V77.4h2.1l0.6,1.9c0.5-0.6,1-1.1,1.6-1.5s1.3-0.6,2-0.6c0.2,0,0.3,0,0.5,0
     c0.2,0,0.3,0.1,0.5,0.1v2.8c-0.2,0-0.4-0.1-0.7-0.1s-0.5,0-0.7,0c-0.5,0-0.9,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-1,0.5
     c-0.3,0.2-0.6,0.5-1,0.8v7.7H867.3z"
                />
                <path
                  fill="#FFFFFF"
                  d="M878.5,89.3c-0.9,0-1.6-0.3-2.2-0.8c-0.6-0.5-0.8-1.3-0.8-2.2v-1c0-0.9,0.3-1.6,0.9-2.2
     c0.6-0.6,1.6-0.9,2.9-0.9h3.4v-1c0-0.4-0.1-0.8-0.2-1c-0.1-0.3-0.4-0.5-0.8-0.6c-0.4-0.1-1-0.2-1.7-0.2h-3.6v-1.6
     c0.5-0.1,1.2-0.3,1.9-0.4c0.7-0.1,1.6-0.2,2.6-0.2c1,0,1.8,0.1,2.5,0.3c0.7,0.2,1.2,0.6,1.5,1.2c0.3,0.6,0.5,1.3,0.5,2.3v8.1
     h-2.1l-0.5-1.3c-0.1,0.1-0.3,0.2-0.5,0.4s-0.6,0.3-1,0.5c-0.4,0.2-0.8,0.3-1.3,0.4C879.5,89.3,879,89.3,878.5,89.3z M879.8,87.4
     c0.2,0,0.4,0,0.6-0.1s0.5-0.1,0.7-0.2c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.1,0.5-0.2s0.2-0.1,0.3-0.1v-3.1l-2.6,0.1
     c-0.7,0.1-1.2,0.2-1.5,0.5c-0.3,0.3-0.5,0.7-0.5,1.2v0.5c0,0.4,0.1,0.7,0.2,0.9c0.2,0.2,0.4,0.4,0.6,0.5S879.6,87.4,879.8,87.4z"
                />
                <path
                  fill="#FFFFFF"
                  d="M888.1,89.2V77.4h2.2l0.5,1.2c0.5-0.4,1.1-0.7,1.8-1c0.7-0.3,1.4-0.4,2.3-0.4c0.7,0,1.4,0.2,1.9,0.5
     c0.5,0.3,0.9,0.7,1.1,1.2c0.3-0.3,0.7-0.5,1.2-0.8c0.5-0.3,1-0.5,1.5-0.6c0.5-0.2,1.1-0.3,1.6-0.3c0.9,0,1.6,0.2,2.2,0.5
     c0.6,0.4,0.9,0.9,1.2,1.5c0.2,0.6,0.4,1.4,0.4,2.2v7.7h-2.7v-7.5c0-0.4-0.1-0.8-0.2-1.1c-0.2-0.3-0.4-0.5-0.7-0.7
     c-0.3-0.2-0.6-0.2-1-0.2c-0.5,0-1.1,0.1-1.6,0.3s-1,0.5-1.5,0.7c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4,0,0.5v7.5h-2.7v-7.5
     c0-0.4-0.1-0.8-0.2-1.1c-0.2-0.3-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.2-1-0.2c-0.4,0-0.7,0-1.1,0.1c-0.3,0.1-0.6,0.2-0.9,0.3
     c-0.3,0.1-0.6,0.3-0.9,0.5v8.6H888.1z"
                />
                <path
                  fill="#FFFFFF"
                  d="M917.5,94.6c-0.7,0-1.4-0.1-2-0.3c-0.6-0.2-1-0.5-1.4-1c-0.3-0.4-0.5-1-0.5-1.6v-0.4
     c0-0.6,0.2-1.2,0.5-1.6c0.4-0.4,0.8-0.8,1.4-1c0.6-0.2,1.2-0.4,1.8-0.4l0.8,0.9c-0.4,0-0.7,0.1-1,0.2c-0.3,0.1-0.5,0.3-0.7,0.5
     c-0.2,0.2-0.3,0.5-0.3,0.9v0.4c0,0.5,0.2,0.9,0.5,1.2c0.4,0.2,0.9,0.4,1.5,0.4h2.4c0.7,0,1.2-0.1,1.6-0.4
     c0.4-0.3,0.6-0.6,0.6-1.2v-0.4c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.3-0.4-0.6-0.5c-0.3-0.1-0.7-0.2-1.2-0.2h-2.9
     c-1.2,0-2.1-0.2-2.7-0.7c-0.6-0.5-0.9-1-0.9-1.7c0-0.6,0.2-1.1,0.5-1.5c0.3-0.4,0.8-0.6,1.4-0.8c-0.5-0.2-0.9-0.5-1.2-0.9
     c-0.3-0.4-0.5-0.8-0.6-1.2c-0.1-0.4-0.2-0.9-0.2-1.3c0-0.9,0.2-1.6,0.5-2.2c0.4-0.6,0.9-1,1.6-1.3c0.7-0.3,1.6-0.4,2.6-0.4h6.4
     v1.5l-2.1,0.3c0.1,0.1,0.3,0.3,0.5,0.5c0.2,0.2,0.3,0.5,0.4,0.8s0.2,0.8,0.2,1.3c0,0.7-0.1,1.3-0.4,1.8c-0.3,0.5-0.7,1-1.3,1.3
     c-0.6,0.3-1.4,0.5-2.5,0.5h-2c-0.5,0-0.9,0.1-1.1,0.2c-0.3,0.1-0.4,0.4-0.4,0.7c0,0.3,0.1,0.5,0.4,0.6c0.2,0.1,0.5,0.2,0.7,0.2
     h3.5c0.9,0,1.7,0.1,2.3,0.4c0.6,0.2,1.1,0.6,1.4,1.1c0.3,0.5,0.5,1.2,0.5,2V91c0,1.1-0.4,2-1.1,2.7c-0.7,0.6-1.8,1-3.3,1H917.5z
      M918.6,83.4h1.3c0.5,0,1-0.1,1.3-0.3c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1c0-0.5-0.1-0.9-0.2-1.2
     c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.2-1-0.2h-1.2c-0.7,0-1.2,0.1-1.5,0.4c-0.3,0.3-0.5,0.8-0.5,1.5s0.2,1.2,0.5,1.5
     S918,83.4,918.6,83.4z"
                />
                <path
                  fill="#FFFFFF"
                  d="M927.3,89.2V77.4h2.1l0.6,1.9c0.5-0.6,1-1.1,1.6-1.5s1.3-0.6,2-0.6c0.2,0,0.3,0,0.5,0
     c0.2,0,0.3,0.1,0.5,0.1v2.8c-0.2,0-0.4-0.1-0.7-0.1s-0.5,0-0.7,0c-0.5,0-0.9,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-1,0.5
     c-0.3,0.2-0.6,0.5-1,0.8v7.7H927.3z"
                />
                <path
                  fill="#FFFFFF"
                  d="M941.4,89.3c-0.9,0-1.7-0.1-2.4-0.3s-1.2-0.5-1.7-1c-0.4-0.5-0.7-1.1-0.9-1.8c-0.2-0.8-0.3-1.8-0.3-2.9
     c0-1.2,0.1-2.2,0.3-3c0.2-0.8,0.5-1.4,1-1.8c0.4-0.4,1-0.8,1.7-0.9c0.7-0.2,1.5-0.3,2.4-0.3c0.9,0,1.7,0.1,2.4,0.3
     c0.7,0.2,1.2,0.5,1.6,0.9s0.7,1.1,0.9,1.8c0.2,0.8,0.3,1.8,0.3,3c0,1.2-0.1,2.2-0.3,3s-0.5,1.4-0.9,1.8c-0.4,0.4-1,0.8-1.7,0.9
     S942.4,89.3,941.4,89.3z M941.4,87.1c0.5,0,0.8,0,1.2-0.1c0.3-0.1,0.6-0.2,0.8-0.5c0.2-0.3,0.4-0.6,0.5-1.2s0.2-1.2,0.2-2.1
     c0-0.9-0.1-1.5-0.2-2s-0.3-0.9-0.5-1.2c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3-0.1-0.7-0.1-1.2-0.1c-0.4,0-0.8,0-1.1,0.1
     s-0.6,0.2-0.8,0.5c-0.2,0.3-0.4,0.6-0.5,1.2c-0.1,0.5-0.2,1.2-0.2,2c0,0.9,0.1,1.6,0.2,2.1c0.1,0.5,0.3,0.9,0.5,1.2
     s0.5,0.4,0.8,0.5S941,87.1,941.4,87.1z"
                />
                <path
                  fill="#FFFFFF"
                  d="M952.6,89.3c-1.1,0-1.9-0.3-2.5-0.9c-0.6-0.6-0.9-1.5-0.9-2.7v-8.3h2.7v7.5c0,0.8,0.2,1.3,0.5,1.6
     s0.8,0.4,1.5,0.4c0.5,0,1-0.1,1.5-0.2c0.5-0.2,0.9-0.4,1.4-0.7v-8.6h2.7v11.8h-2.2l-0.5-1.2c-0.6,0.4-1.2,0.7-1.9,0.9
     C954.1,89.2,953.4,89.3,952.6,89.3z"
                />
                <path
                  fill="#FFFFFF"
                  d="M962.2,94.4v-17h2.3l0.5,1.2c0.3-0.2,0.6-0.4,1-0.6c0.4-0.2,0.8-0.4,1.2-0.5c0.4-0.1,0.9-0.2,1.3-0.2
     c0.7,0,1.4,0.1,1.9,0.4s1,0.7,1.3,1.2c0.3,0.5,0.6,1.2,0.7,1.9c0.2,0.7,0.2,1.6,0.2,2.5c0,1.3-0.1,2.4-0.4,3.3s-0.7,1.6-1.3,2.1
     c-0.6,0.5-1.4,0.7-2.5,0.7c-0.6,0-1.2-0.1-1.8-0.2s-1.2-0.4-1.7-0.6v5.9H962.2z M967.5,87.1c0.4,0,0.8-0.1,1.2-0.3
     c0.4-0.2,0.6-0.6,0.8-1.2c0.2-0.5,0.3-1.3,0.3-2.3c0-1-0.1-1.7-0.3-2.3c-0.2-0.6-0.5-1-0.8-1.2c-0.3-0.2-0.7-0.4-1.2-0.4
     c-0.5,0-1,0.1-1.5,0.3c-0.4,0.2-0.8,0.3-1.2,0.5v6.2c0.4,0.2,0.8,0.4,1.3,0.5C966.6,87,967.1,87.1,967.5,87.1z"
                />
              </g>
            </g>
            <g>
              <path fill="#FFD54F" d="M177,63.6V47.4h2.7v16.2H177z" />
              <path
                fill="#FFD54F"
                d="M186.5,63.8c-0.4,0-0.7,0-1.2-0.1c-0.4-0.1-0.8-0.2-1.2-0.4c-0.4-0.2-0.7-0.6-1-1
   c-0.2-0.5-0.4-1.1-0.4-1.9V51c0-0.7,0.1-1.3,0.3-1.7c0.2-0.5,0.5-0.8,0.8-1.1c0.3-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.8-0.2,1.2-0.2
   c1,0,2,0,2.8,0c0.8,0,1.6,0.1,2.3,0.1c0.7,0,1.3,0.1,1.9,0.1v2.3h-6.1c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.2-0.4,0.6-0.4,1.2v2.9
   l6.8,0.2v2.1l-6.8,0.2v2.8c0,0.4,0.1,0.8,0.2,1c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.1,0.5,0.1,0.8,0.1h6.2v2.3
   c-0.6,0.1-1.3,0.1-2.1,0.1c-0.8,0-1.5,0.1-2.3,0.1C188,63.7,187.2,63.7,186.5,63.8z"
              />
              <path
                fill="#FFD54F"
                d="M201.9,63.8c-1.1,0-2-0.1-2.8-0.3s-1.5-0.6-2-1.2c-0.5-0.6-1-1.4-1.2-2.5c-0.3-1.1-0.4-2.5-0.4-4.2
   c0-1.8,0.1-3.2,0.4-4.3c0.3-1.1,0.7-1.9,1.2-2.5c0.5-0.6,1.2-1,2-1.2c0.8-0.2,1.8-0.3,2.8-0.3c1.1,0,2.1,0.1,2.9,0.3
   c0.8,0.2,1.5,0.6,2,1.2c0.5,0.6,0.9,1.4,1.2,2.5c0.3,1.1,0.4,2.5,0.4,4.3c0,1.7-0.1,3.2-0.4,4.2c-0.3,1.1-0.7,1.9-1.2,2.5
   c-0.5,0.6-1.2,1-2,1.2S203,63.8,201.9,63.8z M201.9,61.3c0.7,0,1.3-0.1,1.8-0.2c0.5-0.2,0.9-0.4,1.2-0.8s0.5-1,0.6-1.7
   c0.1-0.8,0.2-1.7,0.2-3c0-1.3-0.1-2.3-0.2-3.1c-0.1-0.8-0.4-1.4-0.6-1.8s-0.7-0.7-1.2-0.8c-0.5-0.1-1.1-0.2-1.8-0.2
   c-0.7,0-1.3,0.1-1.7,0.2c-0.5,0.1-0.9,0.4-1.2,0.8c-0.3,0.4-0.5,1-0.7,1.8s-0.2,1.8-0.2,3.1c0,1.2,0.1,2.2,0.2,3
   c0.1,0.8,0.3,1.3,0.6,1.7c0.3,0.4,0.7,0.7,1.2,0.8S201.2,61.3,201.9,61.3z"
              />
              <path
                fill="#FFD54F"
                d="M221,63.8c-0.3,0-0.7,0-1.2,0c-0.4,0-0.9-0.1-1.3-0.1c-0.4,0-0.8-0.1-1.2-0.1c-0.4-0.1-0.7-0.1-0.9-0.2
   v-1.6h5.1c0.4,0,0.7,0,0.9-0.1c0.3-0.1,0.5-0.2,0.6-0.4c0.1-0.2,0.2-0.4,0.2-0.8v-0.4c0-0.4-0.1-0.7-0.4-0.9
   c-0.2-0.2-0.7-0.3-1.3-0.3h-1.8c-0.7,0-1.3-0.1-1.8-0.3c-0.5-0.2-1-0.5-1.3-1c-0.3-0.5-0.5-1.1-0.5-1.8v-0.7
   c0-0.7,0.1-1.4,0.4-1.9c0.3-0.5,0.8-0.9,1.4-1.1c0.7-0.3,1.6-0.4,2.7-0.4c0.5,0,1,0,1.5,0.1c0.5,0,1.1,0.1,1.6,0.2
   c0.5,0.1,0.9,0.1,1.2,0.2v1.6h-4.8c-0.5,0-1,0.1-1.2,0.3c-0.3,0.2-0.4,0.5-0.4,1v0.4c0,0.3,0.1,0.6,0.2,0.7
   c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0.1,0.6,0.1,0.9,0.1h1.8c1.2,0,2,0.3,2.6,0.8c0.6,0.5,0.8,1.3,0.8,2.2v1c0,0.8-0.2,1.4-0.5,1.9
   c-0.4,0.5-0.9,0.8-1.6,1S221.9,63.8,221,63.8z"
              />
              <path
                fill="#FFD54F"
                d="M230.2,63.8c-0.9,0-1.6-0.3-2.2-0.8c-0.6-0.5-0.8-1.3-0.8-2.2v-1c0-0.9,0.3-1.6,0.9-2.2
   c0.6-0.6,1.6-0.9,2.9-0.9h3.4v-1c0-0.4-0.1-0.8-0.2-1c-0.1-0.3-0.4-0.5-0.8-0.6c-0.4-0.1-1-0.2-1.7-0.2h-3.6v-1.6
   c0.5-0.1,1.2-0.3,1.9-0.4c0.7-0.1,1.6-0.2,2.6-0.2c1,0,1.8,0.1,2.5,0.3c0.7,0.2,1.2,0.6,1.5,1.2c0.3,0.6,0.5,1.3,0.5,2.3v8.1H235
   l-0.5-1.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.6,0.3-1,0.5c-0.4,0.2-0.8,0.3-1.3,0.4C231.2,63.7,230.7,63.8,230.2,63.8z
    M231.6,61.9c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.1,0.5-0.2
   c0.1-0.1,0.2-0.1,0.3-0.1V58l-2.6,0.1c-0.7,0.1-1.2,0.2-1.5,0.5c-0.3,0.3-0.5,0.7-0.5,1.2v0.5c0,0.4,0.1,0.7,0.2,0.9
   c0.2,0.2,0.4,0.4,0.6,0.5C231,61.8,231.3,61.9,231.6,61.9z"
              />
              <path
                fill="#FFD54F"
                d="M243.4,63.7c-0.8,0-1.4-0.1-1.9-0.4c-0.5-0.3-0.9-0.7-1.2-1.2c-0.3-0.6-0.4-1.3-0.4-2.2v-13h2.7v12.6
   c0,0.5,0.1,0.9,0.2,1.2c0.1,0.3,0.3,0.5,0.5,0.6c0.2,0.1,0.5,0.2,0.7,0.3l1,0.1v2H243.4z"
              />
              <path
                fill="#FFD54F"
                d="M251.4,63.8c-1.3,0-2.3-0.2-3-0.5c-0.8-0.4-1.3-1-1.6-1.8c-0.3-0.9-0.5-2.1-0.5-3.6c0-1.6,0.2-2.8,0.5-3.7
   c0.3-0.9,0.9-1.5,1.6-1.8s1.8-0.5,3-0.5c1.1,0,2,0.1,2.7,0.3c0.7,0.2,1.2,0.6,1.5,1.2c0.3,0.6,0.5,1.4,0.5,2.5
   c0,0.7-0.1,1.3-0.4,1.8s-0.7,0.8-1.3,1c-0.6,0.2-1.2,0.3-1.9,0.3h-3.5c0,0.7,0.1,1.3,0.3,1.7c0.2,0.4,0.5,0.7,0.9,0.9
   c0.4,0.2,1.1,0.3,2,0.3h3.6v1.6c-0.6,0.1-1.2,0.2-1.9,0.3S252.3,63.8,251.4,63.8z M248.9,57.1h3.2c0.5,0,0.9-0.1,1.1-0.3
   c0.2-0.2,0.4-0.6,0.4-1.1c0-0.5-0.1-0.9-0.2-1.2c-0.1-0.3-0.4-0.5-0.7-0.6s-0.8-0.2-1.3-0.2c-0.6,0-1.1,0.1-1.5,0.3
   c-0.4,0.2-0.6,0.5-0.8,1C249,55.5,248.9,56.2,248.9,57.1z"
              />
              <path
                fill="#FFD54F"
                d="M262.7,63.8c-0.3,0-0.7,0-1.2,0c-0.4,0-0.9-0.1-1.3-0.1c-0.4,0-0.8-0.1-1.2-0.1c-0.4-0.1-0.7-0.1-0.9-0.2
   v-1.6h5.1c0.4,0,0.7,0,0.9-0.1c0.3-0.1,0.5-0.2,0.6-0.4c0.1-0.2,0.2-0.4,0.2-0.8v-0.4c0-0.4-0.1-0.7-0.4-0.9
   c-0.2-0.2-0.7-0.3-1.3-0.3h-1.8c-0.7,0-1.3-0.1-1.8-0.3c-0.5-0.2-1-0.5-1.3-1c-0.3-0.5-0.5-1.1-0.5-1.8v-0.7
   c0-0.7,0.1-1.4,0.4-1.9c0.3-0.5,0.8-0.9,1.4-1.1c0.7-0.3,1.6-0.4,2.7-0.4c0.5,0,1,0,1.5,0.1c0.5,0,1.1,0.1,1.6,0.2
   c0.5,0.1,0.9,0.1,1.2,0.2v1.6h-4.8c-0.5,0-1,0.1-1.2,0.3c-0.3,0.2-0.4,0.5-0.4,1v0.4c0,0.3,0.1,0.6,0.2,0.7
   c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0.1,0.6,0.1,0.9,0.1h1.8c1.2,0,2,0.3,2.6,0.8c0.6,0.5,0.8,1.3,0.8,2.2v1c0,0.8-0.2,1.4-0.5,1.9
   c-0.4,0.5-0.9,0.8-1.6,1S263.6,63.8,262.7,63.8z"
              />
              <path
                fill="#FFD54F"
                d="M279.5,63.8c-0.3,0-0.7,0-1.2,0c-0.4,0-0.9-0.1-1.3-0.1c-0.4,0-0.8-0.1-1.2-0.1c-0.4-0.1-0.7-0.1-0.9-0.2
   v-1.6h5.1c0.4,0,0.7,0,0.9-0.1c0.3-0.1,0.5-0.2,0.6-0.4c0.1-0.2,0.2-0.4,0.2-0.8v-0.4c0-0.4-0.1-0.7-0.4-0.9
   c-0.2-0.2-0.7-0.3-1.3-0.3h-1.8c-0.7,0-1.3-0.1-1.8-0.3c-0.5-0.2-1-0.5-1.3-1c-0.3-0.5-0.5-1.1-0.5-1.8v-0.7
   c0-0.7,0.1-1.4,0.4-1.9c0.3-0.5,0.8-0.9,1.4-1.1c0.7-0.3,1.6-0.4,2.7-0.4c0.5,0,1,0,1.5,0.1c0.5,0,1.1,0.1,1.6,0.2
   c0.5,0.1,0.9,0.1,1.2,0.2v1.6h-4.8c-0.5,0-1,0.1-1.2,0.3c-0.3,0.2-0.4,0.5-0.4,1v0.4c0,0.3,0.1,0.6,0.2,0.7
   c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0.1,0.6,0.1,0.9,0.1h1.8c1.2,0,2,0.3,2.6,0.8c0.6,0.5,0.8,1.3,0.8,2.2v1c0,0.8-0.2,1.4-0.5,1.9
   c-0.4,0.5-0.9,0.8-1.6,1S280.5,63.8,279.5,63.8z"
              />
              <path
                fill="#FFD54F"
                d="M290.6,63.6c-0.9,0-1.5-0.1-2.1-0.4c-0.5-0.3-0.9-0.7-1.2-1.3c-0.2-0.6-0.4-1.4-0.3-2.4l0.1-5.5h-1.8v-1.8
   l1.9-0.4l0.4-3.3h2.1v3.3h3v2.2h-3v5.5c0,0.4,0,0.7,0.1,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.3,0.2,0.5,0.3
   c0.2,0.1,0.3,0.1,0.5,0.1l1.5,0.2v2H290.6z"
              />
              <path
                fill="#FFD54F"
                d="M297.2,63.8c-0.9,0-1.6-0.3-2.2-0.8c-0.6-0.5-0.8-1.3-0.8-2.2v-1c0-0.9,0.3-1.6,0.9-2.2
   c0.6-0.6,1.6-0.9,2.9-0.9h3.4v-1c0-0.4-0.1-0.8-0.2-1c-0.1-0.3-0.4-0.5-0.8-0.6c-0.4-0.1-1-0.2-1.7-0.2H295v-1.6
   c0.5-0.1,1.2-0.3,1.9-0.4c0.7-0.1,1.6-0.2,2.6-0.2c1,0,1.8,0.1,2.5,0.3c0.7,0.2,1.2,0.6,1.5,1.2c0.3,0.6,0.5,1.3,0.5,2.3v8.1h-2.1
   l-0.5-1.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.6,0.3-1,0.5c-0.4,0.2-0.8,0.3-1.3,0.4C298.2,63.7,297.7,63.8,297.2,63.8z
    M298.5,61.9c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.1,0.5-0.2
   c0.1-0.1,0.2-0.1,0.3-0.1V58l-2.6,0.1c-0.7,0.1-1.2,0.2-1.5,0.5c-0.3,0.3-0.5,0.7-0.5,1.2v0.5c0,0.4,0.1,0.7,0.2,0.9
   c0.2,0.2,0.4,0.4,0.6,0.5C297.9,61.8,298.2,61.9,298.5,61.9z"
              />
              <path
                fill="#FFD54F"
                d="M306.8,63.6V51.9h2.1l0.6,1.9c0.5-0.6,1-1.1,1.6-1.5c0.6-0.4,1.3-0.6,2-0.6c0.2,0,0.3,0,0.5,0
   c0.2,0,0.3,0.1,0.5,0.1v2.8c-0.2,0-0.4-0.1-0.7-0.1c-0.2,0-0.5,0-0.7,0c-0.5,0-0.9,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-1,0.5
   c-0.3,0.2-0.6,0.5-1,0.8v7.7H306.8z"
              />
              <path
                fill="#FFD54F"
                d="M320.5,63.6c-0.9,0-1.5-0.1-2.1-0.4c-0.5-0.3-0.9-0.7-1.2-1.3c-0.2-0.6-0.4-1.4-0.3-2.4l0.1-5.5h-1.8v-1.8
   l1.9-0.4l0.4-3.3h2.1v3.3h3v2.2h-3v5.5c0,0.4,0,0.7,0.1,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.3,0.2,0.5,0.3
   c0.2,0.1,0.3,0.1,0.5,0.1l1.5,0.2v2H320.5z"
              />
              <path
                fill="#FFD54F"
                d="M329.4,63.8c-1.3,0-2.3-0.2-3-0.5c-0.8-0.4-1.3-1-1.6-1.8c-0.3-0.9-0.5-2.1-0.5-3.6c0-1.6,0.2-2.8,0.5-3.7
   c0.3-0.9,0.9-1.5,1.6-1.8s1.8-0.5,3-0.5c1.1,0,2,0.1,2.7,0.3c0.7,0.2,1.2,0.6,1.5,1.2c0.3,0.6,0.5,1.4,0.5,2.5
   c0,0.7-0.1,1.3-0.4,1.8s-0.7,0.8-1.3,1c-0.6,0.2-1.2,0.3-1.9,0.3h-3.5c0,0.7,0.1,1.3,0.3,1.7c0.2,0.4,0.5,0.7,0.9,0.9
   c0.4,0.2,1.1,0.3,2,0.3h3.6v1.6c-0.6,0.1-1.2,0.2-1.9,0.3S330.3,63.8,329.4,63.8z M326.9,57.1h3.2c0.5,0,0.9-0.1,1.1-0.3
   c0.2-0.2,0.4-0.6,0.4-1.1c0-0.5-0.1-0.9-0.2-1.2c-0.1-0.3-0.4-0.5-0.7-0.6s-0.8-0.2-1.3-0.2c-0.6,0-1.1,0.1-1.5,0.3
   c-0.4,0.2-0.6,0.5-0.8,1C327,55.5,326.9,56.2,326.9,57.1z"
              />
              <path
                fill="#FFD54F"
                d="M340.3,63.8c-0.9,0-1.7-0.2-2.3-0.6c-0.6-0.4-1.1-1-1.4-1.9c-0.3-0.9-0.5-2-0.5-3.4c0-1.5,0.1-2.7,0.4-3.6
   c0.3-0.9,0.7-1.6,1.4-2c0.7-0.4,1.5-0.6,2.6-0.6c0.6,0,1.2,0.1,1.7,0.2c0.6,0.1,1.1,0.3,1.5,0.4v-5.4h2.7v16.8h-2.3l-0.5-1.1
   c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.5C341.2,63.7,340.7,63.8,340.3,63.8z M341.2,61.6c0.5,0,1-0.1,1.4-0.3s0.8-0.3,1.1-0.5
   v-6.6c-0.4-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.8-0.2-1.3-0.2c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.7,0.6-0.8,1.2
   c-0.2,0.6-0.3,1.4-0.3,2.4c0,1,0.1,1.8,0.2,2.3c0.2,0.6,0.4,1,0.8,1.2C340.2,61.5,340.7,61.6,341.2,61.6z"
              />
              <path
                fill="#FFD54F"
                d="M359.9,63.8c-0.9,0-1.7-0.1-2.4-0.3c-0.7-0.2-1.2-0.5-1.6-1c-0.4-0.5-0.7-1.1-0.9-1.8
   c-0.2-0.8-0.3-1.8-0.3-2.9c0-1.2,0.1-2.2,0.3-3s0.5-1.4,1-1.8c0.4-0.4,1-0.8,1.7-0.9c0.7-0.2,1.5-0.3,2.4-0.3
   c0.9,0,1.7,0.1,2.4,0.3c0.7,0.2,1.2,0.5,1.6,0.9s0.7,1.1,0.9,1.8c0.2,0.8,0.3,1.8,0.3,3c0,1.2-0.1,2.2-0.3,3
   c-0.2,0.8-0.5,1.4-0.9,1.8s-1,0.8-1.6,0.9S360.9,63.8,359.9,63.8z M359.9,61.6c0.5,0,0.8,0,1.2-0.1c0.3-0.1,0.6-0.2,0.8-0.5
   c0.2-0.3,0.4-0.6,0.5-1.2s0.2-1.2,0.2-2.1c0-0.9-0.1-1.5-0.2-2s-0.3-0.9-0.5-1.2c-0.2-0.3-0.5-0.4-0.8-0.5
   c-0.3-0.1-0.7-0.1-1.2-0.1c-0.4,0-0.8,0-1.1,0.1s-0.6,0.2-0.8,0.5c-0.2,0.3-0.4,0.6-0.5,1.2c-0.1,0.5-0.2,1.2-0.2,2
   c0,0.9,0.1,1.6,0.2,2.1c0.1,0.5,0.3,0.9,0.5,1.2c0.2,0.3,0.5,0.4,0.8,0.5S359.5,61.6,359.9,61.6z"
              />
              <path
                fill="#FFD54F"
                d="M367.7,63.6V51.9h2.2l0.5,1.2c0.5-0.4,1.1-0.7,1.8-1s1.4-0.4,2.2-0.4c0.9,0,1.7,0.2,2.2,0.6
   s0.9,0.9,1.1,1.5c0.2,0.6,0.3,1.3,0.3,2.1v7.9h-2.7v-7.5c0-0.4-0.1-0.8-0.2-1.1c-0.2-0.3-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.2-1-0.2
   c-0.4,0-0.7,0-1.1,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.3-0.9,0.5v8.6H367.7z"
              />
              <path
                fill="#FFD54F"
                d="M386.2,63.6l1.9-16.2h2.6l3.9,13l3.9-13h2.6l1.9,16.2h-2.6l-1.5-12.8l0.4,0l-3.5,12.7h-2.6l-3.6-12.7
   l0.5,0l-1.4,12.7H386.2z"
              />
              <path
                fill="#FFD54F"
                d="M407.7,63.8c-0.9,0-1.6-0.3-2.2-0.8c-0.6-0.5-0.8-1.3-0.8-2.2v-1c0-0.9,0.3-1.6,0.9-2.2
   c0.6-0.6,1.6-0.9,2.9-0.9h3.4v-1c0-0.4-0.1-0.8-0.2-1c-0.1-0.3-0.4-0.5-0.8-0.6c-0.4-0.1-1-0.2-1.7-0.2h-3.6v-1.6
   c0.5-0.1,1.2-0.3,1.9-0.4c0.7-0.1,1.6-0.2,2.6-0.2c1,0,1.8,0.1,2.5,0.3c0.7,0.2,1.2,0.6,1.5,1.2c0.3,0.6,0.5,1.3,0.5,2.3v8.1h-2.1
   l-0.5-1.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.6,0.3-1,0.5c-0.4,0.2-0.8,0.3-1.3,0.4C408.7,63.7,408.2,63.8,407.7,63.8z
    M409.1,61.9c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.1,0.5-0.2
   c0.1-0.1,0.2-0.1,0.3-0.1V58l-2.6,0.1c-0.7,0.1-1.2,0.2-1.5,0.5c-0.3,0.3-0.5,0.7-0.5,1.2v0.5c0,0.4,0.1,0.7,0.2,0.9
   c0.2,0.2,0.4,0.4,0.6,0.5C408.5,61.8,408.8,61.9,409.1,61.9z"
              />
              <path
                fill="#FFD54F"
                d="M417.3,63.6V51.9h2.1l0.6,1.9c0.5-0.6,1-1.1,1.6-1.5c0.6-0.4,1.3-0.6,2-0.6c0.2,0,0.3,0,0.5,0
   c0.2,0,0.3,0.1,0.5,0.1v2.8c-0.2,0-0.4-0.1-0.7-0.1c-0.2,0-0.5,0-0.7,0c-0.5,0-0.9,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-1,0.5
   c-0.3,0.2-0.6,0.5-1,0.8v7.7H417.3z"
              />
              <path
                fill="#FFD54F"
                d="M431.1,63.8c-0.7,0-1.4-0.1-1.9-0.3c-0.6-0.2-1.1-0.5-1.6-0.9s-0.8-1.1-1-1.8s-0.4-1.8-0.4-2.9
   c0-1.2,0.1-2.2,0.3-3c0.2-0.8,0.6-1.4,1-1.9c0.4-0.5,0.9-0.8,1.6-1c0.6-0.2,1.3-0.3,2.1-0.3c0.7,0,1.3,0,2,0.1
   c0.7,0.1,1.3,0.2,2,0.4v1.6H432c-0.7,0-1.3,0.1-1.7,0.3c-0.4,0.2-0.8,0.6-1,1.2c-0.2,0.6-0.3,1.4-0.3,2.5c0,1.1,0.1,1.9,0.3,2.5
   c0.2,0.6,0.6,0.9,1,1.1c0.4,0.2,1,0.3,1.7,0.3h3.4v1.5c-0.4,0.1-0.8,0.2-1.2,0.3c-0.5,0.1-1,0.1-1.5,0.2S431.6,63.8,431.1,63.8z"
              />
              <path
                fill="#FFD54F"
                d="M437.3,63.6V46.9h2.7v6.2c0.5-0.4,1.1-0.7,1.8-1c0.7-0.3,1.4-0.5,2.2-0.5c0.9,0,1.6,0.2,2.1,0.5
   c0.5,0.4,0.9,0.8,1.2,1.5c0.2,0.6,0.4,1.3,0.4,2.1v7.9h-2.7v-7.5c0-0.4-0.1-0.8-0.2-1.1c-0.2-0.3-0.4-0.5-0.7-0.7
   c-0.3-0.2-0.6-0.2-1-0.2c-0.4,0-0.7,0-1.1,0.1s-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.3-0.9,0.5v8.6H437.3z"
              />
              <path
                fill="#FFD54F"
                d="M455.8,63.6v-2.2l5.6-5.5c0.5-0.5,0.9-0.9,1.2-1.3s0.5-0.8,0.7-1.2c0.1-0.4,0.2-0.8,0.2-1.3
   c0-0.4-0.1-0.8-0.3-1.2s-0.5-0.6-0.9-0.8c-0.4-0.2-1-0.3-1.7-0.3h-4.5v-2.1c0.7-0.1,1.5-0.2,2.3-0.3c0.8-0.1,1.8-0.1,2.8-0.1
   c1.3,0,2.3,0.2,3,0.5c0.7,0.3,1.2,0.8,1.5,1.5c0.3,0.7,0.5,1.4,0.5,2.3c0,0.7-0.1,1.4-0.3,2c-0.2,0.6-0.5,1.2-0.9,1.8
   c-0.4,0.6-0.9,1.1-1.5,1.7l-4.1,4h7.4v2.5H455.8z"
              />
              <path
                fill="#FFD54F"
                d="M471.6,63.6V49.9l-3.1,0.5v-1.7l3.3-1.4h2.4v16.4H471.6z"
              />
              <path
                fill="#FFD54F"
                d="M478,65.5c-0.2,0-0.5,0-0.9-0.1v-0.9c0.2,0,0.3,0,0.4-0.1s0.1-0.2,0.1-0.3v-0.7h-0.2c-0.1,0-0.2,0-0.3-0.1
   c-0.1-0.1-0.1-0.2-0.1-0.3v-1.8c0-0.3,0.1-0.5,0.4-0.5h1.5c0.2,0,0.3,0,0.3,0.1s0.1,0.2,0.1,0.3v2.9c0,0.4-0.1,0.7-0.2,0.9
   c-0.2,0.2-0.3,0.3-0.5,0.4C478.5,65.5,478.3,65.5,478,65.5z"
              />
              <path
                fill="#FFD54F"
                d="M487.6,63.6v-2.2l5.6-5.5c0.5-0.5,0.9-0.9,1.2-1.3s0.5-0.8,0.7-1.2c0.1-0.4,0.2-0.8,0.2-1.3
   c0-0.4-0.1-0.8-0.3-1.2s-0.5-0.6-0.9-0.8c-0.4-0.2-1-0.3-1.7-0.3H488v-2.1c0.7-0.1,1.5-0.2,2.3-0.3c0.8-0.1,1.8-0.1,2.8-0.1
   c1.3,0,2.3,0.2,3,0.5c0.7,0.3,1.2,0.8,1.5,1.5c0.3,0.7,0.5,1.4,0.5,2.3c0,0.7-0.1,1.4-0.3,2c-0.2,0.6-0.5,1.2-0.9,1.8
   c-0.4,0.6-0.9,1.1-1.5,1.7l-4.1,4h7.4v2.5H487.6z"
              />
              <path
                fill="#FFD54F"
                d="M505,63.7c-0.9,0-1.6-0.1-2.2-0.5c-0.6-0.3-1.1-0.8-1.5-1.4c-0.3-0.6-0.5-1.4-0.5-2.3v-8.2
   c0-0.9,0.2-1.7,0.5-2.3s0.8-1.1,1.5-1.4c0.6-0.3,1.4-0.5,2.2-0.5h3.3c0.9,0,1.7,0.2,2.3,0.5c0.6,0.3,1,0.8,1.3,1.4
   c0.3,0.6,0.4,1.4,0.4,2.3v8.2c0,1.3-0.4,2.4-1.1,3.1c-0.7,0.7-1.7,1-3,1H505z M505.6,61.3h2c0.7,0,1.2-0.2,1.6-0.5
   c0.3-0.3,0.5-0.8,0.5-1.5v-7.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-2.2c-0.6,0-1.1,0.2-1.5,0.5
   c-0.4,0.3-0.5,0.8-0.5,1.5v7.5c0,0.7,0.2,1.2,0.6,1.5C504.5,61.1,505,61.3,505.6,61.3z"
              />
              <path
                fill="#FFD54F"
                d="M514.8,63.6v-2.2l5.6-5.5c0.5-0.5,0.9-0.9,1.2-1.3s0.5-0.8,0.7-1.2c0.1-0.4,0.2-0.8,0.2-1.3
   c0-0.4-0.1-0.8-0.3-1.2s-0.5-0.6-0.9-0.8c-0.4-0.2-1-0.3-1.7-0.3h-4.5v-2.1c0.7-0.1,1.5-0.2,2.3-0.3c0.8-0.1,1.8-0.1,2.8-0.1
   c1.3,0,2.3,0.2,3,0.5c0.7,0.3,1.2,0.8,1.5,1.5c0.3,0.7,0.5,1.4,0.5,2.3c0,0.7-0.1,1.4-0.3,2c-0.2,0.6-0.5,1.2-0.9,1.8
   c-0.4,0.6-0.9,1.1-1.5,1.7l-4.1,4h7.4v2.5H514.8z"
              />
              <path
                fill="#FFD54F"
                d="M527.8,63.6v-2.2l5.6-5.5c0.5-0.5,0.9-0.9,1.2-1.3s0.5-0.8,0.7-1.2c0.1-0.4,0.2-0.8,0.2-1.3
   c0-0.4-0.1-0.8-0.3-1.2s-0.5-0.6-0.9-0.8c-0.4-0.2-1-0.3-1.7-0.3h-4.5v-2.1c0.7-0.1,1.5-0.2,2.3-0.3c0.8-0.1,1.8-0.1,2.8-0.1
   c1.3,0,2.3,0.2,3,0.5c0.7,0.3,1.2,0.8,1.5,1.5c0.3,0.7,0.5,1.4,0.5,2.3c0,0.7-0.1,1.4-0.3,2c-0.2,0.6-0.5,1.2-0.9,1.8
   c-0.4,0.6-0.9,1.1-1.5,1.7l-4.1,4h7.4v2.5H527.8z"
              />
            </g>
          </g>
          <g id="Layer_7">
            <a target="_blank" xlink:href="https://t.me/telefydefi">
              <g>
                <linearGradient
                  id="SVGID_3_"
                  gradientUnits="userSpaceOnUse"
                  x1="870.3869"
                  y1="160.1291"
                  x2="870.3869"
                  y2="130.2266"
                >
                  <stop offset="0" style="stop-color: #d6bc04" />
                  <stop offset="1" style="stop-color: #a76f08" />
                </linearGradient>
                <path
                  fill="url(#SVGID_3_)"
                  d="M926.6,160.1H814.2c-2.4,0-4.4-2-4.4-4.4v-21c0-2.4,2-4.4,4.4-4.4h112.4c2.4,0,4.4,2,4.4,4.4v21
   C931,158.1,929,160.1,926.6,160.1z"
                />
                <path
                  fill="#F5F5F5"
                  d="M841,137.5c0,0.2,0,0.3,0,0.5c0,0.1,0,0.1-0.1,0.2c-1,4.5-1.9,9-2.9,13.5c-0.1,0.3-0.1,0.6-0.3,0.9
   c-0.2,0.5-0.6,0.6-1.1,0.5c-0.2-0.1-0.3-0.1-0.5-0.3c-1.4-1-2.8-2-4.2-3.1c-0.3-0.2-0.2-0.2-0.4,0c-0.7,0.7-1.4,1.3-2.1,2
   c-0.2,0.2-0.5,0.3-0.8,0.3c0-0.3,0-0.6,0.1-0.8c0.1-1.2,0.2-2.4,0.3-3.6c0-0.1,0.1-0.2,0.1-0.3c2.6-2.4,5.3-4.8,7.9-7.1
   c0.1-0.1,0.3-0.2,0.4-0.4c0,0,0.1-0.1,0-0.2c0,0-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.1-0.5,0.2c-1.2,0.7-2.3,1.5-3.5,2.2
   c-2.2,1.4-4.4,2.8-6.7,4.2c-0.1,0-0.2,0.1-0.2,0.1c-1.5-0.5-3-0.9-4.5-1.4c-0.3-0.1-0.5-0.2-0.6-0.5c0-0.1,0-0.2,0-0.2
   c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.2,0.5-0.3,0.8-0.4c4.2-1.6,8.3-3.2,12.5-4.8c1.6-0.6,3.2-1.2,4.8-1.8
   C840.3,136.6,840.8,136.8,841,137.5C841,137.5,841,137.5,841,137.5z"
                />
                <g>
                  <path
                    fill="#F5F5F5"
                    d="M848.1,150l1.4-9.1h-3.1l0.2-1.3h7.7l-0.2,1.3H851l-1.4,9.1H848.1z"
                  />
                  <path
                    fill="#F5F5F5"
                    d="M856.3,150.1c-0.3,0-0.5,0-0.8-0.1c-0.3-0.1-0.5-0.2-0.7-0.3c-0.2-0.2-0.4-0.4-0.5-0.7
     c-0.1-0.3-0.1-0.7,0-1.1l1-6.1c0.1-0.6,0.3-1,0.5-1.3c0.2-0.3,0.5-0.5,0.9-0.7c0.3-0.1,0.7-0.2,1.1-0.2c0.7,0,1.4,0,2.1,0
     c0.7,0,1.4,0.1,2.1,0.1l-0.2,1.2h-3.9c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.4,0.5-0.4,0.9l-0.3,2.1l4.3,0.1l-0.2,1.1l-4.4,0.1
     l-0.3,2.1c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1h3.8l-0.2,1.2c-0.7,0-1.3,0.1-2,0.1
     S857,150.1,856.3,150.1z"
                  />
                  <path
                    fill="#F5F5F5"
                    d="M864.4,150c-0.6,0-1-0.1-1.4-0.4s-0.5-0.7-0.5-1.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3l1.3-8.1h1.4
     l-1.3,8c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.3,0.1,0.5,0.3,0.6c0.2,0.1,0.4,0.2,0.7,0.2h3.6l-0.2,1.3H864.4z"
                  />
                  <path
                    fill="#F5F5F5"
                    d="M871.9,150.1c-0.3,0-0.5,0-0.8-0.1c-0.3-0.1-0.5-0.2-0.7-0.3c-0.2-0.2-0.4-0.4-0.5-0.7
     c-0.1-0.3-0.1-0.7,0-1.1l1-6.1c0.1-0.6,0.3-1,0.5-1.3c0.2-0.3,0.5-0.5,0.9-0.7c0.3-0.1,0.7-0.2,1.1-0.2c0.7,0,1.4,0,2.1,0
     c0.7,0,1.4,0.1,2.1,0.1l-0.2,1.2h-3.9c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.4,0.5-0.4,0.9l-0.3,2.1l4.3,0.1l-0.2,1.1l-4.4,0.1
     l-0.3,2.1c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1h3.8l-0.2,1.2c-0.7,0-1.3,0.1-2,0.1
     S872.6,150.1,871.9,150.1z"
                  />
                  <path
                    fill="#F5F5F5"
                    d="M880.9,150.1c-1,0-1.7-0.3-2.1-0.8c-0.4-0.5-0.6-1.3-0.6-2.4c0-0.4,0-0.8,0.1-1.2c0-0.4,0.1-0.8,0.2-1.2
     c0.2-1,0.4-1.9,0.8-2.7c0.4-0.7,0.8-1.3,1.4-1.7c0.6-0.4,1.3-0.6,2.2-0.6c0.8,0,1.5,0,2,0.1c0.5,0.1,1,0.2,1.4,0.3l-0.2,1.1
     c-0.4-0.1-0.8-0.1-1.3-0.2c-0.5-0.1-1.1-0.1-1.8-0.1c-0.7,0-1.2,0.1-1.6,0.4c-0.4,0.3-0.7,0.7-1,1.3s-0.4,1.3-0.6,2.2
     c-0.1,0.8-0.2,1.5-0.2,2c0,0.7,0.1,1.2,0.4,1.5c0.3,0.3,0.7,0.5,1.3,0.5c0.2,0,0.4,0,0.7,0c0.2,0,0.5-0.1,0.7-0.1
     c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.1,0.5-0.1l0.4-2.8H882l0.2-1.1c0.2,0,0.6-0.1,1-0.1c0.4,0,0.9,0,1.4,0c0.1,0,0.3,0,0.4,0
     c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4,0L885,150h-1l-0.1-0.7c-0.1,0.1-0.3,0.1-0.5,0.2c-0.2,0.1-0.5,0.2-0.8,0.3
     c-0.3,0.1-0.6,0.2-0.9,0.2C881.4,150,881.1,150.1,880.9,150.1z"
                  />
                  <path
                    fill="#F5F5F5"
                    d="M886.9,150l1.6-10.4h3.8c0.3,0,0.7,0,1,0.1c0.3,0.1,0.6,0.2,0.8,0.4c0.2,0.2,0.4,0.4,0.6,0.7
     c0.1,0.3,0.2,0.7,0.2,1.2c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.5-0.2,1-0.4,1.3c-0.2,0.3-0.4,0.6-0.6,0.8
     c-0.2,0.2-0.4,0.3-0.7,0.4c-0.2,0.1-0.4,0.2-0.6,0.2l1.4,4.3h-1.4l-1.2-4.1c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0
     c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4,0-0.6,0l-0.7,4.2H886.9z M889.1,144.7h2.4c0.3,0,0.6-0.1,0.9-0.2s0.5-0.3,0.7-0.6
     c0.2-0.3,0.3-0.7,0.4-1.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.4-0.1-0.6-0.2-0.8c-0.1-0.2-0.3-0.3-0.5-0.4
     c-0.2-0.1-0.4-0.1-0.7-0.1h-2.3L889.1,144.7z"
                  />
                  <path
                    fill="#F5F5F5"
                    d="M895.1,150l5.2-10.4h1.8l1.8,10.4h-1.5l-0.5-2.7h-4l-1.3,2.7H895.1z M898.5,146h3.3l-0.8-5.1L898.5,146z"
                  />
                  <path
                    fill="#F5F5F5"
                    d="M905,150l2.9-10.4h1.3l1.3,8.6l4.1-8.6h1.3l-0.4,10.4h-1.4l0.4-7.8l-3.6,7.8h-1.3l-1.1-7.7l-2.1,7.7H905z
     "
                  />
                </g>
              </g>
            </a>
          </g>
          <g id="Layer_3" display="none">
            <linearGradient
              id="SVGID_4_"
              gradientUnits="userSpaceOnUse"
              x1="436"
              y1="143"
              x2="573"
              y2="143"
            >
              <stop offset="0" style="stop-color: #a87030" />
              <stop offset="0.535" style="stop-color: #fab217" />
              <stop offset="0.5568" style="stop-color: #fab019" />
              <stop offset="0.8365" style="stop-color: #f59935" />
              <stop offset="1" style="stop-color: #f3903f" />
            </linearGradient>
            <path
              display="inline"
              fill="url(#SVGID_4_)"
              d="M561,162H448c-6.6,0-12-5.4-12-12v-14c0-6.6,5.4-12,12-12h113c6.6,0,12,5.4,12,12
 v14C573,156.6,567.6,162,561,162z"
            />
            <g display="inline">
              <g opacity="0.21">
                <rect
                  x="645.3"
                  y="-55"
                  fill="#F15A24"
                  width="419.1"
                  height="155.8"
                />
              </g>
              <g>
                <polygon
                  fill="#F15A24"
                  points="1178.9,134.5 1157.5,109.6 979.4,109.6 967.7,95.9 664.2,95.9 645.8,74.4 645.8,-32.7 
     663.8,-53.8 931.5,-53.8 939.2,-44.8 1047.9,-44.8 1048.1,-44.6 1063.2,-26.9 1063.2,83.1 1061.8,83.1 1061.8,-26.3 
     1047.3,-43.3 938.6,-43.3 931,-52.2 664.4,-52.2 647.1,-32.1 647.1,73.8 664.7,94.4 968.3,94.4 979.9,108 1158.1,108 
     1179.8,133.4 			"
                />
              </g>
              <g>
                <path
                  fill="#FBB03B"
                  d="M1042.1,101.8H984l-12.9-15.1H666.7L653.2,71V-32.3l11.5-13.1h263.5l6.6,7.7h109.8l11.5,13.4V85.4
     L1042.1,101.8z M984.1,101.3h57.7l13.8-16.1V-24.1l-11.2-13.1H934.7l-6.6-7.7H664.9l-11.3,12.8V70.8l13.2,15.4h304.4L984.1,101.3
     z"
                />
              </g>
              <g>
                <ellipse
                  fill="#F15A24"
                  cx="1181.4"
                  cy="137.8"
                  rx="5.6"
                  ry="6.5"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="660.8,-36.9 653.3,-28.1 653.3,-23.2 660.7,-31.9 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="660.8,-28.3 653.3,-19.5 653.3,-14.6 660.7,-23.3 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="660.8,-19.7 653.3,-10.9 653.3,-5.9 660.7,-14.7 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="660.8,-11.1 653.3,-2.3 653.3,2.7 660.7,-6.1 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="660.8,-2.5 653.3,6.3 653.3,11.3 660.7,2.5 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="660.8,6.2 653.3,15 653.3,19.9 660.7,11.2 			"
                />
              </g>
              <g opacity="0.28">
                <polygon
                  fill="#FBB03B"
                  points="855.3,86.5 847.2,77 766.9,77 753.9,61.9 653.6,61.9 653.6,61.4 754.1,61.4 767,76.5 847.4,76.5 
     855.7,86.2 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="1037.6,-37.4 1054,-18.2 1054,19.2 1055.8,21.3 1055.8,-24.2 1044.4,-37.5 			"
                />
              </g>
            </g>
            <g display="inline">
              <g opacity="0.21">
                <rect
                  x="150.1"
                  y="-54.9"
                  fill="#F15A24"
                  width="456.6"
                  height="169.7"
                />
              </g>
              <g>
                <polygon
                  fill="#F15A24"
                  points="238.2,134.2 22.1,134.2 22.1,132.5 237.6,132.5 257,109.8 603.1,109.8 603.1,64.2 594.3,54 
     594.3,-23.6 569.3,-52.8 371.4,-52.8 357.3,-36.2 172.1,-36.2 151.3,-11.9 151.3,59.1 157.6,66.5 157.6,109.6 162.6,115.4 
     161.5,116.6 156.1,110.3 156.1,67.2 149.8,59.8 149.8,-12.6 171.5,-37.9 356.7,-37.9 370.8,-54.5 569.9,-54.5 595.8,-24.3 
     595.8,53.3 604.6,63.5 604.6,111.5 257.6,111.5 			"
                />
              </g>
              <g>
                <polygon
                  fill="#F15A24"
                  points="279.4,109.9 284.9,103.5 465.6,103.5 472,111 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="575.7,-54.8 595.8,-31.2 595.8,-55 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="358.6,-50.7 351.4,-42.4 347.7,-42.4 354.8,-50.6 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="350,-50.7 342.8,-42.4 339.1,-42.4 346.2,-50.6 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="341.5,-50.7 334.3,-42.4 330.6,-42.4 337.7,-50.6 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="332.9,-50.7 325.8,-42.4 322,-42.4 329.1,-50.6 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="324.4,-50.7 317.2,-42.4 313.5,-42.4 320.6,-50.6 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="315.8,-50.7 308.7,-42.4 304.9,-42.4 312,-50.6 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="307.3,-50.7 300.1,-42.4 296.4,-42.4 303.5,-50.6 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="298.7,-50.7 291.6,-42.4 287.8,-42.4 294.9,-50.6 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="290.2,-50.7 283,-42.4 279.3,-42.4 286.4,-50.6 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="281.6,-50.7 274.5,-42.4 270.7,-42.4 277.8,-50.6 			"
                />
              </g>
              <g>
                <polygon
                  fill="#FBB03B"
                  points="273.1,-50.7 265.9,-42.4 262.2,-42.4 269.3,-50.6 			"
                />
              </g>
              <g>
                <rect
                  x="10.1"
                  y="125.5"
                  fill="#F15A24"
                  width="12.4"
                  height="14.5"
                />
              </g>
            </g>
          </g>
          <g id="Layer_5" display="none">
            <linearGradient
              id="SVGID_5_"
              gradientUnits="userSpaceOnUse"
              x1="266"
              y1="143"
              x2="403"
              y2="143"
            >
              <stop offset="0" style="stop-color: #a87030" />
              <stop offset="0.535" style="stop-color: #fab217" />
              <stop offset="0.5568" style="stop-color: #fab019" />
              <stop offset="0.8365" style="stop-color: #f59935" />
              <stop offset="1" style="stop-color: #f3903f" />
            </linearGradient>
            <path
              display="inline"
              fill="url(#SVGID_5_)"
              d="M391,162H278c-6.6,0-12-5.4-12-12v-14c0-6.6,5.4-12,12-12h113c6.6,0,12,5.4,12,12
 v14C403,156.6,397.6,162,391,162z"
            />
          </g>
          <g id="Layer_6" display="none">
            <linearGradient
              id="SVGID_6_"
              gradientUnits="userSpaceOnUse"
              x1="436"
              y1="143"
              x2="573"
              y2="143"
            >
              <stop offset="0" style="stop-color: #a87030" />
              <stop offset="0.535" style="stop-color: #fab217" />
              <stop offset="0.5568" style="stop-color: #fab019" />
              <stop offset="0.8365" style="stop-color: #f59935" />
              <stop offset="1" style="stop-color: #f3903f" />
            </linearGradient>
            <path
              display="inline"
              fill="url(#SVGID_6_)"
              d="M561,162H448c-6.6,0-12-5.4-12-12v-14c0-6.6,5.4-12,12-12h113c6.6,0,12,5.4,12,12
 v14C573,156.6,567.6,162,561,162z"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>

  <div class="d-flex py-4 heading-1 mt-2">
    <h2>Featured On</h2>
  </div>
  <div class="container mb-5 news-links">
    <div class="row">
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://www.digitaljournal.com/pr/telefy-credit-score-oriented-one-stop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
          ><img src="./assets/Images/news/digitaljournal-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://www.marketwatch.com/press-release/telefy-credit-score-oriented-one-stop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido-2022-02-18"
          ><img src="./assets/Images/news/marketwatch-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://www.google.com/search?q=%22Telefy+%2D+Credit+Score+oriented+One%2DStop+Solution+for+Defi+Is+All+Set+to+Launch+Their+Groundbreaking+IDO%22&tbm=nws"
          ><img src="./assets/Images/news/googlenews-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://www.rfdtv.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
        >
          <img src="./assets/Images/news/rfdtv-01.png" />
        </a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://www.wtnzfox43.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
        >
          <img src="./assets/Images/news/fox43-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://www.wpgxfox28.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
        >
          <img src="./assets/Images/news/fox28-01.png"
        /></a>
      </div>
    </div>

    <div class="row">
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://www.wicz.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
        >
          <img src="./assets/Images/news/fox40-01.png"
        /></a>
      </div>

      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://www.htv10.tv/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
          ><img src="./assets/Images/news/htv10-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="http://finance.dailyherald.com/dailyherald/news/read/42204923/"
          ><img src="./assets/Images/news/dailyherald-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://www.newschannelnebraska.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
        >
          <img src="./assets/Images/news/ncn-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://www.yournewsnet.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
          ><img src="./assets/Images/news/newsnet-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://www.snntv.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
          ><img src="./assets/Images/news/snn-01.png"
        /></a>
      </div>
    </div>

    <div class="row">
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://lifestyle.1045thedan.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
          ><img src="./assets/Images/news/thedan-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://lifestyle.roanokenewstalk.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
          ><img src="./assets/Images/news/newstalk-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://lifestyle.rewind1019.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
          ><img src="./assets/Images/news/rewind1019-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://lifestyle.xtra1063.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
          ><img src="./assets/Images/news/xtra106-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://lifestyle.mykmlk.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
          ><img src="./assets/Images/news/kmlk-01.png"
        /></a>
      </div>
      <div class="col scale-lg text-center mt-2">
        <a
          target="_blank"
          href="https://lifestyle.953hlf.com/story/45907201/telefy-credit-score-oriented-onestop-solution-for-defi-is-all-set-to-launch-their-groundbreaking-ido"
          ><img src="./assets/Images/news/95.3-01.png"
        /></a>
      </div>
    </div>
  </div>
  <hr />
  <div class="exchange-row">
    <div class="d-flex py-4 heading-1 mt-5">
      <h2 data-aos="fade-up">
        Explore Tele score oriented multi chain DEFI innovations crafted by our
        TeleFyers
      </h2>
    </div>
    <div class="flex-wrapper">
      <div class="flex-box">
        <div class="card exchange-card mb-4 text-white bg-purple">
          <img
            class="card-img-top"
            src="./assets/Images/teleswap.svg"
            alt="Card image cap"
          />
          <div class="card-body">
            <h5 class="card-title">Tele Swap</h5>
            <p class="card-text">
              Tele Swap is multi chain protocol allows users to swap coins,
              adding liquidity using AMM (Automated market maker).
            </p>
            <a class="btn btn-purple btn-sm" (click)="routToSwap()"
              >Enter Exchange</a
            >
          </div>
        </div>
      </div>
      <div class="flex-box">
        <div class="card exchange-card mb-4 text-white bg-purple">
          <img
            class="card-img-top"
            src="./assets/Images/telelend.svg"
            alt="Card image cap"
          />
          <div class="card-body">
            <h5 class="card-title">Tele Lend</h5>
            <p class="card-text">
              Tele Lend is multi chain automated lending protocol allows users
              to lend or borrow using Tele score.
            </p>
            <a
              class="btn btn-purple btn-sm"
              href="https://telefy.finance/#/comingsoon"
              >Enter Exchange</a
            >
          </div>
        </div>
      </div>
      <div class="flex-box">
        <div class="card exchange-card mb-4 text-white bg-purple">
          <img
            class="card-img-top"
            src="./assets/Images/telenft.svg"
            alt="Card image cap"
          />
          <div class="card-body">
            <h5 class="card-title">Tele NFT</h5>
            <p class="card-text">
              Tele NFT is multi chain NFT marketplace where you can buy or sell
              NFT. Tele NFT protocol fees will be distributed to the users who
              staked TELE
            </p>
            <a
              class="btn btn-purple btn-sm"
              href="https://telefy.finance/#/comingsoon"
              >Enter Market Place</a
            >
          </div>
        </div>
      </div>
      <div class="flex-box">
        <div class="card exchange-card mb-4 text-white bg-purple">
          <img
            class="card-img-top"
            src="./assets/Images/telescore.svg"
            alt="Card image cap"
          />
          <div class="card-body">
            <h5 class="card-title">Tele Score</h5>
            <p class="card-text">
              Tele Score is a multi-chain programmable protocol to calculate the
              credit worthiness based on the transactional data on the block
              chains
            </p>
            <a
              class="btn btn-purple btn-sm"
              href="https://telefy.finance/#/comingsoon"
              >Enter App</a
            >
          </div>
        </div>
      </div>
      <div class="flex-box">
        <div class="card exchange-card mb-4 text-white bg-purple">
          <img
            class="card-img-top"
            src="./assets/Images/telepad.svg"
            alt="Card image cap"
          />
          <div class="card-body">
            <h5 class="card-title">Tele PAD</h5>
            <p class="card-text">
              Tele PAD is multi chain initial DEX offering launch PAD, users who
              stake TELE coin can participate in TELE PAD project launch
              offering
            </p>
            <a
              class="btn btn-purple btn-sm"
              href="https://telepad.telefy.finance/"
              >Enter App</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="d-flex py-4 heading-1 mt-5">
    <h2>Telenomics</h2>
  </div>
  <div class="row telenomics">
    <div class="col-md-12">
      <img src="./assets/Images/telenomics.svg" />
    </div>
  </div>
  <hr />

  <div class="d-flex py-4 heading-1 mt-5">
    <h2>Tele Road Map</h2>
  </div>
  <div class="row roadmap">
    <div class="col-md-12">
      <img src="./assets/Images/roadmap1.svg" />
    </div>
  </div>

  <div class="d-flex py-4 heading-1 mt-5">
    <h2>TeleFy community for an evolving DEFI space</h2>
  </div>
  <div class="row commun-row">
    <div class="col-md-3">
      <div class="card mb-4 text-white bg-purple">
        <img
          class="card-img-top2"
          src="./assets/Images/social.png"
          alt="Card image cap"
        />
        <div class="card-body">
          <h5 class="community-title">Social Connect</h5>
          <p class="community-text">7K +</p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card mb-4 text-white bg-purple">
        <img
          class="card-img-top2"
          src="./assets/Images/wallet.png"
          alt="Card image cap"
        />
        <div class="card-body">
          <h5 class="community-title">Wallet Supported</h5>
          <p class="community-text">10 +</p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card mb-4 text-white bg-purple">
        <img
          class="card-img-top2"
          src="./assets/Images/blockchain.png"
          alt="Card image cap"
        />
        <div class="card-body">
          <h5 class="community-title">Chain Supported</h5>
          <p class="community-text">5 +</p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card mb-4 text-white bg-purple">
        <img
          class="card-img-top2"
          src="./assets/Images/tele.png"
          alt="Card image cap"
        />
        <div class="card-body">
          <h5 class="community-title">Tele Holders</h5>
          <p class="community-text">1K +</p>
        </div>
      </div>
    </div>
  </div>
</div>
