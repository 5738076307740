<div class="container py-5">
  <!-- ======= Team Section ======= -->
  <section id="team" class="team section-bg mb-4">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>TeleFy Team</h2>
        <!-- <p>
          Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex
          aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos
          quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
          fugiat sit in iste officiis commodi quidem hic quas.
        </p> -->
      </div>

      <div class="row">
        <div class="col-lg-3 mt-4">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic">
              <img src="./assets/Images/barath.jpg" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>Barath Kumar</h4>
              <span>Co Founder</span>
              <div class="social">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/barath-kumar-raghunathan-847820a3/"
                  ><i class="bi bi-linkedin"></i
                ></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 mt-4">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic">
              <img src="./assets/Images/srini.png" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>Srini</h4>
              <span>Advisor</span>
              <div class="social">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/srinivasabharathi-selvaraj-06195811"
                  ><i class="bi bi-linkedin"></i
                ></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 mt-4">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic">
              <img src="./assets/Images/Areeb.png" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>Areeb Quasem</h4>
              <span>Head of Business Development</span>
              <div class="social">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/areeb-quasem-spc-csp-icp-cat-icp-ent-94730438/"
                  ><i class="bi bi-linkedin"></i
                ></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 mt-4">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic">
              <img src="./assets/Images/vinoth.jpg" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>Vinoth</h4>
              <span>Director of Technology</span>
              <div class="social">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/vinoth-kumar-veeran-027308114/"
                  ><i class="bi bi-linkedin"></i
                ></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 mt-4">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic">
              <img src="./assets/Images/anand.jpg" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>Anand</h4>
              <span>QA Automation Architect </span>
              <div class="social">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/anand-kumar-subramaniam-3596a7211/"
                  ><i class="bi bi-linkedin"></i
                ></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 mt-4">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic">
              <img src="./assets/Images/lokesh.png" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>Lokesh</h4>
              <span>Tech Lead for Telefy</span>
              <div class="social">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/loki-thiru-1a416a97"
                  ><i class="bi bi-linkedin"></i
                ></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 mt-4">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic">
              <img src="./assets/Images/sundar.jpg" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>Sundar</h4>
              <span>Senior UI/UX Developer</span>
              <div class="social">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/sundar-p-188781145/"
                  ><i class="bi bi-linkedin"></i
                ></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 mt-4">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic">
              <img
                src="./assets/Images/sathish.jpeg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="member-info">
              <h4>Sathish Kumar</h4>
              <span>Senior Developer</span>
              <div class="social">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/sathish-kumar-7173a6113"
                  ><i class="bi bi-linkedin"></i
                ></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 mt-4">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic">
              <img src="./assets/Images/ram.jpg" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>Ram</h4>
              <span>Senior Developer</span>
              <div class="social">
                <a target="_blank" href="https://www.linkedin.com/in/leelaram-k"
                  ><i class="bi bi-linkedin"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Team Section -->
</div>
