<div>
  <nav class="container navbar navbar-expand-lg navbar-dark">
    <a class="navbar-brand" href="#">
      <img src="./assets/Images/telefy-dark1.svg" width="120" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" href="#"
            >Home <span class="sr-only">(current)</span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active-link" routerLink="/about"
            >About Us
          </a>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Products
          </a>

          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a
              class="dropdown-item"
              target="_blank"
              href="http://swap.telefy.finance/#/swap?use=V2"
              >Tele Swap</a
            >
            <a class="dropdown-item" routerLink="/comingsoon">Tele Lend</a>
            <a class="dropdown-item" routerLink="/comingsoon">Tele Score</a>
            <a class="dropdown-item" routerLink="/comingsoon">Tele NFT</a>
            <a
              class="dropdown-item"
              target="_blank"
              href="https://telepad.telefy.finance/"
              >Tele PAD</a
            >
          </div>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li> -->
        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Dropdown
          </a>

          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </li> -->
      </ul>
      <form class="form-inline my-2 my-lg-0 top-links-btn">
        <button
          class="btn btn-outline-purple my-2 my-sm-0"
          (click)="routToSwap()"
        >
          Enter App
        </button>
        <a
          class="btn btn-outline-light ml-lg-2 ml-sm-1"
          target="_blank"
          href="./assets/Telefy-whitepaper.pdf"
        >
          Light Papper
        </a>
        <a
          class="btn btn-light btn-gitbook ml-lg-2 ml-sm-1"
          target="_blank"
          href="https://github.com/Telefy"
        >
          <i class="bi bi-github"></i> Git Book
        </a>
        <a
          class="btn btn-light btn-gitbook ml-lg-2 ml-sm-1"
          target="_blank"
          href="https://github.com/Telefy/teleswap-contracts/blob/master/TELE_Audit.pdf"
        >
          <i class="bi bi-clipboard2-data"></i> Audit Link
        </a>
      </form>
    </div>
  </nav>
</div>
