<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <a class="navbar-brand" href="#">
          <img src="./assets/Images/telefy-dark1.svg" width="100" />
        </a>
        <p>Telefy is the worlds first credit score oriented DEFI solution.</p>
      </div>
      <div class="col-md-3">
        <h5>Products</h5>
        <ul class="products">
          <li>
            <a target="_blank" href="http://swap.telefy.finance/#/swap?use=V2"
              ><i class="bi bi-chevron-right"></i> Tele Swap</a
            >
          </li>
          <li>
            <a routerLink="/comingsoon"
              ><i class="bi bi-chevron-right"></i> Tele Lend</a
            >
          </li>
          <li>
            <a routerLink="/comingsoon"
              ><i class="bi bi-chevron-right"></i> Tele Score</a
            >
          </li>
          <li>
            <a target="_blank" href="https://telepad.telefy.finance/"
              ><i class="bi bi-chevron-right"></i> Tele Pad</a
            >
          </li>
          <li>
            <a routerLink="/comingsoon"
              ><i class="bi bi-chevron-right"></i> Tele NFT</a
            >
          </li>
        </ul>
        <!-- <p>
          Teleswap is the home of DeFi. Our community is building a
          comprehensive, decentralized trading platform for the future of
          finance. Join us!
        </p> -->
      </div>
      <div class="col-md-3">
        <h5>Support</h5>
        <ul class="support">
          <!-- <li>
            <a href=""><i class="fa fa-angle-right"></i> Contact</a>
          </li> -->
          <!-- <li>
            <a href=""><i class="fa fa-angle-right"></i> Terms of Use</a>
          </li> -->
          <li>
            <a target="_blank" href="./assets/Telefy-whitepaper.pdf"
              ><i class="bi bi-chevron-right"></i> Light Paper</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://github.com/Telefy/teleswap-contracts/blob/master/TELE_Audit_Certificate.jpeg"
              ><i class="bi bi-chevron-right"></i> Audit Certificate</a
            >
          </li>
        </ul>
      </div>
      <div class="col-md-3">
        <h5>Social</h5>
        <ul class="social-media">
          <li>
            <a
              title="Twitter"
              target="_blank"
              href="https://twitter.com/TelefyConnect"
              ><img src="./assets/Images/twitter.svg" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              title="Facebook"
              href="https://www.facebook.com/Telefy-104998872116794"
              ><img src="./assets/Images/facebook.svg" />
            </a>
          </li>
          <li>
            <a target="_blank" title="Telegram" href="https://t.me/telefydefi"
              ><img src="./assets/Images/telegram.svg" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              title="Instagram"
              href="https://www.instagram.com/telefy_tele/"
              ><img src="./assets/Images/insta.svg" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              title="Reddit"
              href="https://www.reddit.com/r/Telefy_Defi"
              ><img src="./assets/Images/reddit.svg" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="Copyright">
  <p>
    TeleFy Finance Limited &copy;2022 All rights reserved.
    <a href="mailto:info@telefy.finance" target="_blank">info@telefy.finance</a>
    <a class="visit-counter">
      <img
        src="https://hitwebcounter.com/counter/counter.php?page=7960241&style=0006&nbdigits=8&type=page&initCount=1000"
        Alt="web counter"
        border="0"
    /></a>
  </p>
</div>
<button class="btn back-to-tops" *ngIf="isShow" (click)="gotoTop()">
  <i class="bi bi-arrow-up-short"></i>
</button>
