import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }
  ngOnInit(): void {
  }
  launchIDO() {
    window.open("https://telepad.telefy.finance/", "_blank");
  }
  routToSwap() {
    // window.location.href = "http://swap.telefy.finance/";
    window.open("http://swap.telefy.finance/", "_blank");
  }

  currentDate: any;
  preStart: boolean = false;
  preEnd: boolean = false;
  targetDate: any;
  cDateMillisecs: any;
  tDateMillisecs: any;
  difference: any;
  seconds: any;
  minutes: any;
  hours: any;
  days: any;
  year: number = 2022;
  month: number = 11;
  months = [
    'Jan',
    'Feb',
    'Mar',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  day: number = 31;

  ngAfterViewInit() {
    this.myTimer();
    setInterval(this.myTimer, 1000);
  }

  myTimer() {
    console.log("---ter")
    const launchTime = new Date('2022-03-05T00:00:00');
    const currentLocalTime = new Date();
    const estTimNow = new Date(currentLocalTime.toLocaleString('en-US', { timeZone: 'America/New_York' }))
    if(estTimNow.getTime() >= launchTime.getTime()){

      const endTime1 = new Date('2022-03-21T00:00:00');
      const endTime2 = new Date('2022-04-11T00:00:00');
      const endTime3 = new Date('2022-04-26T00:00:00');
      const estTimNow = new Date(currentLocalTime.toLocaleString('en-US', { timeZone: 'America/New_York' }))

      if(endTime1.getTime() >= currentLocalTime.getTime()){
      
        this.tDateMillisecs = endTime1.getTime();
        document.getElementById('presaleContent').innerText = 'Pre-Sale Level 1 Ends in :';
        
      } else if(endTime2.getTime() >= currentLocalTime.getTime()) {
        
        this.tDateMillisecs = endTime2.getTime();
        document.getElementById('presaleContent').innerText = 'Pre-Sale Level 2 Ends in :';
  
      } else if(endTime3.getTime() >= currentLocalTime.getTime()) {
  
        this.tDateMillisecs = endTime3.getTime();
        document.getElementById('presaleContent').innerText = 'Pre-Sale Level 3 Ends in :';
  
      }

      this.cDateMillisecs = estTimNow.getTime();
      this.difference = this.tDateMillisecs - this.cDateMillisecs;
      this.seconds = Math.floor(this.difference / 1000);
      this.minutes = Math.floor(this.seconds / 60);
      this.hours = Math.floor(this.minutes / 60);
      this.days = Math.floor(this.hours / 24);

      this.hours %= 24;
      this.minutes %= 60;
      this.seconds %= 60;
      this.hours = this.hours < 10 ? '0' + this.hours : this.hours;
      this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
      this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;

      

      document.getElementById('days').innerText = this.days;
      document.getElementById('hours').innerText = this.hours;
      document.getElementById('mins').innerText = this.minutes;
      document.getElementById('seconds').innerText = this.seconds; 
      
    } else {     

      this.cDateMillisecs = estTimNow.getTime();
      this.tDateMillisecs = launchTime.getTime();
      this.difference = this.tDateMillisecs - this.cDateMillisecs;
      this.seconds = Math.floor(this.difference / 1000);
      this.minutes = Math.floor(this.seconds / 60);
      this.hours = Math.floor(this.minutes / 60);
      this.days = Math.floor(this.hours / 24);

      this.hours %= 24;
      this.minutes %= 60;
      this.seconds %= 60;
      this.hours = this.hours < 10 ? '0' + this.hours : this.hours;
      this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
      this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;

      document.getElementById('presaleContent').innerText = 'Pre-Sale Starts in :';
      document.getElementById('days').innerText = this.days;
      document.getElementById('hours').innerText = this.hours;
      document.getElementById('mins').innerText = this.minutes;
      document.getElementById('seconds').innerText = this.seconds;  
    }
        
  }
  

}